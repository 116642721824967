import React, { useState } from "react";
import Stepper from "../../components/stepper/Stepper";
import Header from "../../components/headers/Header";
import {
  Beat,
  BeatLite,
  Stake,
  StakeLite,
  DgaAssessmentIcon,
  DgaAssessmentPeople,
} from "../../constants/icons/Icons";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import "./EsrsChecklist.scss";
import EsrsChecklistUnderstandTheContext from "./UnderstandTheContext/UnderstandTheContext";

const EsrsChecklist = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { moduleId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const projectId = location.state?.projectId;
  const token = location.state?.token;
  const projectName = location.state?.projectName;
  const sessionData = location.state?.sessionData;

  const handleStepChange = (index) => {
    setActiveIndex(index);
  };

  const getIcon = (index, activeIcon, inactiveIcon) => {
    return activeIndex === index ? activeIcon : inactiveIcon;
  };

  const project = sessionData?.find((project) =>
    project.modules.some((module) => module.id === parseInt(moduleId))
  );

  const stepItems = [
    { label: t("All projects"), link: "/" },
    { label: projectName, link: `/project-home/${projectId}`, token },
    { label: project?.name, link: `/project-home/${projectId}`, token },
    { label: t("ESRS checklist"), link: "#" },
  ];

  return (
    <div className="esrs-checklist">
      <Header />
      <Stepper
        activeIndex={activeIndex}
        onChange={handleStepChange}
        items={stepItems}
      >
        <div
          icon={getIcon(0, <Stake />, <StakeLite />)}
          text={t("Overview")}
        ></div>
        <div
          icon={getIcon(1, <Stake />, <StakeLite />)}
          text={t("UnderstandTheContext")}
        >
          <EsrsChecklistUnderstandTheContext />
        </div>
        <div
          icon={getIcon(2, <DgaAssessmentPeople />, <DgaAssessmentIcon />)}
          text={t("Assessment")}
        ></div>
        <div
          icon={getIcon(3, <Beat />, <BeatLite />)}
          text={t("Assessment report")}
        ></div>
      </Stepper>
    </div>
  );
};

export default EsrsChecklist;