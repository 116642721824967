import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, Typography, Box } from "@mui/material";
import { Dot } from "../../constants/icons/Icons";
import {
  MotifButton,
  MotifFormField,
  MotifToggleSwitch,
  MotifMessage,
} from "@ey-xd/motif-react";
import "./CommentBox.scss";

const CommentBox = ({
  label,
  onSave,
  onCancel,
  cancelButtonLabel,
  saveButtonLabel,
  isMaterial,
  comment,
  setComment,
  isToggled,
  setIsToggled,
  showExtraField, 
  dmaResults,
  setDmaResults, 
}) => {
  const [commentError, setCommentError] = useState("");

  const handleToggle = () => setIsToggled((prev) => !prev);
  const handleSave = () => {
    if (!comment.trim()) {
      setCommentError("Comment must not be empty.");
      return;
    }
    setCommentError("");
    onSave && onSave(comment, isToggled, dmaResults);
  };

  const handleCancel = () => {
    setComment("");
    setCommentError("");
    onCancel && onCancel();
  };

  return (
    <Box className="comment-toggle-container">
      <Box
        className="comment-toggle-header"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          className="DGA-Label"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Dot />
          &nbsp;{label}
        </Typography>
        <Box
          className="comment-toggle-right"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <MotifFormField className="human-rights-toggle">
            <MotifToggleSwitch
              onChange={handleToggle}
              id="exampleToggle"
              hideLabels
              checked={isToggled}
            />
          </MotifFormField>
          <MotifMessage className="material-text" data-testid="human-rights">
            {isMaterial}
          </MotifMessage>
        </Box>
      </Box>
      {showExtraField && (
        <TextField
          label="DMA results"
          multiline
          variant="outlined"
          rows={10}
          value={dmaResults}
          onChange={(e) => setDmaResults(e.target.value)}
          fullWidth
          sx={{ marginTop: 2 }}
        />
      )}
      <TextField
        label="Comment"
        multiline
        rows={12}
        variant="outlined"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        fullWidth
        error={!!commentError}
        helperText={commentError}
      />

      <Box className="comment-toggle-actions">
        <MotifButton onClick={handleCancel} className="button cancel-button">
          {cancelButtonLabel}
        </MotifButton>
        <MotifButton onClick={handleSave} className="button done-button">
          {saveButtonLabel}
        </MotifButton>
      </Box>
    </Box>
  );
};

CommentBox.propTypes = {
  label: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  cancelButtonLabel: PropTypes.string,
  saveButtonLabel: PropTypes.string,
  isMaterial: PropTypes.string,
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
  isToggled: PropTypes.bool.isRequired,
  setIsToggled: PropTypes.func.isRequired,
  showExtraField: PropTypes.bool, 
  dmaResults: PropTypes.string, 
  setDmaResults: PropTypes.func, 
};

export default CommentBox;
