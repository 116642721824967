import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_ALL_DMA_SM_IDENTIFIED_MATERIAL_IMPACT_RISK_OPPORTUNITY } from "../../services/Api";

// Helper function to create async thunks
const createAsyncThunkHelper = (type, url) => {
  return createAsyncThunk(
    type,
    async ({ moduleId, token }, { rejectWithValue }) => {
      try {
        const response = await axios.get(`${url}/${moduleId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      } catch (error) {
        console.error(`Error fetching ${type} data:`, error);
        return rejectWithValue(
          error.response.data?.message ||
            error.response.data?.title ||
            error?.message ||
            `Error fetching ${type} data`
        );
      }
    }
  );
};

export const getMaterialIdentifiedImpactRiskOpportunity =
  createAsyncThunkHelper(
    "getMaterialIdentifiedImpactRiskOpportunity",
    GET_ALL_DMA_SM_IDENTIFIED_MATERIAL_IMPACT_RISK_OPPORTUNITY
  );

// Helper function to handle extra reducers
const handleExtraReducers = (builder, thunk, successStateKey) => {
  builder
    .addCase(thunk.pending, (state) => {
      state.isLoading = true;
      state[successStateKey] = false;
      state.isError = false;
      state.errorMessage = "";
    })
    .addCase(thunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state[successStateKey] = true;
      state.data = action.payload;
      state.isError = false;
    })
    .addCase(thunk.rejected, (state, action) => {
      state.isLoading = false;
      state[successStateKey] = false;
      state.isError = true;
      state.errorMessage = action.payload;
    });
};

// Slice definition
const dmaReportingSlice = createSlice({
  name: "dmaReporting",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
    postSuccess: false,
    types: [],
  },
  extraReducers: (builder) => {
    handleExtraReducers(
      builder,
      getMaterialIdentifiedImpactRiskOpportunity,
      "postSuccess"
    );
  },
});

export default dmaReportingSlice.reducer;
