import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_ALL_PROJECTS } from "../../services/Api";

// Constants for action types
const FETCH_ALL_PROJECTS = "getAllProjects";

// Helper function for API calls
const fetchData = async (url, token, rejectWithValue) => {
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    return rejectWithValue(
      error.response?.data?.message ||
        error.response?.data?.title ||
        error.message ||
        `Error fetching data from ${url}`
    );
  }
};

// Thunk to fetch all projects with the token
export const fetchAllProjects = createAsyncThunk(
  FETCH_ALL_PROJECTS,
  async ({ token }, { rejectWithValue }) => {
    return fetchData(GET_ALL_PROJECTS, token, rejectWithValue);
  }
);

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  errorMessage: "",
};

// Common state handlers
const handlePending = (state) => {
  state.isLoading = true;
  state.errorMessage = "";
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.data = action.payload;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload;
};

const fetchAllProjectsSlice = createSlice({
  name: "allProjects",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProjects.pending, handlePending)
      .addCase(fetchAllProjects.fulfilled, handleFulfilled)
      .addCase(fetchAllProjects.rejected, handleRejected);
  },
});

export default fetchAllProjectsSlice.reducer;
