import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import Header from "../../components/headers/Header";
import Subheader from "../../components/subheader/Subheader";
import { useTranslation } from "react-i18next";
import "./FileManager.scss";
import { FileDownload, Delete } from "../../constants/icons/Icons";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ErrorModal from "../../components/modals/error/errorModal";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import {
  fetchFiles,
  uploadFile,
  deleteFile,
  downloadFile,
} from "../../features/slices/FileManager";
import DeleteModal from "../../components/modals/deleteModal/DeleteModal";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { handleError } from "../../utils/handleError";

const UploadFile = ({ onUpload }) => {
  const { t } = useTranslation();
  const onDrop = (acceptedFiles, rejectedFiles) => {
    const filteredFiles = acceptedFiles.filter((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return file.type === "application/pdf" && fileExtension === "pdf";
    });

    if (filteredFiles.length > 0) {
      onUpload(filteredFiles);
    }

    if (
      rejectedFiles.length > 0 ||
      filteredFiles.length !== acceptedFiles.length
    ) {
      alert(t("Only PDF files are allowed."));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "application/pdf",
  });

  return (
    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #cccccc",
          padding: "20px",
          cursor: "pointer",
          textAlign: "center",
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="body1" color="textSecondary">
          {t("Draganddrop")}
        </Typography>
      </div>
    </Paper>
  );
};

UploadFile.propTypes = {
  onUpload: PropTypes.func.isRequired,
};

const FileItem = ({ file, onDeleteItem, onViewItem }) => (
  <ListItem
    secondaryAction={
      <>
        <IconButton
          edge="end"
          aria-label="view"
          onClick={() => onViewItem(file.guidName, file.name)}
        >
          <FileDownload />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => onDeleteItem(file)}
        >
          <Delete />
        </IconButton>
      </>
    }
  >
    <InsertDriveFileIcon sx={{ marginRight: 2 }} />
    <ListItemText
      className="file-list-content"
      primary={<span className="file-name">{file.name}</span>}
    />
  </ListItem>
);

FileItem.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    guidName: PropTypes.number.isRequired,
  }).isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onViewItem: PropTypes.func.isRequired,
};

const FileExplorer = ({ files, onDeleteItem, onViewItem }) => {
  const { t } = useTranslation();
  if (!Array.isArray(files)) return null;
  if (files.length === 0) {
    return (
      <Typography variant="body1" color="textSecondary" align="center">
        {t("Nofilesyetuploaded")}
      </Typography>
    );
  }
  return (
    <List className="file-list-content">
      {files.map((file) => (
        <FileItem
          key={file.id}
          file={file}
          onDeleteItem={onDeleteItem}
          onViewItem={onViewItem}
        />
      ))}
    </List>
  );
};

FileExplorer.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onViewItem: PropTypes.func.isRequired,
};

const FileManager = () => {
  const dispatch = useDispatch();
  const files = useSelector((state) => state.fileManager.files);
  const isLoading = useSelector((state) => state.fileManager.isLoading);
  const isError = useSelector((state) => state.fileManager.isError);
  const errorMessage = useSelector((state) => state.fileManager.errorMessage);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const location = useLocation();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const projectName = location.state?.projectName;
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const theme = createTheme({
    typography: {
      fontFamily: "EYInterstate, sans-serif",
    },
  });

  useEffect(() => {
    if (token) {
      dispatch(fetchFiles({ projectId, token }));
    }
  }, [dispatch, token, projectId]);

  const handleUploadFile = (files) => {
    const uploadPromises = files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return dispatch(uploadFile({ fileData: formData, projectId, token }))
        .then(() => {
          console.info(`File ${file.name} uploaded successfully.`);
        })
        .catch((error) => {
          console.error(`Error uploading file ${file.name}:`, error);
        });
    });
    Promise.all(uploadPromises)
      .then(() => {
        dispatch(fetchFiles({ projectId, token }));
      })
      .catch((error) => {
        console.error("Error uploading one or more files:", error);
      });
  };

  const handleDeleteItem = (file) => {
    setSelectedFile(file);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteItem = () => {
    if (selectedFile) {
      dispatch(deleteFile({ fileId: selectedFile.guidName, projectId, token }))
        .then(() => {
          dispatch(fetchFiles({ projectId, token }));
          setIsDeleteModalOpen(false);
          setSelectedFile(null);
        })
        .catch((error) => {
          console.error("Error deleting file:", error);
        });
    }
  };

  const handleViewItem = async (guidName, filename) => {
    if (!guidName || !filename) {
      console.error("fileId or filename is missing");
      return;
    }
    try {
      const response = await dispatch(
        downloadFile({ guidName, projectId, filename, token })
      ).unwrap();

      if (response.type === downloadFile.fulfilled.type) {
        const blob = new Blob([response.payload.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleSearchInput = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredFiles = files.filter((file) =>
    file?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <ThemeProvider theme={theme}>
      <div className="file-manager-container">
        <Header />
        <div className="file-container">
          <Breadcrumbs
            items={[
              { label: "All projects", link: "/" },
              {
                label: projectName,
                link: `/project-home/${projectId}`,
                token: token,
              },
              {
                label: "File manager",
                link: `/file-manager/${projectId}`,
                token: token,
              },
            ]}
          />
          <div className="file-subheader-container">
            <Subheader
              title={t("FileManager")}
              page="FileManager"
              FileManagerButtonTitle={t("FileManager")}
              onChangeSearchInput={handleSearchInput}
            />
          </div>
          <Box sx={{ padding: 4, border: "1px solid #ececec" }}>
            <UploadFile onUpload={handleUploadFile} />
            {isError ? (
              <ErrorModal
                setName={t("Error")}
                labelText={errorMessage}
                handleButtonClick={handleError(dispatch, navigate)}
                deleteButtonLabel={t("Reload")}
              />
            ) : (
              <>
                <Typography className="file-list-header" gutterBottom>
                  {t("UploadList")}
                </Typography>
                <Paper className="file-list-content" elevation={3}>
                  <FileExplorer
                    files={filteredFiles}
                    onDeleteItem={handleDeleteItem}
                    onViewItem={handleViewItem}
                  />
                </Paper>
              </>
            )}
          </Box>
        </div>
        {isLoading && (
          <MotifProgressLoader className="loader" show variant="default" />
        )}
        {isDeleteModalOpen && (
          <DeleteModal
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            setName={t("DeleteLabel")}
            labelText={t("DeletConfirmationSources")}
            onCancel={() => setIsDeleteModalOpen(false)}
            handleDeleteClick={confirmDeleteItem}
            cancelButtonLabel={t("Cancel")}
            deleteButtonLabel={t("DeleteLabel")}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default FileManager;
