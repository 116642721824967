import React from "react";
import "./RateCard.scss";
import PropTypes from "prop-types";
const RateCard = ({ ratingCardValue, ratingCardHeader, ratingCardLabel }) => {
  return (
    <div className="rateCard-container">
      <div className="rateCard-number-div">
        <h5 className="rateCard-number">{ratingCardValue}</h5>
      </div>

      <div className="rateCard-header-div">
        <h1 className="rateCard-header">{ratingCardHeader}</h1>
        <h1
          className={`rateCard-body ${
            ratingCardLabel === "N/A" ? "rateCard-na" : ""
          }`}
        >
          {ratingCardLabel}
        </h1>
      </div>
    </div>
  );
};

RateCard.propTypes = {
  ratingCardValue: PropTypes.string,
  ratingCardHeader: PropTypes.string,
  ratingCardLabel: PropTypes.string,
};

export default RateCard;