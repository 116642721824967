import { logoutUser } from "../../src/features/slices/HomeSlice";
export const handleError = async (dispatch, navigate) => {
  try {
    dispatch(logoutUser());
    localStorage.removeItem("user");
    localStorage.removeItem("language");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("language");
    sessionStorage.clear();
    if (window.msalInstance) {
      await window.msalInstance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } else {
      navigate("/");
    }
  } catch (error) {
    console.error("Error during sign-out", error);
  }
};
