import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import ExcelJS from "exceljs";
import {
  createNewSustainabilityMatters,
  deleteSustainabilityMatters,
  fetchEsrsAr16,
  getAllSustainabilityMatters,
  updateSustainabilityMatters,
  clearError,
} from "../../../../features/slices/SustainabilityMatters";
import { fetchExcelData } from "../../../../features/slices/LongListData";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import TreeView from "../../../../components/treeView/TreeView";
import SustainabilityMattersModal from "../../../../components/modals/dmaModals/SustainabilityMattersModal";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import ErrorModal from "../../../../components/modals/error/errorModal";
import "./SustainabillityMatters.scss";
import { useTranslation } from "react-i18next";
import { handleError as handleLogoutError } from "../../../../utils/handleError";
import { addSheet } from "../../../../utils/excelUtils";

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
};

const formatExcelData = (data) => {
  const workbook = new ExcelJS.Workbook();
  addSheet(workbook, "Sustainability Matters", data);
  return workbook;
};

const downloadExcel = async (data) => {
  const workbook = formatExcelData(data);
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "sustainability_matters.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

const SustainabilityMatters = () => {
  const [modal, setModal] = useState({ isOpen: false, title: "", mode: "add" });
  const [options, setOptions] = useState({
    parent: [],
    esrs: [],
    esrsar16: [],
  });
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [filteredUpdateData, setFilteredUpdateData] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateItemID, setUpdateItemID] = useState(null);
  const [deleteItemID, setDeleteItemID] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [smData, setSmData] = useState([]);

  const getEligibleParentOptions = useCallback(
    (items, parentId = null, currentLevel = 0) => {
      if (currentLevel >= 2) return []; // Stop recursion if level exceeds 2

      return items
        .filter((item) => item.parentId === parentId)
        .flatMap((item) => [
          {
            value: item.id,
            label: item.label || item.name,
          },
          ...getEligibleParentOptions(
            item.children || [],
            item.id,
            currentLevel + 1
          ), // Recursively get children if present
        ]);
    },
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [smResponse, esrsar16Response] = await Promise.all([
          dispatch(getAllSustainabilityMatters({ moduleId, token })),
          dispatch(fetchEsrsAr16({ token })),
        ]);
        const sustainabilityMatters = smResponse.payload.data || [];
        setSmData(sustainabilityMatters);
        setOptions({
          esrsar16:
            esrsar16Response.payload.data.map((type) => ({
              value: type.id,
              label: type.name,
            })) || [],
          parent: getEligibleParentOptions(sustainabilityMatters),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, moduleId, token, getEligibleParentOptions, updateTrigger]);

  const sustainabilityMattersState = useSelector(
    (state) => state.dmaSustainabilityMatters
  );
  const { isError, errorMessage, isGenericError } = sustainabilityMattersState;

  const handleOpenModal = (mode, itemId) => {
    setModal({
      isOpen: true,
      mode,
      title:
        mode === "add"
          ? t("newSustainabilityMatters")
          : t("editSustainabilityMatters"),
    });
  };

  const handleCloseModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
    setUpdateItemID(null);
  };

  const findItemById = (data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const handleEditClick = (id) => {
    handleOpenModal("edit", id);
    setFilteredUpdateData(findItemById(smData, id));
    setUpdateItemID(id);
    setDeleteItemID(id);
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await dispatch(
        deleteSustainabilityMatters({
          sustainabilityMattersId: deleteItemID,
          moduleId,
          token,
        })
      ).unwrap();
      handleCloseModal();
      handleDeleteCloseModal();
      setUpdateTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Deletion failed:", error);
    }
  };

  function findParentById(data, parentId) {
    for (let item of data) {
      if (item.id === parentId) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        const found = findParentById(item.children, parentId);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  const createNewSustainabilityMattersApi = async (data) => {
    if (data) {
      try {
        let newSortOrder;
        if (data.parentId === null) {
          const maxSortOrder = smData.reduce(
            (max, item) => (item.sortOrder > max ? item.sortOrder : max),
            0
          );
          newSortOrder = maxSortOrder + 1;
        } else {
          const parentItem = findParentById(smData, data.parentId);

          newSortOrder = parentItem ? parentItem.sortOrder : 0;
        }
        const newData = {
          ...data,
          sortOrder: newSortOrder,
        };

        await dispatch(
          createNewSustainabilityMatters({
            moduleId,
            sustainabilityMattersData: newData,
            token,
          })
        ).unwrap();

        setUpdateTrigger((prev) => prev + 1);
        handleCloseModal();
      } catch (error) {
        console.error("Creation failed:", error);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const updateSustainabilityMattersApi = async (data) => {
    if (data) {
      setIsLoading(true);
      try {
        const existingItem = findItemById(smData, updateItemID);
        const updatedData = {
          ...data,
          sortOrder: existingItem.sortOrder,
        };

        const response = await dispatch(
          updateSustainabilityMatters({
            sustainabilityMattersId: updateItemID,
            sustainabilityMattersData: updatedData,
            moduleId,
            token,
          })
        ).unwrap();

        if (response.success) {
          handleCloseModal();
          setUpdateTrigger((prev) => prev + 1);
        }
      } catch (error) {
        console.error("Update failed:", error);
      }
      setIsLoading(false);
    } else {
      alert("Please fill in all required fields");
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event);
  };

  const filterItems = (items, query) => {
    if (!query) return items;
    return items
      .map((item) => {
        if (
          item?.label?.toLowerCase().includes(query.toLowerCase()) ||
          item?.description?.toLowerCase().includes(query.toLowerCase())
        )
          return item;
        if (item.children) {
          const filteredChildren = filterItems(item.children, query);
          if (filteredChildren.length > 0)
            return { ...item, children: filteredChildren };
        }
        return null;
      })
      .filter(Boolean);
  };

  const handleDownloadExcel = async () => {
    setIsExcelLoading(true);
    try {
      const excelResponse = await dispatch(
        fetchExcelData({ moduleId, token })
      ).unwrap();
      const exceResponseData = excelResponse?.data || [];
      await downloadExcel(exceResponseData);
    } catch (error) {
      console.error("Error fetching Excel data:", error);
    } finally {
      setIsExcelLoading(false);
    }
  };
  const transformedItems =
    smData.map((item) => ({
      id: item.id,
      itemId: item.id,
      label: item.label || item.name,
      children: item.children || [],
    })) || [];

  const filteredItems = filterItems(transformedItems, searchInput);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);

  // Whenever isError or errorMessage changes, reset showErrorModal
  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? () => handleLogoutError(dispatch, navigate)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }

  return (
    <>
      {isLoading || isExcelLoading ? (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      ) : (
        <>
          <div>
            <ContextSelection
              addButtonText="Add new"
              pageName="SM"
              downloadButtonText="Download longlist"
              onClickNewProject={() => handleOpenModal("add")}
              onClickNewDownload={handleDownloadExcel}
              onChangeSearchInput={handleSearchInputChange}
            />
          </div>
          <div className="sm-file-explorer">
            <TreeView
              items={filteredItems}
              pageName="SM"
              defaultExpandedItems={[]}
              defaultSelectedItems="1"
              onEditClick={handleEditClick}
            />
          </div>
        </>
      )}
      {modal.isOpen && (
        <SustainabilityMattersModal
          onClose={handleCloseModal}
          modalTitle={modal.title}
          modalMode={modal.mode}
          topicNameLabel={t("TopicName")}
          parentLabel={t("Parent")}
          esrsAr16Label={t("ESRS_AR16")}
          esgLabel={t("ESG")}
          enterTopicNameLabel={t("EnterTopicName")}
          selectParentLabel={t("SelectParent")}
          isHumanRightsRelatedLabel={t("isHumanRightsRelated")}
          selectEsrsAr16Label={t("SelectESRS")}
          DescriptionLabel={t("Description")}
          saveButtonLabel={t("addSustainabilityMatters")}
          updateButtonLabel={t("Update")}
          cancelButtonLabel={t("Cancel")}
          esrsar16Options={options.esrsar16}
          parentOptions={options.parent}
          isDisabled={isLoading}
          handleDoneClick={
            modal.mode === "add"
              ? createNewSustainabilityMattersApi
              : updateSustainabilityMattersApi
          }
          filteredUpdateData={filteredUpdateData}
          onDeleteClick={handleDeleteClick}
          DeleteLabel={t("DeleteLabel")}
          updateItemID={updateItemID}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={handleDeleteCloseModal}
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={handleDeleteCloseModal}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
        />
      )}
    </>
  );
};

export default SustainabilityMatters;
