import React from "react";
import {
  MotifMessage,
  MotifFormField,
  MotifInput,
  MotifLabel,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import "./DmaModal.scss";

const TabContent = ({
  topicNameLabel,
  enterTopicNameLabel,
  topicName,
  handleInputChange,
  definitionPositive,
  definitionNegative,
  DefinitionPositiveLabel,
  DefinitionNegativeLabel,
  fieldPrefix,
  formErrors,
}) => {
  const handleChange = (key, value) => {
    handleInputChange(key, value);
  };

  return (
    <>
      <div className="form-row">
        <div className="form-group full-width">
          <MotifMessage data-testid="topic-name">
            {topicNameLabel}*
          </MotifMessage>
          <MotifFormField>
            <MotifInput
              placeholder={enterTopicNameLabel}
              value={topicName}
              hideClearButton={true}
              onChange={(e) =>
                handleChange(`${fieldPrefix}Label`, e.target.value)
              }
              className="rating-input-in-box"
            />
          </MotifFormField>
          {formErrors[`${fieldPrefix}LabelError`] && (
            <MotifErrorMessage>
              {formErrors[`${fieldPrefix}LabelError`]}
            </MotifErrorMessage>
          )}
        </div>
      </div>
      <div className="definition-div">
        <DefinitionField
          label={DefinitionPositiveLabel}
          value={definitionPositive}
          onChange={(e) =>
            handleChange(`${fieldPrefix}DefinitionPositive`, e.target.value)
          }
          error={formErrors[`${fieldPrefix}DefinitionPositiveError`]}
        />
        <DefinitionField
          label={DefinitionNegativeLabel}
          value={definitionNegative}
          onChange={(e) =>
            handleChange(`${fieldPrefix}DefinitionNegative`, e.target.value)
          }
          error={formErrors[`${fieldPrefix}DefinitionNegativeError`]}
        />
      </div>
    </>
  );
};

const DefinitionField = ({ label, value, onChange, error }) => (
  <div className="form-row score-definition-label">
    <MotifLabel id="select-label" data-testid="definition-label">
      {label}
    </MotifLabel>
    <TextField
      placeholder=""
      multiline
      fullWidth
      rows={4}
      value={value}
      onChange={onChange}
    />
    {error && <MotifErrorMessage>{error}</MotifErrorMessage>}
  </div>
);

TabContent.propTypes = {
  topicNameLabel: PropTypes.string.isRequired,
  enterTopicNameLabel: PropTypes.string.isRequired,
  topicName: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  definitionPositive: PropTypes.string.isRequired,
  definitionNegative: PropTypes.string.isRequired,
  DefinitionPositiveLabel: PropTypes.string.isRequired,
  DefinitionNegativeLabel: PropTypes.string.isRequired,
  fieldPrefix: PropTypes.string.isRequired,
  formErrors: PropTypes.object.isRequired,
};

DefinitionField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default TabContent;
