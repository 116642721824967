import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_EXCEL_DATA } from "../../services/Api";

// Constants for action types
const FETCH_EXCEL_DATA = "fetchExcelData";

// Helper function for API calls
const fetchData = async (url, token, rejectWithValue) => {
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    return rejectWithValue(
      error.response?.data?.message ||
        error.response?.data?.title ||
        error.message ||
        `Error fetching data from ${url}`
    );
  }
};

// Thunk to fetch all excelData with the token
export const fetchExcelData = createAsyncThunk(
  FETCH_EXCEL_DATA,
  async ({ moduleId, token }, { rejectWithValue }) => {
    return fetchData(`${GET_EXCEL_DATA}/${moduleId}`, token, rejectWithValue);
  }
);

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  errorMessage: "",
};

// Common state handlers
const handlePending = (state) => {
  state.isLoading = true;
  state.errorMessage = "";
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.data = action.payload;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload;
};

const fetchExcel = createSlice({
  name: "allUsers",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchExcelData.pending, handlePending)
      .addCase(fetchExcelData.fulfilled, handleFulfilled)
      .addCase(fetchExcelData.rejected, handleRejected);
  },
});

export default fetchExcel.reducer;