import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_SCORES_URL,
  CREATE_SCORES_URL,
  UPDATE_SCORES_URL,
  DELETE_SCORES_URL,
} from "../../services/Api";

// Utility function to handle API requests
const apiRequest = async (url, method, data, token) => {
  const config = {
    method,
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  const response = await axios(config);
  return response.data;
};

// Generic thunk creator
const createThunk = (type, apiCall) => {
  return createAsyncThunk(type, async (params, { rejectWithValue }) => {
    try {
      return await apiCall(params);
    } catch (error) {
      console.error(`Error in ${type}:`, error);
      const errorMessage = error.response?.data?.message;

      if (!errorMessage) {
        // No specific error message found
        return rejectWithValue({
          errorMessage:
            error.response?.data?.title ||
            error.message ||
            "Unknown error occurred",
          isGenericError: true,
        });
      } else {
        return rejectWithValue({ errorMessage, isGenericError: false });
      }
    }
  });
};

export const fetchScores = createThunk(
  "scores/fetchScores",
  ({ moduleId, token }) =>
    apiRequest(`${GET_SCORES_URL}/${moduleId}`, "get", null, token)
);

export const createScores = createThunk(
  "createScores",
  ({ moduleId, scoresData, token }) =>
    apiRequest(`${CREATE_SCORES_URL}/${moduleId}`, "post", scoresData, token)
);

export const updateScores = createThunk(
  "updateScores",
  ({ scoresId, scoresData, moduleId, token }) =>
    apiRequest(
      `${UPDATE_SCORES_URL}/${scoresId}/${moduleId}`,
      "put",
      scoresData,
      token
    )
);

export const deleteScores = createThunk(
  "deleteScores",
  ({ scoresId, moduleId, token }) =>
    apiRequest(
      `${DELETE_SCORES_URL}/${scoresId}/${moduleId}`,
      "delete",
      null,
      token
    )
);

// Utility function to handle extra reducers
const handleAsyncActions = (builder, action, successKey = "data") => {
  builder
    .addCase(action.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.errorMessage = "";
    })
    .addCase(action.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state[successKey] = payload;
    })
    .addCase(action.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = payload.errorMessage;
      state.isGenericError = payload.isGenericError || false;
    });
};

// Slice definition
const scoresSlice = createSlice({
  name: "scores",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    errorMessage: "",
    isGenericError: false
  },
  reducers: {
    clearError(state) {
      state.isError = false;
      state.errorMessage = "";
      state.isGenericError = false;
    },
  },
  extraReducers: (builder) => {
    handleAsyncActions(builder, fetchScores, "data");
    handleAsyncActions(builder, createScores, "data");
    handleAsyncActions(builder, updateScores, "data");
    handleAsyncActions(builder, deleteScores, "data");
  },
});

export const { clearError } = scoresSlice.actions;
export default scoresSlice.reducer;