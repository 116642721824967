import React, { useState } from "react";
import {
  MotifLabel,
  MotifSelect,
  MotifOption,
  MotifFormField,
  MotifButton,
  MotifTruncate,
  MotifCheckbox,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { TableCell } from "@mui/material";
import ClientTable from "../../clientTable/ClientTable";
import "./IroModal.scss";

const IroLibraryModal = ({
  onClose,
  modalTitle,
  filterByLabel,
  tableData,
  handleSubmit,
  generateRowKey,
  saveButtonLabel,
  cancelButtonLabel,
  disable,
  selectedFilterValue,
  libraryTypes,
  setSelectedFilterValue,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 5;
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (value) => {
    setSelectedFilterValue(value);
    setSelectedRowIds([]); // Reset selected rows when filter changes
  };

  const getLibraryFilteredData = () => {
    return Array.isArray(tableData)
      ? tableData.filter((item) =>
          item.name?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : [];
  };

  const filteredData = getLibraryFilteredData();

  // Check if all rows are selected
  const allChecked =
    filteredData.length > 0 && selectedRowIds.length === filteredData.length;

  // Check if some but not all rows are selected
  const isIndeterminate =
    selectedRowIds.length > 0 && selectedRowIds.length < filteredData.length;

  const handleSelectAll = () => {
    if (allChecked) {
      setSelectedRowIds([]);
    } else {
      const newSelectedIds = filteredData.map((item) => item.id);
      setSelectedRowIds(newSelectedIds);
    }
  };

  const handleRowSelect = (id) => {
    const isChecked = selectedRowIds.includes(id);
    if (!isChecked) {
      setSelectedRowIds((prevSelected) => [...prevSelected, id]);
    } else {
      setSelectedRowIds((prevSelected) =>
        prevSelected.filter((selectedId) => selectedId !== id)
      );
    }
  };

  const columns = [
    {
      colName: "select",
      label: (
        <MotifCheckbox
          id={`checkbox-parent`}
          name={`checkbox-parent`}
          checked={allChecked}
          className="iro-library-checkbox"
          indeterminate={isIndeterminate}
          onChange={handleSelectAll}
        />
      ),
      showSorting: false,
    },
    { colName: "iroTypeName", label: "Type", showSorting: true },
    { colName: "name", label: "Name", showSorting: true },
    { colName: "description", label: "Description", showSorting: false },
  ];

  const renderTableCell = (colName, value, row, index) => {
    if (colName === "select") {
      const isChecked = selectedRowIds.includes(row.id);
      return (
        <TableCell key={index}>
          <MotifCheckbox
            checked={isChecked}
            id={`checkbox-${row.id}`}
            name={`checkbox-${row.id}`}
            className="iro-library-checkbox"
            onChange={() => handleRowSelect(row.id)}
          />
        </TableCell>
      );
    }
    if (colName === "description") {
      return (
        <TableCell key={index}>
          <MotifTruncate>{value}</MotifTruncate>
        </TableCell>
      );
    }
    return <TableCell key={index}>{value}</TableCell>;
  };

  const onSubmit = () => {
    // Pass the selected IDs to handleSubmit
    handleSubmit(selectedRowIds);
  };

  return (
    <div className="modal-overlay">
      <div className="iro-library-modal modal">
        <div className="modal-header">
          <h2>{modalTitle}</h2>
        </div>
        <div className="modal-body">
          <div className="search-filter-container">
            <Paper component="form" className="search-container">
              <IconButton
                type="button"
                className="search-icon-iro"
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                className="search-box-input-iro"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearch}
                inputProps={{ "aria-label": "search For" }}
              />
            </Paper>
            <div className="filter-container">
              <MotifFormField className="filter-by-select">
                <MotifLabel id="select-dma-select-label" position="in">{filterByLabel}</MotifLabel>
                <MotifSelect
                  className="session-modal-input"
                  ariaLabelledBy="select-dma-select-label"
                  labelPosition="in"
                  visibleOptions={3}
                  value={selectedFilterValue}
                  onChange={handleFilterChange}
                >
                  {libraryTypes.map(({ id, name }) => (
                    <MotifOption key={id} value={id.toString()}>
                      {name}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
            </div>
          </div>
          <div className="iro-table-container">
            <ClientTable
              columns={columns}
              data={filteredData}
              itemsPerPage={itemsPerPage}
              renderTableCell={renderTableCell}
              generateRowKey={generateRowKey}
              showPagination={true}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="button cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton
              onClick={onSubmit}
              disabled={selectedRowIds.length === 0 || disable}
              className="button done-button"
            >
              {saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

IroLibraryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  filterByLabel: PropTypes.string.isRequired,
  tableData: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  generateRowKey: PropTypes.func.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  disable: PropTypes.bool,
  selectedFilterValue: PropTypes.number.isRequired,
  libraryTypes: PropTypes.array.isRequired,
  setSelectedFilterValue: PropTypes.func.isRequired,
};

export default IroLibraryModal;
