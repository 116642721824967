import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  Suspense,
  useCallback,
} from "react";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { TableCell, Box, IconButton, Typography } from "@mui/material";
import { Edit, Delete, View } from "../../constants/icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdminProjects } from "../../features/slices/InstanceMaintenance";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Subheader from "../../components/subheader/Subheader";
import ErrorModal from "../../components/modals/error/errorModal";
import "./InstanceMaintenance.scss";
const ClientTable = React.lazy(() =>
  import("../../components/clientTable/ClientTable")
);

const AllProjects = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const projectIDsRef = useRef([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [transformedData, setTransformedData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = useSelector((state) => state.user.jwtToken);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const result = await dispatch(getAllAdminProjects({ token })).unwrap();
        setProjects(result?.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch all projects", error);
        setIsError(true);
        setErrorMessage(error.message);
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, [dispatch, token]);

  const columnsToDisplay = useMemo(
    () => [
      { colName: "name", label: t("ProjectName"), showSorting: true },
      { colName: "Action", label: t("Action"), showSorting: false },
    ],
    [t]
  );

  useEffect(() => {
    if (projects.length > 0) {
      projectIDsRef.current = projects.map((item) => item.id);
      const data = projects.map((item, index) => {
        const transformedItem = {};
        columnsToDisplay.forEach((column) => {
          if (column.colName !== "Action") {
            transformedItem[column.colName] = item[column.colName];
          }
        });
        transformedItem.id = projectIDsRef.current[index];
        transformedItem.retiered = item.retiered;
        return transformedItem;
      });
      setTransformedData(data);
    }
  }, [projects, columnsToDisplay]);

  const generateRowKey = useCallback((row) => row.id, []);

  const getCellStyle = useCallback((column, index) => {
    return column === "Action"
      ? { textAlign: "right" }
      : { textAlign: index === 0 ? "left" : "right" };
  }, []);

  const handleButtonClick = useCallback(
    (projectId, projectName, buttonName) => {
      const routes = {
        View: `/project-home/${projectId}`,
        Edit: `/edit-project/${projectId}`,
        default: `/`,
      };
      navigate(routes[buttonName] || routes.default, {
        state: { token, projectName },
      });
    },
    [navigate, token]
  );

  const renderTableCell = useCallback(
    (column, value, row, index) => {
      const cellStyle = getCellStyle(column, index);
      const cellPaddingRight = index === column?.length - 1 ? "3%" : "0";

      if (column === "Action") {
        const isRetired = row.retiered;
        return (
          <TableCell key="Action" style={cellStyle}>
            <Box
              display="flex"
              justifyContent="flex-end"
              style={isRetired ? { opacity: 0.3 } : {}}
            >
              <IconButton
                onClick={() =>
                  !isRetired && handleButtonClick(row.id, row.name, "View")
                }
                data-testid="view-button"
                disabled={isRetired}
                style={isRetired ? { color: "grey" } : {}}
              >
                <View />
              </IconButton>
              <IconButton
                onClick={() =>
                  !isRetired && handleButtonClick(row.id, row.name, "Edit")
                }
                disabled={isRetired}
                style={isRetired ? { color: "grey" } : {}}
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() =>
                  !isRetired && handleButtonClick(row.id, row.name, "Delete")
                }
                disabled={isRetired}
                style={isRetired ? { color: "grey" } : {}}
              >
                <Delete />
              </IconButton>
            </Box>
          </TableCell>
        );
      } else {
        return (
          <TableCell
            key={column}
            style={{ ...cellStyle, paddingRight: cellPaddingRight }}
          >
            {value}
          </TableCell>
        );
      }
    },
    [getCellStyle, handleButtonClick]
  );

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const filteredData = useMemo(() => {
    return transformedData.filter((item) => {
      const nameValue = item["name"] || "";
      const query = searchQuery.toLowerCase();
      return nameValue.toLowerCase().includes(query);
    });
  }, [transformedData, searchQuery]);

  const handleError = useCallback(() => {
    window.location.reload();
  }, []);

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  let content;

  if (filteredData.length > 0) {
    content = !isLoading && (
      <ClientTable
        columns={columnsToDisplay}
        data={filteredData}
        itemsPerPage={5}
        generateRowKey={generateRowKey}
        getCellStyle={getCellStyle}
        renderTableCell={renderTableCell}
        handleButtonClick={handleButtonClick}
      />
    );
  } else {
    content = (
      <Typography variant="body1" className="watermark">
        {t("NoDataAvailable")}
      </Typography>
    );
  }

  return (
    <div className="all-projects-container">
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {!isLoading && (
        <div className="instance-home-client-org-container">
          <div className="instance-home-client-org-subheader">
            <Subheader
              page="Maintainance"
              title={t("All projects")}
              disableButton={isLoading}
              onChangeSearchInput={handleSearch}
              data-testid="add-new-project-button"
            />
          </div>
          <div>
            <Suspense
              fallback={
                <MotifProgressLoader
                  data-testid="loading-spinner"
                  show
                  variant="default"
                />
              }
            >
              {content}
            </Suspense>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProjects;