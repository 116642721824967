import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  LIST_OF_FILES,
  UPLOAD_FILES,
  DELETE_FILES,
  DOWNLOAD_FILES,
} from "../../services/Api";

// Constants for action types
const FETCH_FILES = "fileManager/fetchFiles";
const DOWNLOAD_FILE = "fileManager/downloadFile";
const UPLOAD_FILE = "fileManager/uploadFile";
const DELETE_FILE = "fileManager/deleteFile";

// Helper function for API calls
const fetchData = async (
  method,
  url,
  data,
  token,
  rejectWithValue,
  responseType = "json"
) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type":
          method === "post" ? "multipart/form-data" : "application/json",
      },
      responseType,
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    return rejectWithValue(
      error.response?.data?.message ||
        error.response?.data?.title ||
        error.message ||
        `Error fetching data from ${url}`
    );
  }
};

// Async thunks
export const fetchFiles = createAsyncThunk(
  FETCH_FILES,
  async ({ projectId, token }, { rejectWithValue }) => {
    return fetchData(
      "get",
      `${LIST_OF_FILES}?projectId=${projectId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const downloadFile = createAsyncThunk(
  DOWNLOAD_FILE,
  async ({ guidName, projectId, filename, token }, { rejectWithValue }) => {
    const data = await fetchData(
      "get",
      `${DOWNLOAD_FILES}?blobId=${guidName}&projectId=${projectId}`,
      null,
      token,
      rejectWithValue,
      "blob"
    );
    return { data, filename };
  }
);

export const uploadFile = createAsyncThunk(
  UPLOAD_FILE,
  async ({ fileData, projectId, token }, { rejectWithValue }) => {
    return fetchData(
      "post",
      `${UPLOAD_FILES}?projectId=${projectId}`,
      fileData,
      token,
      rejectWithValue
    );
  }
);

export const deleteFile = createAsyncThunk(
  DELETE_FILE,
  async ({ fileId, projectId, token }, { rejectWithValue }) => {
    return fetchData(
      "delete",
      `${DELETE_FILES}?blobId=${fileId}&projectId=${projectId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

const initialState = {
  isLoading: false,
  files: [],
  isError: false,
  errorMessage: "",
};

// Common state handlers
const handlePending = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.errorMessage = "";
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload;
};

const fileManagerSlice = createSlice({
  name: "fileManager",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiles.pending, handlePending)
      .addCase(fetchFiles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.files = Array.isArray(action.payload.data)
          ? action.payload.data
          : [];
      })
      .addCase(fetchFiles.rejected, handleRejected)
      .addCase(downloadFile.pending, handlePending)
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.isLoading = false;
        const { data, filename } = action.payload;
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename || "default-filename.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .addCase(downloadFile.rejected, handleRejected)
      .addCase(uploadFile.pending, handlePending)
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.files = [...state.files, action.payload];
      })
      .addCase(uploadFile.rejected, handleRejected)
      .addCase(deleteFile.pending, handlePending)
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.files = state.files.filter(
          (file) => file.id !== action.payload.id
        );
      })
      .addCase(deleteFile.rejected, handleRejected);
  },
});

export default fileManagerSlice.reducer;
