import React, { useState } from "react";
import PropTypes from "prop-types";
import { MotifButton } from "@ey-xd/motif-react";
import "./DgaAssessmentModal.scss";

const DgaAssessmentModal = ({
  onClose,
  modalTitle,
  tabs,
  saveButtonLabel,
  cancelButtonLabel,
  disable,
  onSave,
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].label);

  const renderTabContent = () => {
    const activeTab = tabs.find((tab) => tab.label === selectedTab);
    return activeTab ? activeTab.content : null;
  };

  return (
    <div className="modal-Dga-overlay">
      <div className="custom-Dga-modal modal">
        <div className="modal-DGA-header ">
          <h2>{modalTitle}</h2>
        </div>
        <div className="modal-DGA-body">
          <div className="modal-DGA-sidebar">
            <ul>
              {tabs.map((tab) => (
                <li key={tab.label}>
                  <button
                    type="button"
                    className={`sidebar-dga-item ${
                      selectedTab === tab.label ? "active" : ""
                    }`}
                    onClick={() => setSelectedTab(tab.label)}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="modal-dga-content">{renderTabContent()}</div>
        </div>
        <div className="modal-dga-footer">
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="button cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton
              onClick={onSave}
              disabled={disable}
              className="button save-button"
            >
              {saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

DgaAssessmentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  disable: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
};

export default DgaAssessmentModal;
