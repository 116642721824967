import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_ESRS_CHECKLIST_SM,
  UPSERT_ESRS_CHECKLIST_SM,
  GET_ALL_ESRS_CHECKLIST_DR,
  UPSERT_ESRS_CHECKLIST_DR,
} from "../../services/Api";

// Utility function to handle API requests
const apiRequest = async (method, url, data, token, rejectWithValue) => {
  const config = {
    method,
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("API request error:", error);
    const errorMessage = error.response?.data?.message;

    if (!errorMessage) {
      // No specific error message found
      return rejectWithValue({
        errorMessage:
          error.response?.data?.title ||
          error.message ||
          "Unknown error occurred",
        isGenericError: true,
      });
    } else {
      return rejectWithValue({ errorMessage, isGenericError: false });
    }
  }
};

// Async thunks
export const getESRSChecklistSustainabilityMatters = createAsyncThunk(
  "esrsChecklistSustainabilityMatters/getAll",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_ALL_ESRS_CHECKLIST_SM}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const getESRSChecklistDisclosureRequirements = createAsyncThunk(
  "getESRSChecklistDisclosureRequirements/getAll",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return await apiRequest(
      "get",
      `${GET_ALL_ESRS_CHECKLIST_DR}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const upsertESRSChecklistSustainabilityMatters = createAsyncThunk(
  "esrsChecklistSustainabilityMatters/upsert",
  async ({ sustainabilityMattersData, token }, { rejectWithValue }) => {
    return await apiRequest(
      "post",
      UPSERT_ESRS_CHECKLIST_SM,
      sustainabilityMattersData,
      token,
      rejectWithValue
    );
  }
);

export const upsertDisclosureData = createAsyncThunk(
  "esrsChecklistDisclosure/upsert",
  async ({ disclosureData, token }, { rejectWithValue }) => {
    return await apiRequest(
      "post",
      UPSERT_ESRS_CHECKLIST_DR,
      disclosureData,
      token,
      rejectWithValue
    );
  }
);

// Initial state
const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  errorMessage: "",
  updateSuccess: false,
  postSuccess: false,
  isGenericError: false,
  types: [],
};

// Common reducer functions
const handlePending = (state) => {
  state.isLoading = true;
  state.updateSuccess = false;
  state.postSuccess = false;
  state.isError = false;
  state.errorMessage = "";
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.updateSuccess = true;
  state.postSuccess = true;
  state.data = action.payload;
  state.isError = false;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.updateSuccess = false;
  state.postSuccess = false;
  state.isError = true;
  state.errorMessage = action.payload.errorMessage;
  state.isGenericError = action.payload.isGenericError || false;
};

// Slice definition
const esrsChecklistSustainabilityMattersSlice = createSlice({
  name: "esrsChecklistSustainabilityMatters",
  initialState,
  reducers: {
    clearError(state) {
      state.isError = false;
      state.errorMessage = "";
      state.isGenericError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getESRSChecklistSustainabilityMatters.pending, handlePending)
      .addCase(getESRSChecklistSustainabilityMatters.fulfilled, handleFulfilled)
      .addCase(getESRSChecklistSustainabilityMatters.rejected, handleRejected)
      .addCase(getESRSChecklistDisclosureRequirements.pending, handlePending)
      .addCase(
        getESRSChecklistDisclosureRequirements.fulfilled,
        handleFulfilled
      )
      .addCase(getESRSChecklistDisclosureRequirements.rejected, handleRejected)
      .addCase(upsertESRSChecklistSustainabilityMatters.pending, handlePending)
      .addCase(
        upsertESRSChecklistSustainabilityMatters.fulfilled,
        handleFulfilled
      )
      .addCase(
        upsertESRSChecklistSustainabilityMatters.rejected,
        handleRejected
      )
      .addCase(upsertDisclosureData.pending, handlePending)
      .addCase(upsertDisclosureData.fulfilled, handleFulfilled)
      .addCase(upsertDisclosureData.rejected, handleRejected);
  },
});

export const { clearError } = esrsChecklistSustainabilityMattersSlice.actions;
export default esrsChecklistSustainabilityMattersSlice.reducer;