import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_LIKELIHOOD__URL,
  CREATE_LIKELIHOOD__URL,
  UPDATE_LIKELIHOOD__URL,
  DELETE_LIKELIHOOD__URL,
} from "../../services/Api";

// Constants for action types
const FETCH_LIKELIHOOD = "likelihood/fetchLikelihood";
const CREATE_LIKELIHOOD = "likelihood/createLikelihood";
const UPDATE_LIKELIHOOD = "likelihood/updateLikelihood";
const DELETE_LIKELIHOOD = "likelihood/deleteLikelihood";

// Helper function for API calls
const fetchData = async (method, url, data, token, rejectWithValue) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    const errorMessage = error.response?.data?.message;

    if (!errorMessage) {
      // No specific error message found
      return rejectWithValue({
        errorMessage:
          error.response?.data?.title ||
          error.message ||
          "Unknown error occurred",
        isGenericError: true,
      });
    } else {
      return rejectWithValue({ errorMessage, isGenericError: false });
    }
  }
};

// Async thunks
export const fetchLikelihood = createAsyncThunk(
  FETCH_LIKELIHOOD,
  async ({ moduleId, token }, { rejectWithValue }) => {
    return fetchData(
      "get",
      `${GET_ALL_LIKELIHOOD__URL}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const createLikelihood = createAsyncThunk(
  CREATE_LIKELIHOOD,
  async ({ moduleId, likelihoodData, token }, { rejectWithValue }) => {
    return fetchData(
      "post",
      `${CREATE_LIKELIHOOD__URL}/${moduleId}`,
      likelihoodData,
      token,
      rejectWithValue
    );
  }
);

export const updateLikelihood = createAsyncThunk(
  UPDATE_LIKELIHOOD,
  async (
    { likelihoodId, moduleId, likelihoodData, token },
    { rejectWithValue }
  ) => {
    return fetchData(
      "put",
      `${UPDATE_LIKELIHOOD__URL}/${likelihoodId}/${moduleId}`,
      likelihoodData,
      token,
      rejectWithValue
    );
  }
);

export const deleteLikelihood = createAsyncThunk(
  DELETE_LIKELIHOOD,
  async ({ likelihoodId, token, moduleId }, { rejectWithValue }) => {
    return fetchData(
      "delete",
      `${DELETE_LIKELIHOOD__URL}/${likelihoodId}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

const initialState = {
  isLoading: false,
  data: [],
  isError: false,
  errorMessage: "",
  isGenericError: false,
};

// Common state handlers
const handlePending = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.errorMessage = "";
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.data = action.payload;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload.errorMessage;
  state.isGenericError = action.payload.isGenericError || false;
};

const likelihoodSlice = createSlice({
  name: "likelihood",
  initialState,
  reducers: {
    clearError(state) {
      state.isError = false;
      state.errorMessage = "";
      state.isGenericError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLikelihood.pending, handlePending)
      .addCase(fetchLikelihood.fulfilled, handleFulfilled)
      .addCase(fetchLikelihood.rejected, handleRejected)
      .addCase(createLikelihood.pending, handlePending)
      .addCase(createLikelihood.fulfilled, handleFulfilled)
      .addCase(createLikelihood.rejected, handleRejected)
      .addCase(updateLikelihood.pending, handlePending)
      .addCase(updateLikelihood.fulfilled, handleFulfilled)
      .addCase(updateLikelihood.rejected, handleRejected)
      .addCase(deleteLikelihood.pending, handlePending)
      .addCase(deleteLikelihood.fulfilled, handleFulfilled)
      .addCase(deleteLikelihood.rejected, handleRejected);
  },
});

export const { clearError } = likelihoodSlice.actions;
export default likelihoodSlice.reducer;