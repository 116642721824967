import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifMessage,
  MotifCheckbox,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TableCell, TextField } from "@mui/material";
import "./IroModal.scss";
import ClientTable from "../../clientTable/ClientTable";
import TreeView from "../../treeView/TreeView";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

const IroModal = ({
  onClose,
  modalMode,
  modalTitle,
  nameLabel,
  typeLabel,
  areaLabel,
  pillarLabel,
  selectAreaLabel,
  SelectPillar,
  BasicLabel,
  DescriptionLabel,
  TabDescriptionLabel,
  SourceLabel,
  ValueChainLabel,
  APSLabel,
  SustainablilityMattersLabel,
  StakeholdersLabel,
  GeographiesLabel,
  saveButtonLabel,
  updateButtonLabel,
  cancelButtonLabel,
  filterByLabel,
  pillarOptions,
  areaOptions,
  handleDoneClick,
  filteredUpdateData,
  fetchTabData,
  tabData,
  setTabData,
  updateTabData,
  disable,
  InputName,
  SelectTimeframe,
  TimeframeLabel,
  timeframeOptions,
  iroTypesOptions,
  onDeleteClick,
  DeleteLabel,
  SelectType,
}) => {
  const isEditMode = modalMode === "edit";
  const isAddMode = modalMode === "add";
  const [selectedTab, setSelectedTab] = useState("Basic");
  const [name, setName] = useState("");
  const [pillar, setPillar] = useState("");
  const [timeframe, setTimeframe] = useState("");
  const [description, setDescription] = useState("");
  const [area, setArea] = useState("");
  const [type, setType] = useState(0);
  const [checkboxState, setCheckboxState] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filterValue, setFilterValue] = useState("None");
  const [toggledItems, setToggledItems] = useState({});
  const [sustainabilityCheckboxState, setSustainabilityCheckboxState] =
    useState({});
  const [nameValidation, setNameValidation] = useState(null);
  const [pillarValidation, setPillarValidation] = useState(null);
  const [typeValidation, setTypeValidation] = useState(null);
  const [areaValidation, setAreaValidation] = useState(null);
  const [descriptionError, setDescriptionError] = useState("");
  const [changedCheckboxSustainability, setChangedCheckboxSustainability] =
    useState([]);
  const idPropertyMapping = useMemo(
    () => ({
      source: "moduleSourceId",
      aps: "moduleActProServiceId",
      valuechain: "moduleValueChainId",
      geographies: "moduleGeographicalLocationId",
      stakeholders: "moduleStakeHolderId",
      sustainablilitymatters: "id",
    }),
    []
  );

  // Maximum character limit
  const MAX_CHAR_LIMIT = 3000;

  useEffect(() => {
    const defaultTimeframe = timeframeOptions?.length
      ? timeframeOptions.find((option) => option.name === "Short")
      : null;

    if (filteredUpdateData && isEditMode) {
      setName(filteredUpdateData.name || "");
      setPillar(filteredUpdateData.pillar || "");
      setArea(filteredUpdateData.area);
      setDescription(filteredUpdateData.description);
      setTimeframe(
        filteredUpdateData.timeframeTypeId === 0
          ? defaultTimeframe?.id.toString()
          : filteredUpdateData.timeframeTypeId.toString()
      );
      setType(filteredUpdateData.iroTypeId.toString() || "");
    } else if (isAddMode) {
      setTimeframe(defaultTimeframe?.id.toString());
    }
    setAreaValidation(null);
    setPillarValidation(null);
    setNameValidation(null);
  }, [filteredUpdateData, isEditMode, isAddMode, timeframeOptions]);

  const previousTab = useRef(selectedTab);

  useEffect(() => {
    if (
      !isAddMode &&
      selectedTab !== "Basic" &&
      previousTab.current !== selectedTab
    ) {
      fetchTabData(selectedTab.toLowerCase().replace(/\s+/g, ""));
      previousTab.current = selectedTab;
    }
  }, [selectedTab, isAddMode, fetchTabData]);

  useEffect(() => {
    const currentTab = selectedTab.toLowerCase().replace(/\s+/g, "");
    const idProperty = idPropertyMapping[currentTab];

    const setStateForItem = (item, newState) => {
      newState[item[idProperty]] = item.isActive;
      if (item.children) {
        item.children.forEach((child) => setStateForItem(child, newState));
      }
    };

    if (tabData[currentTab]) {
      const newState = {};
      tabData[currentTab].forEach((item) => {
        setStateForItem(item, newState);
      });
      if (currentTab === "sustainablilitymatters") {
        setSustainabilityCheckboxState(newState);
      } else {
        setCheckboxState(newState);
      }
    }
  }, [tabData, selectedTab, idPropertyMapping]);

  const generateUpdatePayload = () => changedCheckboxSustainability;

  const validateFields = () => {
    let isValid = true;
    let firstErrorTab = null;

    // Basic Tab Validations
    if (!area) {
      setAreaValidation("Area is required");
      isValid = false;
      if (!firstErrorTab) firstErrorTab = "Basic";
    } else {
      setAreaValidation("");
    }

    if (!pillar) {
      setPillarValidation("Pillar is required");
      isValid = false;
      if (!firstErrorTab) firstErrorTab = "Basic";
    } else {
      setPillarValidation("");
    }

    if (!type) {
      setTypeValidation("Type is required");
      isValid = false;
      if (!firstErrorTab) firstErrorTab = "Basic";
    } else {
      setTypeValidation("");
    }

    if (!name) {
      setNameValidation("Name is required");
      isValid = false;
      if (!firstErrorTab) firstErrorTab = "Basic";
    } else {
      setNameValidation("");
    }

    // Description Tab Validation
    if (description?.length >= MAX_CHAR_LIMIT) {
      setDescriptionError(
        `Description should not exceed ${MAX_CHAR_LIMIT} characters`
      );
      isValid = false;
      if (!firstErrorTab) firstErrorTab = "Description";
    } else {
      setDescriptionError("");
    }

    return { isValid, firstErrorTab };
  };

  const handleSubmit = () => {
    const { isValid, firstErrorTab } = validateFields();

    if (!isValid) {
      // Switch to the tab containing the first error
      if (firstErrorTab && selectedTab !== firstErrorTab) {
        setSelectedTab(firstErrorTab);
      }
    } else {
      // Proceed with saving or updating data
      if (isEditMode) {
        const tab = selectedTab.toLowerCase().replace(/\s+/g, "");
        if (tab === "sustainablilitymatters") {
          tabData.updatedsustainablilitymatters = generateUpdatePayload(
            tabData.sustainablilitymatters
          );
        }

        updateTabData(tab);
      }
      const data = {
        pillar: pillar || null,
        area: area,
        name: name,
        description: description,
        iroTypeId: parseInt(type, 10),
        timeframeTypeId: parseInt(timeframe, 10),
      };
      handleDoneClick(data);
    }
  };

  const handleCheckboxChange = (id) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

    setTabData((prevTabData) => {
      const updatedTabData = { ...prevTabData };
      const currentTab = selectedTab.toLowerCase().replace(/\s+/g, "");
      const idProperty = idPropertyMapping[currentTab];

      const itemIndex = updatedTabData[currentTab].findIndex(
        (item) => item[idProperty] === id
      );

      if (itemIndex > -1) {
        const updatedItem = {
          ...updatedTabData[currentTab][itemIndex],
          isActive: !updatedTabData[currentTab][itemIndex].isActive,
        };

        updatedTabData[currentTab] = [
          ...updatedTabData[currentTab].slice(0, itemIndex),
          updatedItem,
          ...updatedTabData[currentTab].slice(itemIndex + 1),
        ];
      }

      setToggledItems((prevToggledItems) => ({
        ...prevToggledItems,
        [id]: true,
      }));

      console.log("Updated toggledItems:", toggledItems); // Debug

      setTimeout(() => {
        setToggledItems((prevToggledItems) => {
          const { [id]: removed, ...rest } = prevToggledItems;
          return rest;
        });
      }, 300);

      return updatedTabData;
    });
  };

  const handleSustainabilityCheckboxChange = (id) => {
    setSustainabilityCheckboxState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

    const updateChildren = (items, isActive) => {
      return items.map((item) => {
        const updatedItem = {
          ...item,
          isActive: isActive,
          children: updateChildren(item.children || [], isActive),
        };
        // Track changes for children
        addToChangedCheckboxState(item.id, isActive);
        return updatedItem;
      });
    };

    const updateParents = (items) => {
      const checkAndUpdateParents = (item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach(checkAndUpdateParents);
          const wasActive = item.isActive;
          item.isActive = item.children.some((child) => child.isActive);
          if (wasActive !== item.isActive) {
            addToChangedCheckboxState(item.id, item.isActive);
          }
        }
      };
      items.forEach(checkAndUpdateParents);
      return items;
    };

    const updateItemById = (items, id, isActive) => {
      return items.map((item) => {
        if (item.id === id) {
          addToChangedCheckboxState(item.id, isActive); // Track changes for parent
          return {
            ...item,
            isActive: isActive,
            children: updateChildren(item.children || [], isActive),
          };
        } else if (item.children && item.children.length > 0) {
          return {
            ...item,
            children: updateItemById(item.children, id, isActive),
          };
        }
        return item;
      });
    };

    const addToChangedCheckboxState = (id, isActive) => {
      setChangedCheckboxSustainability((prevState) => {
        const existingIndex = prevState.findIndex(
          (item) => item.moduleSustainabilityMatterId === id
        );
        if (existingIndex !== -1) {
          // Update existing entry
          const updatedState = [...prevState];
          updatedState[existingIndex].isActive = isActive;

          // Remove if toggled back to the original state
          if (updatedState[existingIndex].originalIsActive === isActive) {
            updatedState.splice(existingIndex, 1);
          }

          return updatedState;
        }

        // Add new entry with tracking original state
        return [
          ...prevState,
          {
            moduleSustainabilityMatterId: id,
            isActive,
            originalIsActive: !isActive,
          },
        ];
      });
    };

    setTabData((prevTabData) => {
      const updatedTabData = { ...prevTabData };
      const currentTab = "sustainablilitymatters";

      const isItemActive = sustainabilityCheckboxState[id];
      updatedTabData[currentTab] = updateItemById(
        updatedTabData[currentTab],
        id,
        !isItemActive
      );
      updatedTabData[currentTab] = updateParents(updatedTabData[currentTab]);

      return updatedTabData;
    });

    // Optional: Clear toggled items (if applicable)
    setTimeout(() => {
      setToggledItems((prevToggledItems) => {
        const { [id]: removed, ...rest } = prevToggledItems;
        return rest;
      });
    }, 300);
  };

  const renderTableCell = (colName, value, row, index) => {
    const currentTab = selectedTab.toLowerCase().replace(/\s+/g, "");
    const idProperty = idPropertyMapping[currentTab];
    const rowId = row[idProperty];

    if (colName === "status") {
      return (
        <TableCell
          key={index}
          style={{ textAlign: "center", verticalAlign: "middle" }}
        >
          <MotifCheckbox
            id={`checkbox-${rowId}`}
            name={`checkbox-${rowId}`}
            value="Active"
            className="iro-checkbox"
            checked={checkboxState[rowId] || false}
            onChange={() => handleCheckboxChange(rowId)}
          >
            Active
          </MotifCheckbox>
        </TableCell>
      );
    }
    return <TableCell key={index}>{value}</TableCell>;
  };

  const generateRowKey = (row) =>
    row[idPropertyMapping[selectedTab.toLowerCase().replace(/\s+/g, "")]];

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterTreeData = (data, query, status) => {
    const filteredData = [];

    const traverse = (items) => {
      return items
        .map((item) => {
          const matchesSearchQuery = item.label
            ?.toLowerCase()
            .includes(query.toLowerCase());
          const matchesStatus =
            status === "None" ||
            (status === "Active" && item.isActive) ||
            (status === "Inactive" && !item.isActive);

          if (matchesSearchQuery && matchesStatus) {
            return {
              ...item,
              children: item.children ? traverse(item.children) : [],
            };
          }

          if (item.children) {
            const filteredChildren = traverse(item.children);
            if (filteredChildren.length > 0) {
              return { ...item, children: filteredChildren };
            }
          }

          return null;
        })
        .filter(Boolean);
    };

    traverse(data).forEach((item) => {
      if (item) filteredData.push(item);
    });

    return filteredData;
  };

  const filterData = (data) => {
    return data.filter((item) => {
      const matchesSearchQuery =
        item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.typeName?.toLowerCase().includes(searchQuery.toLowerCase());

      if (filterValue === "Active") {
        return (
          matchesSearchQuery &&
          (item.isActive ||
            toggledItems[
              item[
                idPropertyMapping[selectedTab.toLowerCase().replace(/\s+/g, "")]
              ]
            ])
        );
      } else if (filterValue === "Inactive") {
        return (
          matchesSearchQuery &&
          (!item.isActive ||
            toggledItems[
              item[
                idPropertyMapping[selectedTab.toLowerCase().replace(/\s+/g, "")]
              ]
            ])
        );
      } else {
        return matchesSearchQuery;
      }
    });
  };
  const getTabData = () => {
    switch (selectedTab) {
      case "Source":
        return tabData.source.map((item) => ({
          ...item,
          typeName: item.sourceType,
        }));
      case "APS":
        return tabData.aps.map((item) => ({
          ...item,
          typeName: item.actProServiceType,
        }));
      case "Value chain":
        return tabData.valuechain.map((item) => ({
          ...item,
          typeName: item.valueChainType,
        }));
      case "Stakeholders":
        return tabData.stakeholders.map((item) => ({
          ...item,
          typeName: item.stakeHolderType,
        }));
      case "Sustainablility matters":
        return tabData.sustainablilitymatters || [];
      default:
        return tabData[selectedTab.toLowerCase().replace(/\s+/g, "")] || [];
    }
  };

  const renderTabContent = () => {
    if (selectedTab === "Basic") {
      return (
        <>
          <div className="form-row">
            <div className="form-group">
              <MotifMessage>{pillarLabel}*</MotifMessage>
              <MotifFormField>
                <MotifLabel id="select-dma-select-label" position="in">
                  {SelectPillar}
                </MotifLabel>
                <MotifSelect
                  className="iro-select"
                  ariaLabelledBy="select-dma-select-label"
                  labelPosition="in"
                  visibleOptions={3}
                  value={pillar}
                  onChange={(e) => setPillar(e)}
                >
                  {pillarOptions.map((option) => (
                    <MotifOption key={option.value} value={option.value}>
                      {option.label}
                    </MotifOption>
                  ))}
                </MotifSelect>
                {pillarValidation && (
                  <MotifErrorMessage>{pillarValidation}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
            <div className="form-group">
              <MotifMessage>{areaLabel}*</MotifMessage>
              <MotifFormField>
                <MotifLabel id="select-dma-select-label" position="in">
                  {selectAreaLabel}
                </MotifLabel>
                <MotifSelect
                  className="iro-select"
                  ariaLabelledBy="select-dma-select-label"
                  labelPosition="in"
                  visibleOptions={3}
                  value={area}
                  onChange={(e) => setArea(e)}
                >
                  {areaOptions.map((option) => (
                    <MotifOption key={option.value} value={option.value}>
                      {option.label}
                    </MotifOption>
                  ))}
                </MotifSelect>
                {areaValidation && (
                  <MotifErrorMessage>{areaValidation}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group half-width">
              <MotifMessage data-testid="type">{typeLabel}*</MotifMessage>
              <MotifFormField className="filter-by-select">
                <MotifLabel id="select-dma-select-label" position="in">
                  {SelectType}
                </MotifLabel>
                <MotifSelect
                  className="iro-textField"
                  ariaLabelledBy="select-dma-select-label"
                  labelPosition="in"
                  visibleOptions={3}
                  value={type}
                  onChange={(e) => setType(e)}
                  disabled={isEditMode}
                >
                  {iroTypesOptions.map((option) => (
                    <MotifOption key={option.id} value={option.id.toString()}>
                      {option.name}
                    </MotifOption>
                  ))}
                </MotifSelect>
                {typeValidation && (
                  <MotifErrorMessage>{typeValidation}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
            <div className="form-group half-width">
              <MotifMessage data-testid="name">{nameLabel}*</MotifMessage>
              <MotifFormField>
                <MotifLabel
                  id="select-dma-name-input-label"
                  position="in"
                  htmlFor="name-input"
                >
                  {InputName}
                </MotifLabel>
                <MotifInput
                  className="session-modal-input session-name-input"
                  labelPosition="in"
                  aria-describedby="select-dma-name-input-label"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {nameValidation && (
                  <MotifErrorMessage>{nameValidation}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group half-width">
              <MotifMessage data-testid="type">{TimeframeLabel}</MotifMessage>
              <MotifFormField className="filter-by-select">
                <MotifLabel id="select-dma-select-label" position="in">
                  {SelectTimeframe}
                </MotifLabel>
                <MotifSelect
                  ariaLabelledBy="select-dma-select-label"
                  labelPosition="in"
                  visibleOptions={3}
                  value={timeframe}
                  onChange={(e) => setTimeframe(e)}
                  className="iro-textField"
                >
                  {timeframeOptions.map((option) => (
                    <MotifOption key={option.id} value={option.id.toString()}>
                      {option.name}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
            </div>
          </div>
        </>
      );
    }

    if (selectedTab === "Description") {
      return (
        <div className="form-row sustain-description-label">
          <MotifLabel id="select-label" data-testid="description-label">
            {DescriptionLabel}
          </MotifLabel>
          <TextField
            placeholder=""
            multiline
            fullWidth
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {descriptionError && (
            <MotifErrorMessage>{descriptionError}</MotifErrorMessage>
          )}
        </div>
      );
    }

    if (selectedTab === "Sustainablility matters") {
      const filteredData = filterTreeData(
        tabData.sustainablilitymatters || [],
        searchQuery,
        filterValue
      );
      return (
        <>
          <div className="search-filter-container">
            <Paper component="form" className="search-container">
              <IconButton
                type="button"
                className="search-icon-iro"
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                className="search-box-input-iro"
                placeholder="Search"
                onChange={handleSearch}
                inputProps={{ "aria-label": "search For" }}
              />
            </Paper>
            <div className="filter-container">
              <MotifFormField className="filter-by-select">
                <MotifLabel id="select-dma-select-label" position="in">
                  {filterByLabel}
                </MotifLabel>
                <MotifSelect
                  ariaLabelledBy="select-dma-select-label"
                  labelPosition="in"
                  visibleOptions={3}
                  className="iro-textField"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e)}
                >
                  <MotifOption value="None">None</MotifOption>
                  <MotifOption value="Active">Active</MotifOption>
                  <MotifOption value="Inactive">Inactive</MotifOption>
                </MotifSelect>
              </MotifFormField>
            </div>
          </div>
          <div className="iro-sm-container">
            <TreeView
              items={filteredData}
              defaultExpandedItems={[]}
              defaultSelectedItems=""
              pageName="IROModal"
              checkboxState={sustainabilityCheckboxState}
              handleCheckboxChange={handleSustainabilityCheckboxChange}
            />
          </div>
        </>
      );
    }

    const tableData = getTabData();

    const commonColumns = [
      { colName: "name", label: "Name", showSorting: true },
      { colName: "status", label: "Status", showSorting: false },
    ];

    const columnsWithType = [
      { colName: "name", label: "Name", showSorting: true },
      { colName: "typeName", label: "Type", showSorting: true },
      { colName: "status", label: "Status", showSorting: false },
    ];

    return (
      <>
        <div className="search-filter-container">
          <Paper component="form" className="search-container">
            <IconButton
              type="button"
              className="search-icon-iro"
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className="search-box-input-iro"
              placeholder="Search"
              onChange={handleSearch}
              inputProps={{ "aria-label": "search For" }}
            />
          </Paper>
          <div className="filter-container">
            <MotifFormField className="filter-by-select">
              <MotifLabel id="select-dma-select-label" position="in">
                {filterByLabel}
              </MotifLabel>
              <MotifSelect
                ariaLabelledBy="select-dma-select-label"
                labelPosition="in"
                visibleOptions={3}
                className="iro-textField"
                value={filterValue}
                onChange={(e) => setFilterValue(e)}
              >
                <MotifOption value="None">None</MotifOption>
                <MotifOption value="Active">Active</MotifOption>
                <MotifOption value="Inactive">Inactive</MotifOption>
              </MotifSelect>
            </MotifFormField>
          </div>
        </div>
        <div className="iro-table-container">
          <ClientTable
            columns={
              selectedTab === "Geographies" ? commonColumns : columnsWithType
            }
            data={filterData(tableData)}
            itemsPerPage={500}
            renderTableCell={renderTableCell}
            generateRowKey={generateRowKey}
            showPagination={false}
          />
        </div>
      </>
    );
  };

  return (
    <div className="modal-overlay">
      <div className="sustain-modal modal">
        <div className="modal-header">
          <h2>{modalTitle}</h2>
        </div>
        <div className="modal-body">
          <div className="modal-sidebar">
            <ul>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Basic" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Basic")}
                >
                  {BasicLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Description" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Description")}
                >
                  {TabDescriptionLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Source" ? "active" : ""
                  }
                  } ${isAddMode ? "disabled" : ""}`}
                  onClick={() => setSelectedTab("Source")}
                >
                  {SourceLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Value chain" ? "active" : ""
                  }
                  } ${isAddMode ? "disabled" : ""}`}
                  onClick={() => setSelectedTab("Value chain")}
                >
                  {ValueChainLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "APS" ? "active" : ""
                  }
                  } ${isAddMode ? "disabled" : ""}`}
                  onClick={() => setSelectedTab("APS")}
                >
                  {APSLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Geographies" ? "active" : ""
                  }
                  } ${isAddMode ? "disabled" : ""}`}
                  onClick={() => setSelectedTab("Geographies")}
                >
                  {GeographiesLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Stakeholders" ? "active" : ""
                  }
                  } ${isAddMode ? "disabled" : ""}`}
                  onClick={() => setSelectedTab("Stakeholders")}
                >
                  {StakeholdersLabel}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Sustainablility matters" ? "active" : ""
                  }
                  } ${isAddMode ? "disabled" : ""}`}
                  onClick={() => setSelectedTab("Sustainablility matters")}
                >
                  {SustainablilityMattersLabel}
                </button>
              </li>
            </ul>
          </div>
          <div className="modal-content">{renderTabContent()}</div>
        </div>
        <div className="dma-modal-footer">
          <div className="left-buttons">
            {isEditMode && (
              <MotifButton
                className="button delete-button"
                onClick={onDeleteClick}
              >
                {DeleteLabel}
              </MotifButton>
            )}
          </div>
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="button cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton
              onClick={handleSubmit}
              disabled={disable}
              className="button done-button"
            >
              {isEditMode ? updateButtonLabel : saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

IroModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  nameLabel: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  areaLabel: PropTypes.string.isRequired,
  pillarLabel: PropTypes.string.isRequired,
  selectAreaLabel: PropTypes.string.isRequired,
  SelectPillar: PropTypes.string.isRequired,
  BasicLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  TabDescriptionLabel: PropTypes.string.isRequired,
  SourceLabel: PropTypes.string.isRequired,
  ValueChainLabel: PropTypes.string.isRequired,
  APSLabel: PropTypes.string.isRequired,
  SustainablilityMattersLabel: PropTypes.string.isRequired,
  StakeholdersLabel: PropTypes.string.isRequired,
  GeographiesLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  filterByLabel: PropTypes.string.isRequired,
  pillarOptions: PropTypes.array,
  areaOptions: PropTypes.array,
  handleDoneClick: PropTypes.func.isRequired,
  filteredUpdateData: PropTypes.object,
  fetchTabData: PropTypes.func.isRequired,
  tabData: PropTypes.object.isRequired,
  setTabData: PropTypes.func.isRequired,
  updateTabData: PropTypes.func.isRequired,
  TimeframeLabel: PropTypes.string.isRequired,
  timeframeOptions: PropTypes.array,
  iroTypesOptions: PropTypes.array,
  onDeleteClick: PropTypes.func.isRequired,
  DeleteLabel: PropTypes.string.isRequired,
  SelectType: PropTypes.string.isRequired,
  SelectTimeframe: PropTypes.string.isRequired,
  InputName: PropTypes.string.isRequired,
  disable: PropTypes.bool,
};

export default IroModal;
