import React, {
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  useState,
} from "react";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import "./ProjectHome.scss";
import Header from "../../components/headers/Header";
import SessionModulelanguageData from "../projectHome/sessionAccordion/Session_Module.json";
import Accordion from "../projectHome/sessionAccordion/SessionAccordion";
import ProjectDetails from "./projectDetails/ProjectDetails";
import Subheader from "../../components/subheader/Subheader";
import languageData from "../../constants/languages/en/translations.json";
import { useParams, useNavigate } from "react-router-dom";
import AddUser from "../../components/modals/addUser/AddUser";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectDetails,
  createSessionInProject,
  createModuleInProject,
  updateSessionDetails,
  retireSession,
  retireModule,
  fetchAllModuleTypesForSession,
  fetchModuleById,
  updateModule,
  getAllSessionFrameWork,
  clearError,
} from "../../features/slices/ProjectScreens";
import ErrorModal from "../../components/modals/error/errorModal";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { handleError as handleLogoutError } from "../../utils/handleError";

const initialState = {
  isModalOpen: false,
  isModuleModalOpen: false,
  sessionName: "",
  sessionFramework: "",
  modalType: "",
  searchQuery: "",
  sessionId: "",
  moduleId: "",
  updateTrigger: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_IS_MODAL_OPEN":
      return { ...state, isModalOpen: action.payload };
    case "SET_IS_MODULE_MODAL_OPEN":
      return { ...state, isModuleModalOpen: action.payload };
    case "SET_SESSION_NAME":
      return { ...state, sessionName: action.payload };
    case "SET_SESSION_FRAMEWORK":
      return { ...state, sessionFramework: action.payload };
    case "SET_MODAL_TYPE":
      return { ...state, modalType: action.payload };
    case "SET_SEARCH_QUERY":
      return { ...state, searchQuery: action.payload };
    case "SET_SESSION_ID":
      return { ...state, sessionId: action.payload };
    case "SET_MODULE_ID":
      return { ...state, moduleId: action.payload };
    case "INCREMENT_UPDATE_TRIGGER":
      return { ...state, updateTrigger: state.updateTrigger + 1 };
    default:
      return state;
  }
};

const ProjectHome = () => {
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const isAdmin = true;
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { projectId: projectIdString } = useParams();
  const projectId = parseInt(projectIdString);
  const [moduleTypes, setModuleTypes] = useState([]);
  const [moduleDataById, setModuleDataById] = useState();
  const [sessionFrameWorkDropDown, setSessionFrameWorkDropDown] = useState([]);

  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { data, isError, isLoading, errorMessage, isGenericError } =
    useSelector((state) => state.projectDetails);
  const projectData = useMemo(() => data?.data || {}, [data]);
  const sessionsData = useMemo(
    () => projectData.sessions || SessionModulelanguageData,
    [projectData]
  );
  const projectName = projectData?.name;

  useEffect(() => {
    dispatch(fetchProjectDetails({ projectId, token }));
  }, [dispatch, projectId, token]);

  const fetchSessionFrameWork = useCallback(async () => {
    try {
      const response = await dispatch(
        getAllSessionFrameWork({ token })
      ).unwrap();
      if (response.success) {
        setSessionFrameWorkDropDown(response.data.frameworks || []);
      }
    } catch (error) {
      console.error("Fetching of session frameworks failed:", error);
    }
  }, [dispatch, token]);

  const handleSessionModal = useCallback(async () => {
    await fetchSessionFrameWork();
    dispatchLocal({ type: "SET_IS_MODAL_OPEN", payload: true });
    dispatchLocal({
      type: "SET_MODAL_TYPE",
      payload: languageData.AddSessionPopup,
    });
  }, [fetchSessionFrameWork]);

  const fetchModuleTypes = useCallback(
    async (frameworkTypeId) => {
      try {
        const response = await dispatch(
          fetchAllModuleTypesForSession({ frameworkTypeId, token })
        ).unwrap();
        if (response.success) {
          setModuleTypes(response.data);
        }
      } catch (error) {
        console.error("Fetching of module types failed:", error);
      }
    },
    [dispatch, token]
  );

  const fetchModuleByID = useCallback(
    async (id, frameworkTypeId) => {
      try {
        const response = await dispatch(
          fetchAllModuleTypesForSession({ frameworkTypeId, token })
        ).unwrap();
        if (response.success) {
          setModuleTypes(response.data);
        }
      } catch (error) {
        console.error("Fetching of module types failed:", error);
      }
      try {
        const response = await dispatch(
          fetchModuleById({ id, token })
        ).unwrap();
        if (response.success) {
          setModuleDataById(response.data);
        }
      } catch (error) {
        console.error("Fetching of module types failed:", error);
      }
    },
    [dispatch, token]
  );

  const handleModuleModal = useCallback(
    async (sessionName, id, frameworkTypeId) => {
      await fetchModuleTypes(frameworkTypeId);
      dispatchLocal({ type: "SET_IS_MODULE_MODAL_OPEN", payload: true });
      dispatchLocal({ type: "SET_SESSION_NAME", payload: sessionName });
      dispatchLocal({ type: "SET_SESSION_ID", payload: id });
      dispatchLocal({
        type: "SET_MODAL_TYPE",
        payload: languageData.AddModulePopup,
      });
    },
    [fetchModuleTypes]
  );

  const handleCloseModal = useCallback(() => {
    dispatchLocal({ type: "SET_IS_MODAL_OPEN", payload: false });
    dispatchLocal({ type: "SET_SESSION_NAME", payload: "" });
    dispatchLocal({ type: "SET_SESSION_FRAMEWORK", payload: "" });
  }, []);

  const handleModuleCloseModal = useCallback(() => {
    dispatchLocal({ type: "SET_IS_MODULE_MODAL_OPEN", payload: false });
    dispatchLocal({ type: "SET_SESSION_NAME", payload: "" });
    dispatchLocal({ type: "SET_SESSION_FRAMEWORK", payload: "" });
  }, []);

  //New functions for handling edit and delete session modals
  const handleEditSessionModal = useCallback(
    async (sessionId, sessionName, sessionFramework) => {
      await fetchSessionFrameWork();
      dispatchLocal({ type: "SET_IS_MODAL_OPEN", payload: true });
      dispatchLocal({
        type: "SET_MODAL_TYPE",
        payload: languageData.EditSessionPopup,
      });
      dispatchLocal({ type: "SET_SESSION_ID", payload: sessionId });
      dispatchLocal({ type: "SET_SESSION_NAME", payload: sessionName });
      dispatchLocal({
        type: "SET_SESSION_FRAMEWORK",
        payload: sessionFramework,
      });
    },
    [fetchSessionFrameWork]
  );

  const handleDeleteSessionModal = useCallback((sessionId, sessionName) => {
    dispatchLocal({ type: "SET_IS_MODAL_OPEN", payload: true });
    dispatchLocal({
      type: "SET_MODAL_TYPE",
      payload: languageData.RetireSessionPopup,
    });
    dispatchLocal({ type: "SET_SESSION_ID", payload: sessionId });
    dispatchLocal({ type: "SET_SESSION_NAME", payload: sessionName });
  }, []);

  const handleDeleteModuleModal = useCallback((moduleId) => {
    dispatchLocal({ type: "SET_IS_MODAL_OPEN", payload: true });
    dispatchLocal({
      type: "SET_MODAL_TYPE",
      payload: languageData.RetireModulePopup,
    });
    dispatchLocal({ type: "SET_MODULE_ID", payload: moduleId });
  }, []);

  // New functions for handling edit module  modal

  const handleEditModuleModal = useCallback(
    async (id, sessionName, frameworkTypeId) => {
      await fetchModuleByID(id, frameworkTypeId);

      dispatchLocal({ type: "SET_IS_MODULE_MODAL_OPEN", payload: true });
      dispatchLocal({ type: "SET_SESSION_NAME", payload: sessionName });
      dispatchLocal({ type: "SET_SESSION_ID", payload: id });
      dispatchLocal({
        type: "SET_MODAL_TYPE",
        payload: languageData.EditModulePopup,
      });
    },
    [fetchModuleByID]
  );

  const createSession = useCallback(
    async (sessionData) => {
      if (sessionData.name && sessionData.frameworkId) {
        sessionData.projectId = parseFloat(projectId);
        dispatch(createSessionInProject({ sessionData, token }))
          .unwrap()
          .then((response) => {
            if (response.success) {
              dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
              handleCloseModal();
              dispatch(fetchProjectDetails({ projectId, token }));
            }
          })
          .catch((error) => {
            console.error("creation failed:", error);
          });
      } else {
        alert("session name and framework creation failed");
      }
    },
    [dispatch, projectId, token, handleCloseModal]
  );

  const updateSession = useCallback(
    async (sessionData) => {
      if (sessionData.name && sessionData.id) {
        dispatch(
          updateSessionDetails({
            name: sessionData.name,
            id: sessionData.id,
            token,
            projectId,
          })
        )
          .unwrap()
          .then((response) => {
            if (response.success) {
              dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
              handleCloseModal();
              dispatch(fetchProjectDetails({ projectId, token }));
            }
          })
          .catch((error) => {
            console.error("Update failed:", error);
          });
      } else {
        alert("Please fill in all required fields");
      }
    },
    [dispatch, token, handleCloseModal, projectId]
  );

  const handleRetireSession = useCallback(
    async (sessionData) => {
      if (sessionData.id) {
        dispatch(retireSession({ id: sessionData.id, token, projectId }))
          .unwrap()
          .then((response) => {
            if (response) {
              dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
              handleCloseModal();
              dispatch(fetchProjectDetails({ projectId, token }));
            }
          })
          .catch((error) => {
            console.error("Retire failed:", error);
          });
      } else {
        alert("Please fill in all required fields");
      }
    },
    [dispatch, token, handleCloseModal, projectId]
  );

  const handleRetireModule = useCallback(
    async (moduleData) => {
      if (moduleData.id) {
        dispatch(retireModule({ id: moduleData.id, token, projectId }))
          .unwrap()
          .then((response) => {
            if (response) {
              dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
              handleCloseModal();
              dispatch(fetchProjectDetails({ projectId, token }));
            }
          })
          .catch((error) => {
            console.error("Retire failed:", error);
          });
      } else {
        alert("Please fill in all required fields");
      }
    },
    [dispatch, token, handleCloseModal, projectId]
  );

  const createModule = useCallback(
    (moduleData) => {
      if (moduleData.sessionId) {
        dispatch(createModuleInProject({ moduleData, token }))
          .unwrap()
          .then((response) => {
            if (response.success) {
              dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
              handleModuleCloseModal();
              dispatch(fetchProjectDetails({ projectId, token }));
            }
          })
          .catch((error) => {
            console.error("creation failed:", error);
          });
      } else {
        alert("Please fill in all required fields");
      }
    },
    [dispatch, token, handleModuleCloseModal, projectId]
  );

  const updateModuleId = useCallback(
    async (moduleDataEdited) => {
      if (moduleDataEdited) {
        dispatch(updateModule({ moduleDataEdited, token }))
          .unwrap()
          .then((response) => {
            if (response.success) {
              dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
              handleModuleCloseModal();
              dispatch(fetchProjectDetails({ projectId, token }));
            }
          })
          .catch((error) => {
            console.error("Update failed:", error);
          });
      } else {
        alert("Please fill in all required fields");
      }
    },
    [dispatch, token, projectId, handleModuleCloseModal]
  );

  const handleClientOrg = useCallback(() => {
    navigate(`/client-org/${projectId}`, { state: { projectName, token } });
  }, [navigate, projectId, token, projectName]);

  const handleUserManagement = useCallback(() => {
    navigate(`/user-management/${projectId}`, {
      state: { projectName, token },
    });
  }, [navigate, projectId, token, projectName]);

  const handleFileManager = useCallback(() => {
    navigate(`/file-manager/${projectId}`, { state: { projectName, token } });
  }, [navigate, projectId, token, projectName]);

  const handleEditNavigate = useCallback(() => {
    navigate(`/edit-project/${projectId}`, { state: { projectName, token } });
  }, [navigate, projectId, token, projectName]);

  const loggedInUserEmail = accounts[0]?.username;
  const isUserAdmin = useMemo(() => {
    return data?.data?.users?.some(
      (user) =>
        user.user.email === loggedInUserEmail &&
        user.role.name === "Project admin"
    );
  }, [data, loggedInUserEmail]);

  const handleSearch = useCallback((event) => {
    dispatchLocal({ type: "SET_SEARCH_QUERY", payload: event.target.value });
  }, []);

  const filteredSessionsData = useMemo(() => {
    const query = state.searchQuery.toLowerCase();
    return sessionsData?.filter((session) =>
      session?.name?.toLowerCase().includes(query)
    );
  }, [sessionsData, state.searchQuery]);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);

  // Whenever isError or errorMessage changes, reset showErrorModal
  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? handleLogoutError(dispatch, navigate)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }
  return (
    <div>
      <Header languageData-testid="header" />
      <div className="Container">
        <Breadcrumbs
          items={[
            { label: "All projects", link: "/" },
            {
              label: projectData.name,
              link: `/project-home/${projectId}`,
            },
          ]}
        />
        <Subheader
          isAdmin={isAdmin}
          page={languageData.ProjectHomeAccessTitle}
          title={projectData.name}
          languageData-testid="subheader"
          handleSessionModal={handleSessionModal}
          handleClientOrg={handleClientOrg}
          handleUserManagement={handleUserManagement}
          handleFileManager={handleFileManager}
          ProjectHomeAccessTitle={t("ProjectHomeAccessTitle")}
          ClientOrgTitleEN={t("ClientOrgTitle")}
          disableUser={!isUserAdmin}
          UserManagementTitleEN={t("UserManagementTitle")}
          NewSessionTitleEN={t("NewSessionTitle")}
          FileManagerButtonTitle={t("FileManager")}
          onChangeSearchInput={handleSearch}
          disableButton={isLoading}
        />
        {state.modalType === languageData.AddSessionPopup && (
          <AddUser
            isOpen={state.isModalOpen}
            onClose={handleCloseModal}
            projectId={projectId}
            NewSessionTitleEN={t("NewSessionTitle")}
            SessionNameContentEN={t("SessionNameContent")}
            SessionFrameworkEN={t("SessionFramework")}
            SelectSessionFrameworkEN={t("SelectSessionFramework")}
            ESRSEN={t("ESRS")}
            ISSBEN={t("ISSB")}
            GRIEN={t("GRI")}
            CancelButtonEN={t("Cancel")}
            DoneButtonEN={t("DoneButton")}
            page={languageData.AddSessionPopup}
            SessionNameEN={t("SessionName")}
            AddSessionPopupEN={t("AddSessionPopup")}
            callApi={createSession}
            isDisabled={isLoading}
            sessionFrameWorkArray={sessionFrameWorkDropDown}
          />
        )}
        {state.modalType === languageData.EditSessionPopup && (
          <AddUser
            isOpen={state.isModalOpen}
            onClose={handleCloseModal}
            NewSessionTitleEN={t("EditSessionTitle")}
            SessionNameContentEN={t("SessionNameContent")}
            SessionFrameworkEN={t("SessionFramework")}
            SelectSessionFrameworkEN={t("SelectSessionFramework")}
            sessionId={state.sessionId}
            SelectedsessionName={state.sessionName}
            SelectedsessionFramework={state.sessionFramework}
            AddModulePopupEN={t("AddModulePopup")}
            NewModuleTitleEN={t("NewModuleTitle")}
            SessionNameEN={t("SessionName")}
            ModuleEN={t("Module")}
            SelectModuleEN={t("SelectModule")}
            ESRSEN={t("ESRS")}
            CancelButtonEN={t("Cancel")}
            DoneButtonEN={t("DoneButton")}
            page={languageData.EditSessionPopup}
            EditSessionPopupEN={t("EditSessionPopup")}
            callApi={updateSession}
            isDisabled={isLoading}
            handleDeleteSession={() =>
              handleDeleteSessionModal(state.sessionId, state.sessionName)
            }
            DeleteButton={t("DeleteLabel")}
            sessionFrameWorkArray={sessionFrameWorkDropDown}
          />
        )}
        {state.modalType === languageData.RetireSessionPopup && (
          <AddUser
            isOpen={state.isModalOpen}
            onClose={handleCloseModal}
            page={languageData.RetireSessionPopup}
            RetireSessionPopup={t("RetireSessionPopup")}
            ConfirmationTitle={t("DeleteLabel")}
            ConfirmationEN={t("DeleteLabel")}
            ConfirmationDeleteMsgEN={t("ConfirmationRetireSession")}
            CancelButtonEN={t("Cancel")}
            sessionId={state.sessionId}
            callApi={handleRetireSession}
            isDisabled={isLoading}
          />
        )}
        {state.modalType === languageData.RetireModulePopup && (
          <AddUser
            isOpen={state.isModalOpen}
            onClose={handleCloseModal}
            page={languageData.RetireModulePopup}
            RetireModulePopup={t("RetireModulePopup")}
            ConfirmationTitle={t("DeleteLabel")}
            ConfirmationEN={t("DeleteLabel")}
            ConfirmationDeleteMsgEN={t("ConfirmationRetireModule")}
            CancelButtonEN={t("Cancel")}
            isDisabled={isLoading}
            callApi={handleRetireModule}
            moduleId={state.moduleId}
          />
        )}
        {isError}
        {isAdmin && (
          <ProjectDetails
            languageData-testid="project-details"
            ProjectDetailTitle={t("ProjectDetailTitle")}
            EditEN={t("Edit")}
            projectName={t("ProjectName")}
            clientName={t("ClientName")}
            country={t("Country")}
            engagementcode={t("Engagementcode")}
            projectSizeUSD={t("ProjectSizeUSD")}
            clientSector={t("ClientSector")}
            projectAdministrator={"ProjectAdministrator"}
            data={projectData}
            handleEditNavigate={handleEditNavigate}
          />
        )}
        <Accordion
          data={filteredSessionsData || []}
          isAdmin={isAdmin}
          handleModuleModal={handleModuleModal}
          handleEditSessionModal={handleEditSessionModal}
          handleDeleteSessionModal={handleDeleteSessionModal}
          handleEditModuleModal={handleEditModuleModal}
          ModuleTitleEN={t("ModuleTitle")}
          projectName={projectName}
          sessionData={sessionsData}
          projectId={projectId}
        />
        {state.modalType === languageData.AddModulePopup && (
          <AddUser
            isOpen={state.isModuleModalOpen}
            onClose={handleModuleCloseModal}
            projectId={projectId}
            sessionId={state.sessionId}
            SelectedsessionName={state.sessionName}
            AddModulePopupEN={t("AddModulePopup")}
            NewModuleTitleEN={t("NewModuleTitle")}
            SessionNameEN={t("SessionName")}
            ModuleEN={t("Module")}
            SelectModuleEN={t("SelectModule")}
            DMAEN={t("DMA")}
            HLEN={t("HL")}
            DDEN={t("DD")}
            CancelButtonEN={t("Cancel")}
            DoneButtonEN={t("DoneButton")}
            page={languageData.AddModulePopup}
            callApi={createModule}
            clientReadyLabel={t("clientReadyLabel")}
            nameLabel={t("Name")}
            enterNamePlaceholder={t("enterNamePlaceholder")}
            clientReadyPlaceholder={t("channelPlaceholder")}
            yesLabel={t("yesLabel")}
            noLabel={t("noLabel")}
            moduleTypes={moduleTypes}
            isDisabled={isLoading}
          />
        )}

        {state.modalType === languageData.EditModulePopup && (
          <AddUser
            isOpen={state.isModuleModalOpen}
            onClose={handleModuleCloseModal}
            sessionId={state.sessionId}
            SelectedsessionName={state.sessionName}
            AddModulePopupEN={t("AddModulePopup")}
            EditModulePopupEN={t("EditModulePopup")}
            NewModuleTitleEN={t("EditModuleTitle")}
            SessionNameEN={t("SessionName")}
            ModuleEN={t("Module")}
            SelectModuleEN={t("SelectModule")}
            DMAEN={t("DMA")}
            HLEN={t("HL")}
            DDEN={t("DD")}
            CancelButtonEN={t("Cancel")}
            DoneButtonEN={t("UpdateButton")}
            page={languageData.EditModulePopup}
            callApi={updateModuleId}
            clientReadyLabel={t("clientReadyLabel")}
            nameLabel={t("Name")}
            enterNamePlaceholder={t("enterNamePlaceholder")}
            clientReadyPlaceholder={t("channelPlaceholder")}
            yesLabel={t("yesLabel")}
            noLabel={t("noLabel")}
            moduleTypes={moduleTypes}
            moduleNameUpdated={moduleDataById?.name}
            isClientReadyUpdated={
              moduleDataById?.isClientReady === true ? "Yes" : "No"
            }
            moduleTypeId={moduleDataById?.moduleTypeId}
            moduleIdToUpdateApi={moduleDataById?.id}
            DeleteButton={t("DeleteLabel")}
            handleDeleteSession={() =>
              handleDeleteModuleModal(moduleDataById?.id)
            }
          />
        )}
      </div>
      {isLoading && (
        <MotifProgressLoader className="loader" show variant="default" />
      )}
    </div>
  );
};

export default ProjectHome;