import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLeft, EditSvg, Dot, Templates } from "../../constants/icons/Icons";
import Header from "../../components/headers/Header";
import Card from "../../components/card/Card";
import "./InstanceMaintenance.scss";
import { useNavigate } from "react-router-dom";
import ChangeStatusModal from "./ChangeStatusModal";
import { MotifButton, MotifProgressLoader } from "@ey-xd/motif-react";
import maintenanceLanguage from "../../constants/languages/en/translations.json";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import ErrorModal from "../../components/modals/error/errorModal";
import {
  getAllStatus,
  getMaintainenceStatus,
  getAllModules,
  copyAllModuleTablesTotemplate,
  copySourcesToTemplate,
  copyValueChainToTemplate,
  copyActProServiceToTemplate,
  copyGeographicalLocationToTemplate,
  copyStakeHolderToTemplate,
} from "../../features/slices/InstanceMaintenance";
import { useTranslation } from "react-i18next";
import TemplateModal from "./TemplateModal";
import AllProjects from "./AllProjects";
import DeleteModal from "../../components/modals/deleteModal/DeleteModal";
import { handleError } from "../../utils/handleError";

const columns = [
  { colName: "projectName", label: "Project name", showSorting: true },
  { colName: "name", label: "DMA module name", showSorting: true },
];

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
};

const templateOptions = [
  { value: "all", label: "All" },
  { value: "sources", label: "Sources" },
  { value: "valueChain", label: "Value chain" },
  {
    value: "activitiesProductsAndServices",
    label: "Activities product and services",
  },
  { value: "geographicalLocation", label: "Geographical location" },
  { value: "stakeholders", label: "Stakeholders" },
];

const InstanceMaintenance = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [allStatusData, setAllStatusData] = useState([]);
  const [status, setStatus] = useState("");
  const [instanceName, setInstanceName] = useState("");
  const [modulesData, setModulesData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedRows, setSelectedRows] = useState({});
  const navigate = useNavigate();
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const reduxDispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    reduxDispatch(getAllStatus({ token }));
  }, [reduxDispatch, token]);

  const {
    data: responseData,
    isLoading,
    isError,
    errorMessage,
  } = useSelector(
    (state) => state.allStatus || { data: null, isLoading: false }
  );

  const labelMappingApiResponse = useMemo(
    () => ({
      totalProjects: t("totalProjects"),
      activeProjects: t("activeProjects"),
      retiredProjects: t("retiredProjects"),
      totalUsers: t("totalUsers"),
      totalSessions: t("totalSessions"),
      totalDMAModules: t("totalDMAModules"),
      totalDDModules: t("totalDDModules"),
      totalHLModules: t("totalHLModules"),
    }),
    [t]
  );

  useEffect(() => {
    if (responseData) {
      const instanceData = responseData.data;
      setStatus(instanceData.status);
      setInstanceName(instanceData.instanceName);
      const transformedData = Object.entries(instanceData)
        .filter(([key]) => key !== "instanceName" && key !== "status")
        .map(([key, value]) => ({
          label: labelMappingApiResponse[key] || key,
          value,
        }));

      setAllStatusData(transformedData);
    }
  }, [responseData, labelMappingApiResponse]);

  const handleNavLeft = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleTemplateModalOpen = useCallback(() => {
    reduxDispatch(getAllModules({ token })).then((response) => {
      const moduleData = response?.payload.data || [];
      const transformedModulesData = moduleData.map((module) => ({
        id: module.id,
        projectName: module.projectName,
        name: module.name,
      }));
      setModulesData(transformedModulesData);
      setIsTemplateModalOpen(true);
    });
  }, [reduxDispatch, token]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setIsTemplateModalOpen(false);
    setIsConfirmationModalOpen(false);
  }, []);

  const handleStatusUpdate = useCallback(
    (selectedStatus, message) => {
      const isActive = selectedStatus === maintenanceLanguage.StatusActiveEN;
      const updatePayload = {
        status: isActive,
        messageWhenInactive: !isActive ? message : "",
      };

      reduxDispatch(getMaintainenceStatus(updatePayload))
        .unwrap()
        .then(() => {
          handleClose();
          reduxDispatch(getAllStatus({ token }));
        })
        .catch((error) => {
          console.error("Error updating status:", error);
        });
    },
    [reduxDispatch, handleClose, token]
  );

  const handleTemplateModalConfirmComplete = useCallback(() => {
    reduxDispatch(getAllStatus({ token })).then(() => {
      setIsConfirmationModalOpen(false);
    });
  }, [reduxDispatch, token]);

  const handleTemplateModalConfirmClick = useCallback(
    (selectedStatus, selectedProjects) => {
      setSelectedStatus(selectedStatus);
      setSelectedRows(
        selectedProjects.reduce((acc, projectId) => {
          acc[projectId] = true;
          return acc;
        }, {})
      );
      setIsConfirmationModalOpen(true);
    },
    []
  );

  const handleConfirmationModalConfirm = useCallback(() => {
    const actionMap = {
      all: copyAllModuleTablesTotemplate,
      sources: copySourcesToTemplate,
      valueChain: copyValueChainToTemplate,
      activitiesProductsAndServices: copyActProServiceToTemplate,
      geographicalLocation: copyGeographicalLocationToTemplate,
      stakeholders: copyStakeHolderToTemplate,
    };

    const action = actionMap[selectedStatus];
    if (action) {
      Promise.all(
        Object.keys(selectedRows).map((moduleId) =>
          reduxDispatch(action({ moduleId, token }))
        )
      ).then(() => {
        handleTemplateModalConfirmComplete();
      });
    } else {
      console.error("Unknown status:", selectedStatus);
    }
  }, [
    selectedRows,
    selectedStatus,
    reduxDispatch,
    token,
    handleTemplateModalConfirmComplete,
  ]);

  if (isError) {
    return (
      <ErrorModal
        setName={t("Error")}
        labelText={errorMessage}
        handleButtonClick={handleError(reduxDispatch, navigate)}
        deleteButtonLabel={t("Reload")}
      />
    );
  }

  return (
    <div>
      <Header />
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {isOpen && (
        <ChangeStatusModal
          onClose={handleClose}
          setInstanceName={instanceName}
          handleStatusUpdate={handleStatusUpdate}
          currentStatus={status}
        />
      )}
      {isTemplateModalOpen && (
        <TemplateModal
          title={t("Templates")}
          onClose={handleClose}
          columns={columns}
          data={modulesData}
          itemsPerPage={5}
          generateRowKey={(row) => row.id}
          showSelect={true}
          selectableOptions={templateOptions}
          confirmButtonText={t("Copy to template")}
          cancelButtonText={t("Cancel")}
          onConfirm={handleTemplateModalConfirmClick}
        />
      )}
      {isConfirmationModalOpen && (
        <DeleteModal
          isOpen={isConfirmationModalOpen}
          onClose={handleClose}
          setName={t("Confirmation")}
          labelText={t("Warning! This action will override the template. Do you want to proceed?")}
          handleDeleteClick={handleConfirmationModalConfirm}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("Confirm")}
        />
      )}
      <div className="maintenance-container">
        <Breadcrumbs
          items={[
            { label: "All projects", link: "/" },
            {
              label: "Settings",
              link: "/maintenance",
            },
          ]}
        />
        <div className="maintenance-header-container">
          <div className="maintenance-section1">
            <MotifButton
              data-testid="navButton"
              className="nav-left-btn"
              onClick={handleNavLeft}
            >
              <NavLeft />
              <span
                data-testid="maintenance-subheader"
                className="motif-h5-default-bold"
              >
                {t("Maintenance")}
              </span>
            </MotifButton>
          </div>
          <div className="maintenance-section2">
            <MotifButton
              className="active-btn"
              size="small"
              type="submit"
              variant="primary-alt"
            >
              <Dot />
              &nbsp; {status}
            </MotifButton>
            <MotifButton
              className="context-selection-button-download"
              size="small"
              type="submit"
              variant="primary-alt"
              onClick={handleTemplateModalOpen}
            >
              {t("Templates")}
              &nbsp;
              <Templates />
            </MotifButton>
            <MotifButton
              className="subheader-button-add"
              size="small"
              type="submit"
              variant="primary-alt"
              onClick={handleModalOpen}
            >
              {t("ChangeStatus")}
              &nbsp;
              <EditSvg />
            </MotifButton>
          </div>
        </div>
        <div className="cards-container">
          {allStatusData.map(({ label, value }) => (
            <Card key={label} label={label} value={value} />
          ))}
        </div>
      </div>
      <AllProjects />
    </div>
  );
};

export default InstanceMaintenance;
