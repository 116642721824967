import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_APS_TYPES_URL,
  GET_ALL_APS_URL,
  CREATE_APS_URL,
  UPDATE_APS_URL,
  DELETE_APS_URL,
} from "../../services/Api";

// Utility function to handle API requests
const apiRequest = async (url, method, data, token) => {
  const config = {
    method,
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  const response = await axios(config);
  return response.data;
};

// Generic thunk creator
const createThunk = (type, apiCall) => {
  return createAsyncThunk(type, async (params, { rejectWithValue }) => {
    try {
      return await apiCall(params);
    } catch (error) {
      console.error(`Error in ${type}:`, error);
      const errorMessage = error.response?.data?.message;

      if (!errorMessage) {
        // No specific error message found
        return rejectWithValue({
          errorMessage:
            error.response?.data?.title ||
            error.message ||
            "Unknown error occurred",
          isGenericError: true,
        });
      } else {
        return rejectWithValue({ errorMessage, isGenericError: false });
      }
    }
  });
};

export const fetchAps = createThunk("dmaAps/fetchAps", ({ moduleId, token }) =>
  apiRequest(`${GET_ALL_APS_URL}/${moduleId}`, "get", null, token)
);

export const createAps = createThunk(
  "dmaAps/createAps",
  ({ moduleId, apsData, token }) =>
    apiRequest(`${CREATE_APS_URL}/${moduleId}`, "post", apsData, token)
);

export const updateAps = createThunk(
  "dmaAps/updateAps",
  ({ apsId, apsData, moduleId, token }) =>
    apiRequest(`${UPDATE_APS_URL}/${apsId}/${moduleId}`, "put", apsData, token)
);

export const deleteAps = createThunk(
  "dmaAps/deleteAps",
  ({ apsId, moduleId, token }) =>
    apiRequest(`${DELETE_APS_URL}/${apsId}/${moduleId}`, "delete", null, token)
);

export const fetchApsTypes = createThunk("dmaAps/fetchApsTypes", ({ token }) =>
  apiRequest(GET_ALL_APS_TYPES_URL, "get", null, token)
);

// Utility function to handle extra reducers
const handleAsyncActions = (builder, action, successKey = "data") => {
  builder
    .addCase(action.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.errorMessage = "";
    })
    .addCase(action.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state[successKey] = payload;
    })
    .addCase(action.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = payload.errorMessage;
      state.isGenericError = payload.isGenericError || false;
    });
};

// Slice definition
const dmaApsSlice = createSlice({
  name: "dmaAps",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    isGenericError: false,
    errorMessage: "",
    types: [],
  },
  reducers: {
    clearError(state) {
      state.isError = false;
      state.errorMessage = "";
      state.isGenericError = false;
    },
  },
  extraReducers: (builder) => {
    handleAsyncActions(builder, fetchAps, "data");
    handleAsyncActions(builder, createAps, "data");
    handleAsyncActions(builder, updateAps, "data");
    handleAsyncActions(builder, deleteAps, "data");
    handleAsyncActions(builder, fetchApsTypes, "types");
  },
});

export const { clearError } = dmaApsSlice.actions;
export default dmaApsSlice.reducer;