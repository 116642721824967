import React from "react";
import "./ContextSelection.scss";
import { MotifButton } from "@ey-xd/motif-react";
import { Add, Download, Library } from "../../constants/icons/Icons";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

const ContextSelection = ({
  onChangeSearchInput,
  addButtonText,
  onClickNewProject,
  onClickLibrary,
  downloadButtonText,
  libraryButtonText,
  onClickNewDownload,
  pageName,
}) => {
  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value || "";
    onChangeSearchInput(inputValue.toLowerCase());
  };

  return (
    <div id="context-selection">
      {pageName === "Scores" && <h2>{pageName}</h2>}
      {pageName === "Likelihood" && <h2>{pageName}</h2>}
      {pageName === "Assessments" && <h2>{pageName}</h2>}
      {!(
        pageName === "Scores" ||
        pageName === "Likelihood" ||
        pageName === "Assessments"
      ) && (
        <Paper
          component="form"
          onSubmit={(e) => e.preventDefault()}
          className="search-box"
          role="form"
        >
          <IconButton type="button" className="search-icon" aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            className="search-box-input"
            placeholder="Search"
            onChange={handleSearchInputChange}
            inputProps={{ "aria-label": "search For" }}
          />
        </Paper>
      )}

      <div className="context-selection-button-container">
        {(pageName === "SM" || pageName === "DgaContext") && (
          <MotifButton
            className="context-selection-button-download"
            onClick={onClickNewDownload}
            size="small"
            type="submit"
            variant="primary-alt"
          >
            {downloadButtonText}
            <span className="icon">
              <Download />
            </span>
          </MotifButton>
        )}
        {pageName === "IRO" && (
          <MotifButton
            className="context-selection-button-add library-button"
            onClick={onClickLibrary}
            size="small"
            type="submit"
            variant="primary-alt"
          >
            {libraryButtonText}
            <span className="icon">
              <Library />
            </span>
          </MotifButton>
        )}
        {pageName !== "DgaContext" && (
          <MotifButton
            className="context-selection-button-add"
            onClick={onClickNewProject}
            size="small"
            type="submit"
            variant="primary-alt"
          >
            {addButtonText}
            <span className="icon">
              <Add />
            </span>
          </MotifButton>
        )}
      </div>
    </div>
  );
};

ContextSelection.propTypes = {
  onChangeSearchInput: PropTypes.func.isRequired,
  addButtonText: PropTypes.string.isRequired,
  onClickNewProject: PropTypes.func.isRequired,
  onClickNewDownload: PropTypes.func,
  onClickLibrary: PropTypes.func,
  downloadButtonText: PropTypes.string,
  libraryButtonText: PropTypes.string,
  pageName: PropTypes.string,
};

export default ContextSelection;