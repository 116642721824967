import React, {
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import TreeView from "../../../components/treeView/TreeView";
import ContextSelection from "../../../components/contextSelection/ContextSelection";
import {
  getAllIro,
  createIro,
  updateIro,
  deleteIro,
  getApsIro,
  getSourcesIro,
  getValueChainIro,
  getStakeholdersIro,
  getGeographiesIro,
  updateSourcesIro,
  updateValueChainIro,
  updateApsIro,
  updateGeographiesIro,
  updateStakeholdersIro,
  getAllSustainabilityMattersIro,
  updateSustainabilityMattersIro,
  getAllTimeframeTypes,
  getAllIroTypes,
  getIROLibraryTypes,
  getLibraryItems,
  createSustainabilityMatterFromLibrary,
  clearError,
} from "../../../features/slices/Iro";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import IroModal from "../../../components/modals/dmaModals/IroModal";
import IroLibraryModal from "../../../components/modals/dmaModals/IroLibraryModal";
import ErrorModal from "../../../components/modals/error/errorModal";
import DeleteModal from "../../../components/modals/deleteModal/DeleteModal";
import PropTypes from "prop-types";
import "./DMAIroOverview.scss";
import { handleError as handleLogoutError } from "../../../utils/handleError";

const initialState = {
  searchInput: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SEARCH_INPUT":
      return { ...state, searchInput: action.payload };
    default:
      return state;
  }
};

const DMAIroOverview = ({ setAssessmentIroId, navigateToAssessment }) => {
  const [modal, setModal] = useState({ isOpen: false, title: "", mode: "add" });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    itemId: null,
  });
  const [libraryModal, setLibraryModal] = useState(false);
  const [libraryTypes, setLibraryTypes] = useState([]);
  const [libraryItems, setLibraryItems] = useState([]);
  const [selectedFilterValue, setSelectedFilterValue] = useState(null); // For default selection
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const dispatch = useDispatch();
  const iroData = useSelector((state) => state.iro.data);
  const { isError, isGenericError, errorMessage, isLoading } = useSelector(
    (state) => state.iro
  );

  const { moduleId: moduleIdString } = useParams();
  const moduleId = parseInt(moduleIdString);
  const [state, localDispatch] = useReducer(reducer, initialState);

  const handleCloseLibraryModal = () => {
    setLibraryModal(false);
    setSelectedFilterValue(null);
    setLibraryItems([]);
  };

  const [pillarOptions, setPillarOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [timeframeOptions, setTimeframeOptions] = useState([]);
  const [iroTypesOptions, setIroTypesOptions] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [updateItemID, setUpdateItemID] = useState(null);
  const [filteredUpdateData, setFilteredUpdateData] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);
  const [dotColorState, setDotColorState] = useState({}); // State to manage dot colors

  // Whenever isError or errorMessage changes, reset showErrorModal
  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const [tabData, setTabData] = useState({
    source: [],
    aps: [],
    valuechain: [],
    geographies: [],
    stakeholders: [],
    sustainablilitymatters: [],
    updatedsustainablilitymatters: [],
  });
  const [dataFetched, setDataFetched] = useState({
    source: false,
    aps: false,
    valuechain: false,
    geographies: false,
    stakeholders: false,
    sustainablilitymatters: false,
    updatedsustainablilitymatters: false,
  });

  const fetchData = useCallback(
    async (action, setState) => {
      try {
        const response = await dispatch(action({ token })).unwrap();
        if (response.success) {
          setState(response.data);
        }
      } catch (error) {
        console.error(`Error fetching data:`, error);
      }
    },
    [dispatch, token]
  );

  useEffect(() => {
    if (token) {
      dispatch(getAllIro({ moduleId, token }));
      fetchData(getAllTimeframeTypes, setTimeframeOptions);
      fetchData(getAllIroTypes, setIroTypesOptions);
    }
  }, [token, dispatch, moduleId, updateTrigger, fetchData]);

  const handleSearchInputChange = useCallback((event) => {
    localDispatch({ type: "SET_SEARCH_INPUT", payload: event });
  }, []);

  // Function to get dotColor based on isMaterial
  const getDotColor = (isMaterial) => {
    if (isMaterial === true) return "green";
    if (isMaterial === false) return "red";
    return "grey";
  };

  const filterItems = useCallback((items, query) => {
    if (!query) return items;
    const queryLowerCase = query.toLowerCase();
    return items
      .map((item) => {
        const areaMatches = item.children
          .filter((area) => {
            const nameMatches = area.children.filter((name) =>
              name.label.toLowerCase().includes(queryLowerCase)
            );
            return area.label.toLowerCase().includes(queryLowerCase) ||
              nameMatches.length > 0
              ? { ...area, children: nameMatches }
              : null;
          })
          .filter(Boolean);
        return item.label.toLowerCase().includes(queryLowerCase) ||
          areaMatches.length > 0
          ? { ...item, children: areaMatches }
          : null;
      })
      .filter(Boolean);
  }, []);

  const memoizedIroData = useMemo(() => iroData?.data || [], [iroData]);

  useEffect(() => {
    if (memoizedIroData.length) {
      setPillarOptions(getUniqueOptions(memoizedIroData, "pillar"));
      setAreaOptions(getUniqueOptions(memoizedIroData, "area"));
    }
  }, [memoizedIroData]);

  const getUniqueOptions = (data, key) => {
    return [...new Set(data.map((item) => item[key]))].map((value) => ({
      value,
      label: value,
    }));
  };

  const transformData = (data) => {
    if (!Array.isArray(data)) {
      console.error("Unexpected data format:", data);
      return [];
    }

    const pillarMap = {};
    data.forEach((item) => {
      if (!pillarMap[item.pillar]) {
        pillarMap[item.pillar] = {
          id: `pillar-${item.id}`,
          itemId: `pillar-${item.pillar}`,
          label: item.pillar,
          itemType: "pillar",
          children: [],
        };
      }
      let area = pillarMap[item.pillar].children.find(
        (child) => child.label === item.area
      );
      if (!area) {
        area = {
          id: `area-${item.id}`,
          itemId: `area-${item.pillar}-${item.area}`,
          label: item.area,
          itemType: "area",
          children: [],
        };
        pillarMap[item.pillar].children.push(area);
      }
      area.children.push({
        id: `name-${item.id}`,
        itemId: item.id,
        label: item.name,
        itemType: "name",
      });
    });
    return Object.values(pillarMap);
  };

  const transformedData = useMemo(
    () => transformData(memoizedIroData),
    [memoizedIroData]
  );

  const filteredData = useMemo(
    () => filterItems(transformedData, state.searchInput),
    [transformedData, state.searchInput, filterItems]
  );

  const findItemById = (data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const handleEditClick = (id) => {
    try {
      const numericId =
        typeof id === "string" ? parseInt(id.replace(/^\D+/g, "")) : id;
      handleOpenModal("edit", numericId);
      const item = findItemById(memoizedIroData, numericId);
      setFilteredUpdateData(item);
      setUpdateItemID(numericId);
    } catch (error) {
      console.error("Error in handleEditClick:", error);
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteModal({ isOpen: true, itemId: updateItemID });
  };

  const handleViewClick = (id) => {
    const assessmentIroId =
      typeof id === "string" ? parseInt(id.replace(/^\D+/g, "")) : id;
    setAssessmentIroId(assessmentIroId);
    navigateToAssessment();
  };

  const handleConfirmDelete = async () => {
    try {
      const { itemId } = deleteModal;
      await dispatch(deleteIro({ iroId: itemId, token, moduleId })).unwrap();
      setUpdateTrigger((prev) => prev + 1);
      setDeleteModal({ isOpen: false, itemId: null });
      handleCloseModal();
    } catch (error) {
      console.error("Deletion failed:", error);
    }
  };

  const handleOpenModal = (mode, itemId) => {
    setModal({
      isOpen: true,
      mode,
      title: mode === "add" ? t("NewIRO") : t("EditIRO"),
    });
  };

  const handleCloseModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
    resetDataFetchedAndTabData();
  };

  const resetDataFetchedAndTabData = () => {
    setDataFetched({
      source: false,
      aps: false,
      valuechain: false,
      geographies: false,
      stakeholders: false,
      sustainablilitymatters: false,
      updatedsustainablilitymatters: false,
    });

    setTabData({
      source: [],
      aps: [],
      valuechain: [],
      geographies: [],
      stakeholders: [],
      sustainablilitymatters: [],
      updatedsustainablilitymatters: [],
    });
  };

  const createNewIroApi = async (data) => {
    if (data) {
      data.moduleId = moduleId;
      try {
        const response = await dispatch(
          createIro({ iroData: data, token })
        ).unwrap();
        const newId = response.data.id;
        setUpdateTrigger((prev) => prev + 1);
        handleCloseModal();
        handleEditClick(newId);
      } catch (error) {
        console.error("Creation failed:", error);
        setUpdateTrigger((prev) => prev + 1);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const updateIroApi = async (data) => {
    if (data) {
      data.moduleId = moduleId;
      data.moduleIROId = updateItemID;
      try {
        const response = await dispatch(
          updateIro({ iroId: updateItemID, iroData: data, token })
        ).unwrap();
        if (response.success) {
          setUpdateTrigger((prev) => prev + 1);
          handleCloseModal();
        }
      } catch (error) {
        console.error("Updation failed:", error);
        setUpdateTrigger((prev) => prev + 1);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const fetchTabData = (tab) => {
    if (!dataFetched[tab]) {
      const tabActions = {
        source: getSourcesIro,
        aps: getApsIro,
        valuechain: getValueChainIro,
        geographies: getGeographiesIro,
        stakeholders: getStakeholdersIro,
        sustainablilitymatters: getAllSustainabilityMattersIro,
      };

      const action = tabActions[tab];
      if (action) {
        dispatch(action({ iroId: updateItemID, token, moduleId }))
          .unwrap()
          .then((data) => {
            setTabData((prev) => ({ ...prev, [tab]: data.data }));
            setDataFetched((prev) => ({ ...prev, [tab]: true }));
          })
          .catch((error) => console.error(error));
      }
    }
  };

  const updateTabData = async () => {
    const apiCalls = {
      source: updateSourcesIro,
      aps: updateApsIro,
      valuechain: updateValueChainIro,
      geographies: updateGeographiesIro,
      stakeholders: updateStakeholdersIro,
      sustainablilitymatters: updateSustainabilityMattersIro,
    };

    const tabDataMapping = {
      source: tabData.source,
      aps: tabData.aps,
      valuechain: tabData.valuechain,
      geographies: tabData.geographies,
      stakeholders: tabData.stakeholders,
      sustainablilitymatters: tabData.updatedsustainablilitymatters,
    };

    try {
      setLocalLoader(true);
      // Loop through each tab and trigger the corresponding API call
      const updatePromises = Object.keys(apiCalls).map(async (tab) => {
        const apiAction = apiCalls[tab];
        const tabDataItems = tabDataMapping[tab];

        if (tabDataItems && tabDataItems.length > 0) {
          const response = await dispatch(
            apiAction({
              iroId: updateItemID,
              iroData: tabDataItems.map((item) => ({
                ...item,
              })),
              token,
              moduleId,
            })
          ).unwrap();

          if (!response.success) {
            throw new Error(`Update failed for ${tab}`);
          }
        }
      });

      // Wait for all API calls to complete
      await Promise.all(updatePromises);
      setUpdateTrigger((prev) => prev + 1);
      handleCloseModal();
    } catch (error) {
      console.error("Update failed:", error);
    } finally {
      setLocalLoader(false);
    }
  };

  useEffect(() => {
    if (memoizedIroData.length) {
      const newState = {};
      memoizedIroData.forEach((item) => {
        const maxScore =
          item.maxScore != null ? item.maxScore.toFixed(1) : "N/A";
        newState[`name-${item.id}`] = {
          dotColor: getDotColor(item.isMaterial),
          maxScore,
        };
      });
      setDotColorState(newState);
    }
  }, [memoizedIroData]);

  const handleOpenLibraryModal = async () => {
    try {
      const response = await dispatch(getIROLibraryTypes({ token })).unwrap();
      if (response.success && response.data.length > 0) {
        setLibraryTypes(response.data);
        setSelectedFilterValue(response.data[0].id.toString()); // Auto-select the first value
      }
    } catch (error) {
      console.error("Error fetching library types:", error);
    }
  };

  useEffect(() => {
    const fetchLibraryItems = async () => {
      try {
        const response = await dispatch(
          getLibraryItems({
            token,
            iroLibraryTypeId: selectedFilterValue,
          })
        ).unwrap();
        if (response.success) {
          setLibraryItems(response.data);
        }
        setLibraryModal(true); // Open the modal after data is fetched
      } catch (error) {
        console.error("Error fetching library items:", error);
      }
    };

    if (selectedFilterValue !== null) {
      fetchLibraryItems();
    }
  }, [selectedFilterValue, token, dispatch]);

  // Handler for filter value change
  const handleFilterValueChange = (value) => {
    setSelectedFilterValue(value);
  };

  const generateRowKey = (row) => row.id;

  const handleLibrarySubmit = async (selectedIds) => {
    try {
      const data = selectedIds;

      const response = await dispatch(
        createSustainabilityMatterFromLibrary({
          token,
          moduleId,
          data,
        })
      ).unwrap();

      if (response.success) {
        await dispatch(getAllIro({ moduleId, token }));
        handleCloseLibraryModal();
      } else {
        console.error(
          "Failed to create sustainability matters from library:",
          response.message
        );
      }
    } catch (error) {
      console.error(
        "Error creating sustainability matters from library:",
        error
      );
    }
  };

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  const allExpandedIds = useMemo(() => {
    const getAllIds = (items) => {
      const ids = [];
      const traverse = (nodes) => {
        nodes.forEach((node) => {
          ids.push(node.id);
          if (node.children) {
            traverse(node.children);
          }
        });
      };
      traverse(items);
      return ids;
    };
    return getAllIds(filteredData);
  }, [filteredData]);

  if (showErrorModal && localErrorMessage) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? () => handleLogoutError(dispatch, navigate)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }

  return (
    <>
      {(isLoading || localLoader) && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <div className="Iro-file-explorer">
        <div>
          <ContextSelection
            addButtonText="New IRO"
            pageName="IRO"
            libraryButtonText={t("libraryLabel")}
            onClickNewProject={() => handleOpenModal("add")}
            onClickLibrary={() => handleOpenLibraryModal()}
            onChangeSearchInput={handleSearchInputChange}
          />
        </div>
        <div className="Iro-table">
          <TreeView
            key={allExpandedIds.join("-")}
            items={filteredData}
            defaultExpandedItems={allExpandedIds}
            defaultSelectedItems=""
            onEditClick={handleEditClick}
            onViewClick={handleViewClick}
            onDeleteClick={handleDeleteClick}
            handleCheckboxChange={() => {}}
            dotColorState={dotColorState}
            pageName="IRO"
          />
        </div>
        {modal.isOpen && (
          <IroModal
            onClose={handleCloseModal}
            modalTitle={modal.title}
            modalMode={modal.mode}
            nameLabel={t("Name")}
            typeLabel={t("IROType")}
            areaLabel={t("Area")}
            pillarLabel={t("Pillar")}
            selectAreaLabel={t("SelectArea")}
            SelectPillar={t("SelectPillar")}
            BasicLabel={t("Definition")}
            SelectType={t("SelectType")}
            InputName={t("EnterName")}
            SelectTimeframe={t("SelectTimeframe")}
            DescriptionLabel={t("Description")}
            TabDescriptionLabel={t("TabDescription")}
            SourceLabel={t("SourceLabel")}
            ValueChainLabel={t("ValueChain")}
            APSLabel={t("APSLabel")}
            SustainablilityMattersLabel={t("Sustainablilitymatters")}
            StakeholdersLabel={t("Stakeholders")}
            GeographiesLabel={t("Geographies")}
            saveButtonLabel={t("addIRO")}
            updateButtonLabel={t("updateIRO")}
            cancelButtonLabel={t("Cancel")}
            filterByLabel={t("FilterBy")}
            pillarOptions={pillarOptions}
            areaOptions={areaOptions}
            handleDoneClick={
              modal.mode === "add" ? createNewIroApi : updateIroApi
            }
            filteredUpdateData={filteredUpdateData}
            fetchTabData={fetchTabData}
            tabData={tabData}
            disable={isLoading}
            setTabData={setTabData}
            updateTabData={updateTabData}
            filterItems={filterItems}
            TimeframeLabel={t("TimeframeLabel")}
            timeframeOptions={timeframeOptions}
            iroTypesOptions={iroTypesOptions}
            onDeleteClick={handleDeleteClick}
            DeleteLabel={t("DeleteLabel")}
          />
        )}
        {deleteModal.isOpen && (
          <DeleteModal
            isOpen={deleteModal.isOpen}
            onClose={() => setDeleteModal({ isOpen: false, itemId: null })}
            setName={t("DeleteLabel")}
            labelText={t("DeletConfirmationSources")}
            onCancel={() => setDeleteModal({ isOpen: false, itemId: null })}
            handleDeleteClick={handleConfirmDelete}
            cancelButtonLabel={t("Cancel")}
            deleteButtonLabel={t("DeleteLabel")}
          />
        )}
        {libraryModal && (
          <IroLibraryModal
            isOpen={libraryModal}
            onClose={handleCloseLibraryModal}
            modalTitle={t("libraryLabel")}
            filterByLabel={t("sectorLabel")}
            saveButtonLabel={t("addIROS")}
            cancelButtonLabel={t("Cancel")}
            disable={isLoading}
            libraryTypes={libraryTypes}
            selectedFilterValue={selectedFilterValue}
            setSelectedFilterValue={handleFilterValueChange}
            tableData={libraryItems}
            generateRowKey={generateRowKey}
            handleSubmit={handleLibrarySubmit}
          />
        )}
      </div>
    </>
  );
};

DMAIroOverview.propTypes = {
  setAssessmentIroId: PropTypes.func,
  navigateToAssessment: PropTypes.func,
};

export default DMAIroOverview;
