import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_IRO_MODULE_BY_ID,
  GET_ALL_MODULE_RATING,
  GET_SOURCES_REFERENCE_LIST,
  GET_VALUE_CHAIN_REFERENCE_LIST,
  GET_APS_REFERENCE_LIST,
  GET_STAKEHOLDER_REFERENCE_LIST,
  SAVE_RACIMAPPING_URL,
  CREATE_IRO_ASSESSMENT,
  GET_IRO_ASSESSMENT,
  UPDATE_IRO_ASSESSMENT,
  DELETE_IRO_ASSESSMENT,
  GET_GEOGRAPHICAL_REFERENCE_LIST,
  GET_SUSTAINABILITY_MATTERS_IRO,
} from "../../services/Api";

// Reusable function for making API requests
const apiRequest = async (url, method, data, token, rejectWithValue) => {
  try {
    const response = await axios({
      url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error ${method} request:`, error);
    const errorMessage = error.response?.data?.message;

    if (!errorMessage) {
      // No specific error message found
      return rejectWithValue({
        errorMessage:
          error.response?.data?.title ||
          error.message ||
          "Unknown error occurred",
        isGenericError: true,
      });
    } else {
      return rejectWithValue({ errorMessage, isGenericError: false });
    }
  }
};

export const fetchIroModuleById = createAsyncThunk(
  "fetchIroModuleById",
  async ({ moduleIROId, moduleId, token }, { rejectWithValue }) => {
    const url = `${GET_IRO_MODULE_BY_ID}${moduleIROId}?moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getAllModuleRating = createAsyncThunk(
  "getAllModuleRating",
  async ({ moduleId, token }, { rejectWithValue }) => {
    const url = `${GET_ALL_MODULE_RATING}/${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getSourceRefList = createAsyncThunk(
  "getSourceRefList",
  async ({ moduleIROId, moduleId, token }, { rejectWithValue }) => {
    const url = `${GET_SOURCES_REFERENCE_LIST}?moduleIROId=${moduleIROId}&moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getValueChainRefList = createAsyncThunk(
  "getValueChainRefList",
  async ({ moduleIROId, moduleId, token }, { rejectWithValue }) => {
    const url = `${GET_VALUE_CHAIN_REFERENCE_LIST}?moduleIROId=${moduleIROId}&moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getAPSRefList = createAsyncThunk(
  "getAPSRefList",
  async ({ moduleIROId, moduleId, token }, { rejectWithValue }) => {
    const url = `${GET_APS_REFERENCE_LIST}?moduleIROId=${moduleIROId}&moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getStakeholderRefList = createAsyncThunk(
  "getStakeholderRefList",
  async ({ moduleIROId, moduleId, token }, { rejectWithValue }) => {
    const url = `${GET_STAKEHOLDER_REFERENCE_LIST}?moduleIROId=${moduleIROId}&moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const getGeographicalRefList = createAsyncThunk(
  "getGeographicalRefList",
  async ({ moduleIROId, moduleId, token }, { rejectWithValue }) => {
    const url = `${GET_GEOGRAPHICAL_REFERENCE_LIST}?moduleIROId=${moduleIROId}&moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const saveRACIMapping = createAsyncThunk(
  "saveRACIMapping",
  async ({ moduleIROId, moduleId, raciData, token }, { rejectWithValue }) => {
    const url = `${SAVE_RACIMAPPING_URL}/${moduleIROId}?moduleId=${moduleId}`;
    return apiRequest(url, "put", raciData, token, rejectWithValue);
  }
);

export const createIROAssessment = createAsyncThunk(
  "createIROAssessment",
  async ({ iroAssessmentData, token }, { rejectWithValue }) => {
    const url = CREATE_IRO_ASSESSMENT;
    return apiRequest(url, "post", iroAssessmentData, token, rejectWithValue);
  }
);

export const getIROAssessment = createAsyncThunk(
  "getIROAssessment",
  async ({ moduleIROId, moduleId, token }, { rejectWithValue }) => {
    const url = `${GET_IRO_ASSESSMENT}/${moduleIROId}?moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const updateIROAssessment = createAsyncThunk(
  "updateIROAssessment",
  async ({ iroAssessmentData, token }, { rejectWithValue }) => {
    const url = UPDATE_IRO_ASSESSMENT;
    return apiRequest(url, "post", iroAssessmentData, token, rejectWithValue);
  }
);

export const deleteIROAssessment = createAsyncThunk(
  "deleteIROAssessment",
  async (
    { assessmentId, moduleIROId, moduleId, token },
    { rejectWithValue }
  ) => {
    const url = `${DELETE_IRO_ASSESSMENT}/${assessmentId}/${moduleIROId}?moduleId=${moduleId}`;
    return apiRequest(url, "delete", null, token, rejectWithValue);
  }
);

export const getSustainabilityMattersIro = createAsyncThunk(
  "getSustainabilityMattersIro",
  async ({ moduleId, iroId, token }, { rejectWithValue }) => {
    const url = `${GET_SUSTAINABILITY_MATTERS_IRO}?moduleIROId=${iroId}&moduleId=${moduleId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

// Slice definition
const dmaIroAssessment = createSlice({
  name: "dmaIroAssessment",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
    types: [],
    postSuccess: false,
    isGenericError: false,
  },
  reducers: {
    clearError(state) {
      state.isError = false;
      state.errorMessage = "";
      state.isGenericError = false;
    },
  },
  extraReducers: (builder) => {
    const pendingReducer = (state) => {
      state.isLoading = true;
      state.postSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    };

    const fulfilledReducer = (state, action) => {
      state.isLoading = false;
      state.postSuccess = true;
      state.data = action.payload;
      state.isError = false;
    };

    const rejectedReducer = (state, action) => {
      state.isLoading = false;
      state.postSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload.errorMessage;
      state.isGenericError = action.payload.isGenericError || false;
    };

    builder
      .addCase(fetchIroModuleById.pending, pendingReducer)
      .addCase(fetchIroModuleById.fulfilled, fulfilledReducer)
      .addCase(fetchIroModuleById.rejected, rejectedReducer)
      .addCase(getAllModuleRating.pending, pendingReducer)
      .addCase(getAllModuleRating.fulfilled, fulfilledReducer)
      .addCase(getAllModuleRating.rejected, rejectedReducer)
      .addCase(getSourceRefList.pending, pendingReducer)
      .addCase(getSourceRefList.fulfilled, fulfilledReducer)
      .addCase(getSourceRefList.rejected, rejectedReducer)
      .addCase(getValueChainRefList.pending, pendingReducer)
      .addCase(getValueChainRefList.fulfilled, fulfilledReducer)
      .addCase(getValueChainRefList.rejected, rejectedReducer)
      .addCase(getAPSRefList.pending, pendingReducer)
      .addCase(getAPSRefList.fulfilled, fulfilledReducer)
      .addCase(getAPSRefList.rejected, rejectedReducer)
      .addCase(getStakeholderRefList.pending, pendingReducer)
      .addCase(getStakeholderRefList.fulfilled, fulfilledReducer)
      .addCase(getStakeholderRefList.rejected, rejectedReducer)
      .addCase(getGeographicalRefList.pending, pendingReducer)
      .addCase(getGeographicalRefList.fulfilled, fulfilledReducer)
      .addCase(getGeographicalRefList.rejected, rejectedReducer)
      .addCase(saveRACIMapping.pending, pendingReducer)
      .addCase(saveRACIMapping.fulfilled, fulfilledReducer)
      .addCase(saveRACIMapping.rejected, rejectedReducer)
      .addCase(createIROAssessment.pending, pendingReducer)
      .addCase(createIROAssessment.fulfilled, fulfilledReducer)
      .addCase(createIROAssessment.rejected, rejectedReducer)
      .addCase(getIROAssessment.pending, pendingReducer)
      .addCase(getIROAssessment.fulfilled, fulfilledReducer)
      .addCase(getIROAssessment.rejected, rejectedReducer)
      .addCase(getSustainabilityMattersIro.pending, pendingReducer)
      .addCase(getSustainabilityMattersIro.fulfilled, fulfilledReducer)
      .addCase(getSustainabilityMattersIro.rejected, rejectedReducer)
      .addCase(updateIROAssessment.pending, pendingReducer)
      .addCase(updateIROAssessment.fulfilled, fulfilledReducer)
      .addCase(updateIROAssessment.rejected, rejectedReducer)
      .addCase(deleteIROAssessment.pending, pendingReducer)
      .addCase(deleteIROAssessment.fulfilled, fulfilledReducer)
      .addCase(deleteIROAssessment.rejected, rejectedReducer);
  },
});

export const { clearError } = dmaIroAssessment.actions;
export default dmaIroAssessment.reducer;