import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_PROJECT_DETAILS_URL,
  UPDATE_PROJECT_DETAILS_URL,
  GET_USERS_URL,
  PROJECT_BASE_URL,
  CREATE_NEW_USER_URL,
  CREATE_SESSION_URL,
  CREATE_MODULE_URL,
  UPDATE_USER_URL,
  DELETE_USER_URL,
  RETIRE_PROJECT_URL,
  CREATE_PROJECT_URL,
  UPDATE_SESSION_URL,
  RETIRE_SESSION_URL,
  GET_PROJECT_USER_ROLES,
  GET_MODULE_TYPES_URL,
  GET_MODULE_TYPES_FOR_SESSION_URL,
  FETCH_COUNTRIES_URL,
  GET_MODULE_BY_ID,
  UPDATE_MODULE_URL,
  GET_CLIENTS_URL,
  GET_CLIENTS_TYPES_URL,
  CREATE_CLIENTS_URL,
  UPDATE_CLIENTS_URL,
  DELETE_CLIENTS_URL,
  FETCH_SECTORS_URL,
  GET_ALL_FRAMEWORK,
  RETIRE_MODULE_URL,
} from "../../services/Api";

// Reusable function for making API requests
const apiRequest = async (url, method, data, token, rejectWithValue) => {
  try {
    const response = await axios({
      url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error ${method} request:`, error);
    const errorMessage = error.response?.data?.message;

    if (!errorMessage) {
      // No specific error message found
      return rejectWithValue({
        errorMessage:
          error.response?.data?.title ||
          error.message ||
          "Unknown error occurred",
        isGenericError: true,
      });
    } else {
      return rejectWithValue({ errorMessage, isGenericError: false });
    }
  }
};

// Common handlers
const handlePending = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.errorMessage = "";
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.isError = false;
  state.isGenericError = false;
  state.data = action.payload;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  if (typeof action.payload === "object" && action.payload !== null) {
    state.errorMessage = action.payload.errorMessage || "An error occurred";
    state.isGenericError = action.payload.isGenericError || false;
  } else {
    state.errorMessage = action.payload || "An error occurred";
    state.isGenericError = false;
  }
};

// Async thunks using apiRequest
export const fetchProjectDetails = createAsyncThunk(
  "fetchProjectDetails",
  async ({ projectId, token }, { rejectWithValue }) => {
    const url = `${GET_PROJECT_DETAILS_URL}?projectId=${projectId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const updateProjectDetails = createAsyncThunk(
  "updateProjectDetails",
  async ({ projectData, token }, { rejectWithValue }) => {
    return apiRequest(
      UPDATE_PROJECT_DETAILS_URL,
      "put",
      projectData,
      token,
      rejectWithValue
    );
  }
);

export const createProject = createAsyncThunk(
  "createProject",
  async ({ projectData, token }, { rejectWithValue }) => {
    return apiRequest(
      CREATE_PROJECT_URL,
      "post",
      projectData,
      token,
      rejectWithValue
    );
  }
);

export const createSessionInProject = createAsyncThunk(
  "createSessionInProject",
  async ({ sessionData, token }, { rejectWithValue }) => {
    return apiRequest(
      CREATE_SESSION_URL,
      "post",
      sessionData,
      token,
      rejectWithValue
    );
  }
);

export const createModuleInProject = createAsyncThunk(
  "createModuleInProject",
  async ({ moduleData, token }, { rejectWithValue }) => {
    return apiRequest(
      CREATE_MODULE_URL,
      "post",
      moduleData,
      token,
      rejectWithValue
    );
  }
);

export const fetchUsers = createAsyncThunk(
  "fetchUsers",
  async ({ projectId, token }, { rejectWithValue }) => {
    const url = `${PROJECT_BASE_URL}${GET_USERS_URL}?projectId=${projectId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const createNewUser = createAsyncThunk(
  "createNewUser",
  async ({ projectId, userData, token }, { rejectWithValue }) => {
    const url = `${PROJECT_BASE_URL}${CREATE_NEW_USER_URL}?projectId=${projectId}`;
    return apiRequest(url, "post", userData, token, rejectWithValue);
  }
);

export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ userData, token }, { rejectWithValue }) => {
    const url = `${UPDATE_USER_URL}?id=${userData.id}&roleId=${userData.projectRoleId}&projectId=${userData.projectId}&isApprover=${userData.isApprover}`;
    return apiRequest(url, "put", userData, token, rejectWithValue);
  }
);

export const deleteUser = createAsyncThunk(
  "deleteUser",
  async ({ projectId, userId, token }, { rejectWithValue }) => {
    const url = `${PROJECT_BASE_URL}${DELETE_USER_URL}?userId=${userId}&projectId=${projectId}`;
    return apiRequest(url, "delete", null, token, rejectWithValue);
  }
);

export const retireProject = createAsyncThunk(
  "retireProject",
  async ({ projectId, token }, { rejectWithValue }) => {
    const url = `${RETIRE_PROJECT_URL}?projectId=${projectId}`;
    return apiRequest(url, "put", {}, token, rejectWithValue);
  }
);

export const updateSessionDetails = createAsyncThunk(
  "updateSessionDetails",
  async ({ name, id, token, projectId }, { rejectWithValue }) => {
    const url = `${UPDATE_SESSION_URL}?sessionId=${id}&sessionName=${name}&projectId=${projectId}`;
    return apiRequest(url, "put", {}, token, rejectWithValue);
  }
);

export const retireSession = createAsyncThunk(
  "retireSession",
  async ({ id, token, projectId }, { rejectWithValue }) => {
    const url = `${RETIRE_SESSION_URL}?sessionId=${id}&projectId=${projectId}`;
    return apiRequest(url, "put", {}, token, rejectWithValue);
  }
);

export const retireModule = createAsyncThunk(
  "retireModule",
  async ({ id, token, projectId }, { rejectWithValue }) => {
    const url = `${RETIRE_MODULE_URL}?moduleId=${id}&projectId=${projectId}`;
    return apiRequest(url, "put", {}, token, rejectWithValue);
  }
);

export const fetchProjectUserRoles = createAsyncThunk(
  "fetchProjectUserRoles",
  async ({ token }, { rejectWithValue }) => {
    return apiRequest(
      GET_PROJECT_USER_ROLES,
      "get",
      null,
      token,
      rejectWithValue
    );
  }
);

export const fetchAllModuleTypes = createAsyncThunk(
  "fetchAllModuleTypes",
  async ({ token }, { rejectWithValue }) => {
    return apiRequest(
      GET_MODULE_TYPES_URL,
      "get",
      null,
      token,
      rejectWithValue
    );
  }
);

export const fetchAllModuleTypesForSession = createAsyncThunk(
  "fetchAllModuleTypesForSession",
  async ({ frameworkTypeId, token }, { rejectWithValue }) => {
    const url = `${GET_MODULE_TYPES_FOR_SESSION_URL}?FrameworkTypeId=${frameworkTypeId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const fetchModuleById = createAsyncThunk(
  "fetchModuleById",
  async ({ id, token }, { rejectWithValue }) => {
    const url = `${GET_MODULE_BY_ID}?moduleId=${id}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const fetchCountries = createAsyncThunk(
  "fetchCountries",
  async ({ token }, { rejectWithValue }) => {
    return apiRequest(FETCH_COUNTRIES_URL, "get", null, token, rejectWithValue);
  }
);

export const fetchSectors = createAsyncThunk(
  "fetchSectors",
  async ({ token }, { rejectWithValue }) => {
    return apiRequest(FETCH_SECTORS_URL, "get", null, token, rejectWithValue);
  }
);

export const updateModule = createAsyncThunk(
  "updateModule",
  async ({ moduleDataEdited, token }, { rejectWithValue }) => {
    return apiRequest(
      UPDATE_MODULE_URL,
      "post",
      moduleDataEdited,
      token,
      rejectWithValue
    );
  }
);

export const fetchClientsDetails = createAsyncThunk(
  "fetchClientDetails",
  async ({ projectId, token }, { rejectWithValue }) => {
    const url = `${GET_CLIENTS_URL}?projectId=${projectId}`;
    return apiRequest(url, "get", null, token, rejectWithValue);
  }
);

export const fetchClientTypes = createAsyncThunk(
  "fetchClientTypes",
  async ({ token }, { rejectWithValue }) => {
    return apiRequest(
      GET_CLIENTS_TYPES_URL,
      "get",
      null,
      token,
      rejectWithValue
    );
  }
);

export const createClient = createAsyncThunk(
  "createClient",
  async ({ clientData, token }, { rejectWithValue }) => {
    return apiRequest(
      CREATE_CLIENTS_URL,
      "post",
      clientData,
      token,
      rejectWithValue
    );
  }
);

export const updateClient = createAsyncThunk(
  "updateClient",
  async ({ clientId, clientData, projectId, token }, { rejectWithValue }) => {
    const url = `${UPDATE_CLIENTS_URL}/${clientId}/${projectId}`;
    return apiRequest(url, "put", clientData, token, rejectWithValue);
  }
);

export const deleteClient = createAsyncThunk(
  "deleteClient",
  async ({ clientId, projectId, token }, { rejectWithValue }) => {
    const url = `${DELETE_CLIENTS_URL}/${clientId}/${projectId}`;
    return apiRequest(url, "delete", null, token, rejectWithValue);
  }
);

export const getAllSessionFrameWork = createAsyncThunk(
  "getAllSessionFrameWork",
  async ({ token }, { rejectWithValue }) => {
    return apiRequest(GET_ALL_FRAMEWORK, "get", null, token, rejectWithValue);
  }
);

const projectDetailsSlice = createSlice({
  name: "projectDetails",
  initialState: {
    isLoading: false,
    isLoadingSectors: false,
    data: null,
    projectUserRoles: null,
    moduleTypes: null,
    sectorsList: null,
    countriesList: null,
    fetchModuleData: null,
    sessionFramework: null,
    updatedData: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
    isGenericError: false,
  },
  reducers: {
    clearError(state) {
      state.isError = false;
      state.errorMessage = "";
      state.isGenericError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchProjectDetails
      .addCase(fetchProjectDetails.pending, handlePending)
      .addCase(fetchProjectDetails.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action.payload;
      })
      .addCase(fetchProjectDetails.rejected, handleRejected)

      // updateProjectDetails
      .addCase(updateProjectDetails.pending, (state) => {
        handlePending(state);
        state.updateSuccess = false;
      })
      .addCase(updateProjectDetails.fulfilled, (state, action) => {
        state.updateSuccess = true;
        state.updatedData = action.payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(updateProjectDetails.rejected, (state, action) => {
        handleRejected(state, action);
        state.updateSuccess = false;
      })

      // createProject
      .addCase(createProject.pending, (state) => {
        handlePending(state);
        state.updateSuccess = false;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.updateSuccess = true;
      })
      .addCase(createProject.rejected, (state, action) => {
        handleRejected(state, action);
        state.updateSuccess = false;
      })

      // createSessionInProject
      .addCase(createSessionInProject.pending, (state) => {
        handlePending(state);
        state.updateSuccess = false;
      })
      .addCase(createSessionInProject.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.updateSuccess = true;
      })
      .addCase(createSessionInProject.rejected, (state, action) => {
        handleRejected(state, action);
        state.updateSuccess = false;
      })

      // createModuleInProject
      .addCase(createModuleInProject.pending, (state) => {
        handlePending(state);
        state.updateSuccess = false;
      })
      .addCase(createModuleInProject.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.updateSuccess = true;
      })
      .addCase(createModuleInProject.rejected, (state, action) => {
        handleRejected(state, action);
        state.updateSuccess = false;
      })

      // fetchUsers
      .addCase(fetchUsers.pending, handlePending)
      .addCase(fetchUsers.fulfilled, handleFulfilled)
      .addCase(fetchUsers.rejected, handleRejected)

      // createNewUser
      .addCase(createNewUser.pending, (state) => {
        handlePending(state);
        state.updateSuccess = false;
      })
      .addCase(createNewUser.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.updateSuccess = true;
      })
      .addCase(createNewUser.rejected, (state, action) => {
        handleRejected(state, action);
        state.updateSuccess = false;
      })

      // updateUser
      .addCase(updateUser.pending, (state) => {
        handlePending(state);
        state.updateSuccess = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.updateSuccess = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        handleRejected(state, action);
        state.updateSuccess = false;
      })

      // deleteUser
      .addCase(deleteUser.pending, (state) => {
        handlePending(state);
        state.updateSuccess = false;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.updateSuccess = true;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        handleRejected(state, action);
        state.updateSuccess = false;
      })

      // retireProject
      .addCase(retireProject.pending, (state) => {
        handlePending(state);
        state.updateSuccess = false;
      })
      .addCase(retireProject.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.updateSuccess = true;
      })
      .addCase(retireProject.rejected, (state, action) => {
        handleRejected(state, action);
        state.updateSuccess = false;
      })

      // updateSessionDetails
      .addCase(updateSessionDetails.pending, (state) => {
        handlePending(state);
        state.updateSuccess = false;
      })
      .addCase(updateSessionDetails.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.updateSuccess = true;
      })
      .addCase(updateSessionDetails.rejected, (state, action) => {
        handleRejected(state, action);
        state.updateSuccess = false;
      })

      // retireSession
      .addCase(retireSession.pending, (state) => {
        handlePending(state);
        state.updateSuccess = false;
      })
      .addCase(retireSession.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.updateSuccess = true;
      })
      .addCase(retireSession.rejected, (state, action) => {
        handleRejected(state, action);
        state.updateSuccess = false;
      })

      // retireModule
      .addCase(retireModule.pending, (state) => {
        handlePending(state);
        state.updateSuccess = false;
      })
      .addCase(retireModule.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.updateSuccess = true;
      })
      .addCase(retireModule.rejected, (state, action) => {
        handleRejected(state, action);
        state.updateSuccess = false;
      })

      // fetchProjectUserRoles
      .addCase(fetchProjectUserRoles.pending, handlePending)
      .addCase(fetchProjectUserRoles.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.projectUserRoles = action.payload;
      })
      .addCase(fetchProjectUserRoles.rejected, handleRejected)

      // fetchAllModuleTypes
      .addCase(fetchAllModuleTypes.pending, handlePending)
      .addCase(fetchAllModuleTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isGenericError = false;
        state.moduleTypes = action.payload;
      })
      .addCase(fetchAllModuleTypes.rejected, handleRejected)

      // fetchAllModuleTypesForSession
      .addCase(fetchAllModuleTypesForSession.pending, handlePending)
      .addCase(fetchAllModuleTypesForSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isGenericError = false;
        state.moduleTypes = action.payload;
      })
      .addCase(fetchAllModuleTypesForSession.rejected, handleRejected)

      // fetchModuleById
      .addCase(fetchModuleById.pending, handlePending)
      .addCase(fetchModuleById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isGenericError = false;
        state.fetchModuleData = action.payload;
      })
      .addCase(fetchModuleById.rejected, handleRejected)

      // fetchCountries
      .addCase(fetchCountries.pending, handlePending)
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isGenericError = false;
        state.countriesList = action.payload; // Ensure this line correctly updates the state
      })
      .addCase(fetchCountries.rejected, handleRejected)

      // fetchSectors
      .addCase(fetchSectors.pending, (state) => {
        state.isLoadingSectors = true;
        state.isError = false;
        state.errorMessage = "";
        state.isGenericError = false;
      })
      .addCase(fetchSectors.fulfilled, (state, action) => {
        state.isLoadingSectors = false;
        state.isError = false;
        state.isGenericError = false;
        state.sectorsList = action.payload; // Ensure this line correctly updates the state
      })
      .addCase(fetchSectors.rejected, (state, action) => {
        state.isLoadingSectors = false;
        state.isError = true;
        state.errorMessage = action.payload?.errorMessage || action.payload;
        state.isGenericError = action.payload?.isGenericError || false;
      })

      // updateModule
      .addCase(updateModule.pending, handlePending)
      .addCase(updateModule.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.moduleTypes = action.payload;
      })
      .addCase(updateModule.rejected, handleRejected)

      // fetchClientsDetails
      .addCase(fetchClientsDetails.pending, handlePending)
      .addCase(fetchClientsDetails.fulfilled, handleFulfilled)
      .addCase(fetchClientsDetails.rejected, handleRejected)

      // fetchClientTypes
      .addCase(fetchClientTypes.pending, handlePending)
      .addCase(fetchClientTypes.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.types = action.payload;
      })
      .addCase(fetchClientTypes.rejected, handleRejected)

      // createClient
      .addCase(createClient.pending, handlePending)
      .addCase(createClient.fulfilled, handleFulfilled)
      .addCase(createClient.rejected, handleRejected)

      // updateClient
      .addCase(updateClient.pending, handlePending)
      .addCase(updateClient.fulfilled, handleFulfilled)
      .addCase(updateClient.rejected, handleRejected)

      // deleteClient
      .addCase(deleteClient.pending, handlePending)
      .addCase(deleteClient.fulfilled, handleFulfilled)
      .addCase(deleteClient.rejected, handleRejected)

      // getAllSessionFrameWork
      .addCase(getAllSessionFrameWork.pending, handlePending)
      .addCase(getAllSessionFrameWork.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isGenericError = false;
        state.sessionFramework = action.payload;
      })
      .addCase(getAllSessionFrameWork.rejected, handleRejected);
  },
});

export const { clearError } = projectDetailsSlice.actions;
export default projectDetailsSlice.reducer;