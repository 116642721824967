import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_THRESHOLD,
  CREATE_UPDATE_THRESHOLD,
  GET_THRESHOLD_TYPES,
} from "../../services/Api";

// Constants for action types
const FETCH_THRESHOLD_TYPES = "fetchThresholdTypes";
const FETCH_THRESHOLD = "fetchThreshold";
const CREATE_UPDATE_THRESHOLD_ACTION = "createUpdateThreshold";

// Helper function for API calls
const fetchData = async (method, url, data, token, rejectWithValue) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    const errorMessage = error.response?.data?.message;

    if (!errorMessage) {
      // No specific error message found
      return rejectWithValue({
        errorMessage:
          error.response?.data?.title ||
          error.message ||
          "Unknown error occurred",
        isGenericError: true,
      });
    } else {
      return rejectWithValue({ errorMessage, isGenericError: false });
    }
  }
};

// Async thunks
export const fetchThresholdTypes = createAsyncThunk(
  FETCH_THRESHOLD_TYPES,
  async ({ token }, { rejectWithValue }) => {
    return fetchData("get", GET_THRESHOLD_TYPES, null, token, rejectWithValue);
  }
);

export const fetchThreshold = createAsyncThunk(
  FETCH_THRESHOLD,
  async ({ moduleId, thresholdTypeId, token }, { rejectWithValue }) => {
    return fetchData("get", `${GET_THRESHOLD}/${moduleId}/${thresholdTypeId}`, null, token, rejectWithValue);
  }
);

export const createUpdateThreshold = createAsyncThunk(
  CREATE_UPDATE_THRESHOLD_ACTION,
  async ({ moduleId, thresholdData, token }, { rejectWithValue }) => {
    return fetchData("post", `${CREATE_UPDATE_THRESHOLD}/${moduleId}`, thresholdData, token, rejectWithValue);
  }
);

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  postSuccess: false,
  errorMessage: "",
  isGenericError: false
};

// Common state handlers
const handlePending = (state) => {
  state.isLoading = true;
  state.errorMessage = "";
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.data = action.payload;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload.errorMessage;
  state.isGenericError = action.payload.isGenericError || false;
};

const handleCreateUpdatePending = (state) => {
  state.isLoading = true;
  state.postSuccess = false;
  state.isError = false;
  state.errorMessage = "";
};

const handleCreateUpdateFulfilled = (state, action) => {
  state.isLoading = false;
  state.postSuccess = true;
  state.data = action.payload;
  state.isError = false;
};

const handleCreateUpdateRejected = (state, action) => {
  state.isLoading = false;
  state.postSuccess = false;
  state.isError = true;
  state.errorMessage = action.payload.errorMessage;
  state.isGenericError = action.payload.isGenericError || false;
};

const dmaProcessSpecificationSlice = createSlice({
  name: "dmaProcessSpecification",
  initialState,
  reducers: {
    clearError(state) {
      state.isError = false;
      state.errorMessage = "";
      state.isGenericError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchThresholdTypes.pending, handlePending)
      .addCase(fetchThresholdTypes.fulfilled, handleFulfilled)
      .addCase(fetchThresholdTypes.rejected, handleRejected)
      .addCase(fetchThreshold.pending, handlePending)
      .addCase(fetchThreshold.fulfilled, handleFulfilled)
      .addCase(fetchThreshold.rejected, handleRejected)
      .addCase(createUpdateThreshold.pending, handleCreateUpdatePending)
      .addCase(createUpdateThreshold.fulfilled, handleCreateUpdateFulfilled)
      .addCase(createUpdateThreshold.rejected, handleCreateUpdateRejected);
  },
});

export const { clearError } = dmaProcessSpecificationSlice.actions;
export default dmaProcessSpecificationSlice.reducer;