import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_GL_URL,
  CREATE_GL_URL,
  EDIT_GL_URL,
  DELETE_GL_URL,
} from "../../services/Api";

// Common error handler
const handleError = (error, rejectWithValue) => {
  console.error(error.message);
  const errorMessage = error.response?.data?.message;

  if (!errorMessage) {
    // No specific error message found
    return rejectWithValue({
      errorMessage:
        error.response?.data?.title ||
        error.message ||
        "Unknown error occurred",
      isGenericError: true,
    });
  } else {
    return rejectWithValue({ errorMessage, isGenericError: false });
  }
};

// Common request handler
const makeRequest = async (method, url, data, token, rejectWithValue) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return handleError(error, rejectWithValue);
  }
};

// Async thunks
export const fetchAllGl = createAsyncThunk(
  "allGldata/fetchAllGl",
  async ({ moduleId, token }, { rejectWithValue }) => {
    return makeRequest(
      "get",
      `${GET_ALL_GL_URL}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

export const createGl = createAsyncThunk(
  "allGldata/createGl",
  async ({ moduleId, glData, token }, { rejectWithValue }) => {
    return makeRequest(
      "post",
      `${CREATE_GL_URL}/${moduleId}`,
      glData,
      token,
      rejectWithValue
    );
  }
);

export const updateGl = createAsyncThunk(
  "allGldata/updateGl",
  async ({ glId, moduleId, glData, token }, { rejectWithValue }) => {
    return makeRequest(
      "put",
      `${EDIT_GL_URL}/${glId}/${moduleId}`,
      glData,
      token,
      rejectWithValue
    );
  }
);

export const deleteGl = createAsyncThunk(
  "allGldata/deleteGl",
  async ({ glId, moduleId, token }, { rejectWithValue }) => {
    return makeRequest(
      "delete",
      `${DELETE_GL_URL}/${glId}/${moduleId}`,
      null,
      token,
      rejectWithValue
    );
  }
);

// Common state handlers
const handlePending = (state) => {
  state.isLoading = true;
  state.isError = false;
  state.errorMessage = "";
  state.isGenericError = false;
};

const handleFulfilled = (state, action) => {
  state.isLoading = false;
  state.data = action.payload;
  state.isError = false;
  state.isGenericError = false;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload.errorMessage;
  state.isGenericError = action.payload.isGenericError || false;
};

// Slice definition
const fetchAllGlSlice = createSlice({
  name: "allGldata",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
    isGenericError: false
  },
  reducers: {
    clearError(state) {
      state.isError = false;
      state.errorMessage = "";
      state.isGenericError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGl.pending, handlePending)
      .addCase(fetchAllGl.fulfilled, handleFulfilled)
      .addCase(fetchAllGl.rejected, handleRejected)
      .addCase(createGl.pending, handlePending)
      .addCase(createGl.fulfilled, handleFulfilled)
      .addCase(createGl.rejected, handleRejected)
      .addCase(updateGl.pending, handlePending)
      .addCase(updateGl.fulfilled, handleFulfilled)
      .addCase(updateGl.rejected, handleRejected)
      .addCase(deleteGl.pending, handlePending)
      .addCase(deleteGl.fulfilled, handleFulfilled)
      .addCase(deleteGl.rejected, handleRejected);
  },
});

export const { clearError } = fetchAllGlSlice.actions;
export default fetchAllGlSlice.reducer;
