import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import "./DMAIroAssessment.scss";
import MappingForm from "../../../components/forms/MappingForm";
import RateCard from "../../../components/rateCard/RateCard";
import { useTranslation } from "react-i18next";
import IROAssessmentSubheader from "../../../components/iroAssessmentSubheader/IROAssessmentSubheader";
import { Box, TableCell, IconButton } from "@mui/material";
import ClientTable from "../../../components/clientTable/ClientTable";
import ContextSelection from "../../../components/contextSelection/ContextSelection";
import { Edit } from "../../../constants/icons/Icons";
import TreeView from "../../../components/treeView/TreeView";
import {
  MotifCard,
  MotifCardHeader,
  MotifCardBody,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import ReferenceListTab from "../../../components/referenceListTab/ReferenceListTab";
import IroAssessmentModal from "../../../components/modals/dmaModals/IroAssessmentModal";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { fetchClientsDetails } from "../../../features/slices/ProjectScreens";
import { useDispatch, useSelector } from "react-redux";
import {
  createIROAssessment,
  fetchIroModuleById,
  getAllModuleRating,
  getSourceRefList,
  getAPSRefList,
  getStakeholderRefList,
  getValueChainRefList,
  saveRACIMapping,
  getIROAssessment,
  updateIROAssessment,
  deleteIROAssessment,
  getGeographicalRefList,
  getSustainabilityMattersIro,
  clearError as clearDMAError,
} from "../../../features/slices/DMAIroAssessment";
import { fetchLikelihood } from "../../../features/slices/Likelihood";
import PropTypes from "prop-types";
import ErrorModal from "../../../components/modals/error/errorModal";
import DeleteModal from "../../../components/modals/deleteModal/DeleteModal";
import { handleError as handleLogoutError } from "../../../utils/handleError";
import IroModal from "../../../components/modals/dmaModals/IroModal";
import {
  updateIro,
  deleteIro,
  getApsIro,
  getSourcesIro,
  getValueChainIro,
  getStakeholdersIro,
  getGeographiesIro,
  updateSourcesIro,
  updateValueChainIro,
  updateApsIro,
  updateGeographiesIro,
  updateStakeholdersIro,
  getAllSustainabilityMattersIro,
  updateSustainabilityMattersIro,
  getAllTimeframeTypes,
  getAllIroTypes,
  getAllIro,
  clearError as clearIROError,
} from "../../../features/slices/Iro";

const DMAIroAssessment = ({
  navigateToIdentification,
  assessmentIroId,
  setAssessmentIroId,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentAssessmentId, setCurrentAssessmentId] =
    useState(assessmentIroId);
  const { moduleId: moduleIdString } = useParams();
  const moduleId = parseInt(moduleIdString);
  const [modal, setModal] = useState({ isOpen: false, title: "", mode: "add" });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    itemId: null,
  });
  const [iroModal, setIroModal] = useState({
    isOpen: false,
    title: "",
    mode: "add",
  });
  const [scoreData, setScoreData] = useState([]);
  const [smData, setSmData] = useState([]);
  const [iroModule, setIroModule] = useState([]);
  const [raciDropdownData, setRaciDropdownData] = useState([]);
  const [ratingDropDownArray, setRatingDropDownArray] = useState([]);
  const [ratingLikelihoodDropDownArray, setRatingLikelihoodDropDownArray] =
    useState([]);
  const [sources, setSources] = useState([]);
  const [valueChain, setValueChain] = useState([]);
  const [aps, setAps] = useState([]);
  const [stakeholder, setStakeholder] = useState([]);
  const [geographies, setGeographies] = useState([]);
  const [infoRating, setInfoRating] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const [ratingCardScore, setRatingCardScore] = useState(null);
  const [ratingCardMaterial, setRatingCardMaterial] = useState(false);
  const [filteredUpdateData, setFilteredUpdateData] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateItemID, setUpdateItemID] = useState(null);
  const [updateIroItemID, setUpdateIroItemID] = useState(null);
  const [deleteItemID, setDeleteItemID] = useState(null);
  const [getIroData, setGetIroData] = useState([]);
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const projectId = location.state?.projectId;
  const [refreshData, setRefreshData] = useState(0);

  const [pillarOptions, setPillarOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [timeframeOptions, setTimeframeOptions] = useState([]);
  const [iroTypesOptions, setIroTypesOptions] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [justDeleted, setJustDeleted] = useState(false);

  const {
    isError: isDMAError,
    isGenericError: isDMAGenericError,
    errorMessage: dmaErrorMessage,
    isLoading: isDMAIroLoading,
  } = useSelector((state) => state.dmaIroAssessment);

  const {
    isError: isIROError,
    isGenericError: isIROGenericError,
    errorMessage: iroErrorMessage,
    isLoading: isIroLoading,
  } = useSelector((state) => state.iro);

  // Combine isLoading values
  const isLoading = isDMAIroLoading || isIroLoading;

  useEffect(() => {
    const fetchIroData = async () => {
      try {
        const response = await dispatch(
          getAllIro({ moduleId, token })
        ).unwrap();
        if (response?.data) {
          setGetIroData(response.data);
        } else {
          setGetIroData([]);
        }
      } catch (error) {
        console.error("Error fetching IRO data:", error);
        setGetIroData([]);
      }
    };

    if (moduleId && token) {
      fetchIroData();
    }
  }, [dispatch, moduleId, token]);

  useEffect(() => {
    if (getIroData) {
      if (!justDeleted && assessmentIroId === null && getIroData.length > 0) {
        const firstIroId = getIroData[0]?.id;
        setAssessmentIroId(firstIroId);
        setCurrentAssessmentId(firstIroId);
      }
    }
  }, [getIroData, assessmentIroId, setAssessmentIroId, justDeleted]);

  const moduleIROId = assessmentIroId;
  const iroId = assessmentIroId;

  const getErrorState = () => {
    if (isDMAError) {
      return {
        isError: isDMAError,
        isGenericError: isDMAGenericError,
        errorMessage: dmaErrorMessage,
      };
    }
    if (isIROError) {
      return {
        isError: isIROError,
        isGenericError: isIROGenericError,
        errorMessage: iroErrorMessage,
      };
    }
    return { isError: false, isGenericError: false, errorMessage: null };
  };

  const { isError, isGenericError, errorMessage } = getErrorState();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const [
        smResponse,
        clientResponse,
        getIroModuleResponse,
        sourceReferneceResponse,
        valueChainReferenceResponse,
        apsReferenceResponse,
        stakeholderReferenceResponse,
        geographiesReferenceResponse,
        getIROAssessmentListResponse,
      ] = await Promise.all([
        dispatch(getSustainabilityMattersIro({ moduleId, iroId, token })),
        dispatch(fetchClientsDetails({ projectId, token })).unwrap(),
        dispatch(fetchIroModuleById({ moduleIROId, moduleId, token })).unwrap(),
        dispatch(getSourceRefList({ moduleIROId, moduleId, token })).unwrap(),
        dispatch(
          getValueChainRefList({ moduleIROId, moduleId, token })
        ).unwrap(),
        dispatch(getAPSRefList({ moduleIROId, moduleId, token })).unwrap(),
        dispatch(
          getStakeholderRefList({ moduleIROId, moduleId, token })
        ).unwrap(),
        dispatch(
          getGeographicalRefList({ moduleIROId, moduleId, token })
        ).unwrap(),
        dispatch(getIROAssessment({ moduleIROId, moduleId, token })).unwrap(),
      ]);

      setSmData(smResponse.payload.data || []);
      if (Array.isArray(clientResponse.data)) {
        const clientsData = clientResponse.data.map((item) => ({
          ...item,
          typeName: item.clientOrganisationType?.name || "Unknown",
        }));
        setRaciDropdownData(clientsData);
      } else {
        setRaciDropdownData([]);
      }
      if (getIroModuleResponse.data) {
        setIroModule(getIroModuleResponse.data?.moduleIRODto);
      } else {
        setIroModule([]);
      }
      if (sourceReferneceResponse?.data) {
        setSources(sourceReferneceResponse.data.iroAssessmentSourceServices);
      } else {
        setSources([]);
      }
      if (valueChainReferenceResponse?.data) {
        setValueChain(
          valueChainReferenceResponse.data.iroAssessmentValueChainServices
        );
      } else {
        setValueChain([]);
      }
      if (apsReferenceResponse?.data) {
        setAps(apsReferenceResponse.data.iroAssessmentAPSServices);
      } else {
        setAps([]);
      }
      if (stakeholderReferenceResponse?.data) {
        setStakeholder(
          stakeholderReferenceResponse.data.iroAssessmentStakeholdersServices
        );
      } else {
        setStakeholder([]);
      }
      if (geographiesReferenceResponse?.data) {
        setGeographies(
          geographiesReferenceResponse.data
            .iroAssessmentGeographicalLocationServices
        );
      } else {
        setGeographies([]);
      }

      if (getIROAssessmentListResponse.data) {
        const modifiedArray =
          getIROAssessmentListResponse.data.iroAssessments.map((item) => ({
            ...item,
            score: item.assessment.score,
            isMaterial: item.assessment.isMaterial ? "Yes" : "No",
            isPosIsNeg: item.isPosIsNeg.split("/")[0],
          }));
        setScoreData(modifiedArray);
        setRatingCardScore(
          getIROAssessmentListResponse.data.maxScore === null
            ? "N/A"
            : Math.floor(getIROAssessmentListResponse.data.maxScore * 10) / 10
        );
        setRatingCardMaterial(
          getIROAssessmentListResponse.data.isAssessmentMaterial
        );
      } else {
        setScoreData([]);
      }

      if (getIroModuleResponse?.data?.moduleIRODto) {
        const lastSavedData = getIroModuleResponse.data.moduleIRODto;

        const clientData = clientResponse?.data || [];

        const getClientById = (id) => {
          const client = clientData.find((item) => item.id === id);
          return client ? client.id : ""; // Use client.id because the form expects IDs
        };

        setInitialData({
          responsible: getClientById(lastSavedData?.raciResponsibleId),
          accountable: getClientById(lastSavedData?.raciAccountableId),
          consulted: getClientById(lastSavedData?.raciConsultedId),
          informed: getClientById(lastSavedData?.raciInformedId),
          others: getClientById(lastSavedData?.raciOthersId),
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [dispatch, moduleId, moduleIROId, projectId, token, iroId]);

  const findCurrentIndex = () => {
    if (!Array.isArray(getIroData)) {
      return -1; // Prevent runtime errors
    }
    return getIroData.findIndex((item) => item.id === currentAssessmentId);
  };

  const handleNext = () => {
    const currentIndex = findCurrentIndex();
    if (currentIndex < getIroData.length - 1) {
      const nextId = getIroData[currentIndex + 1].id;
      setCurrentAssessmentId(nextId);
      setAssessmentIroId(nextId);
    }
  };

  const handlePrevious = () => {
    const currentIndex = findCurrentIndex();
    if (currentIndex > 0) {
      const prevId = getIroData[currentIndex - 1].id;
      setCurrentAssessmentId(prevId);
      setAssessmentIroId(prevId);
    }
  };

  useEffect(() => {
    if (currentAssessmentId) {
      fetchData();
    }
  }, [currentAssessmentId, fetchData, refreshData, updateTrigger]);

  const fetchApiData = useCallback(
    async (action, setState) => {
      try {
        const response = await dispatch(action({ token })).unwrap();
        if (response.success) {
          setState(response.data);
        }
      } catch (error) {
        console.error(`Error fetching data:`, error);
      }
    },
    [dispatch, token]
  );

  useEffect(() => {
    if (token) {
      fetchApiData(getAllTimeframeTypes, setTimeframeOptions);
      fetchApiData(getAllIroTypes, setIroTypesOptions);
    }
  }, [token, dispatch, moduleId, updateTrigger, fetchApiData]);

  const onSave = async (formData) => {
    if (formData) {
      try {
        const payload = {
          raciResponsibleId: formData.responsible,
          raciAccountableId: formData.accountable,
          raciConsultedId: formData.consulted,
          raciInformedId: formData.informed,
          raciOthersId: formData.others,
        };

        // Dispatch the saveRACIMapping action with the payload
        const response = await dispatch(
          saveRACIMapping({
            moduleIROId: moduleIROId,
            moduleId: moduleId,
            raciData: payload,
            token,
          })
        ).unwrap();

        if (response.success) {
          console.info("RACI mapping saved successfully:", response);
        } else {
          console.error("Failed to save RACI mapping:", response);
        }
      } catch (error) {
        console.error("Error while saving RACI mapping:", error);
      }
    }
  };

  const onCancel = async () => {
    try {
      // Fetch the last saved RACI data
      const getIroModuleResponse = await dispatch(
        fetchIroModuleById({ moduleIROId, moduleId, token })
      ).unwrap();
      const lastSavedData = getIroModuleResponse?.data?.moduleIRODto;

      if (lastSavedData) {
        // Fetch client details to map IDs to names
        const clientResponse = await dispatch(
          fetchClientsDetails({ projectId, token })
        ).unwrap();
        const clientData = clientResponse?.data || [];

        // Helper function to get client name by ID
        const getClientById = (id) => {
          const client = clientData.find((item) => item.id === id);
          return client ? client.id : ""; // Return empty string if client is not found
        };

        // Set the initial form data with client names or IDs
        setInitialData({
          responsible: getClientById(lastSavedData?.raciResponsibleId),
          accountable: getClientById(lastSavedData?.raciAccountableId),
          consulted: getClientById(lastSavedData?.raciConsultedId),
          informed: getClientById(lastSavedData?.raciInformedId),
          others: getClientById(lastSavedData?.raciOthersId),
        });
      }
    } catch (error) {
      console.error("Error fetching RACI data:", error);
    }
  };

  const columnsAssessment = [
    { colName: "isMaterial", label: t("Material"), showSorting: false },
    { colName: "valueChains", label: t("ValueChain"), showSorting: false },
    { colName: "score", label: t("Score"), showSorting: false },
    { colName: "isPosIsNeg", label: t("Type"), showSorting: false },
    { colName: "Action", label: t("Action"), showSorting: false },
  ];

  const findItemById = (data, id) => {
    for (let item of data) {
      if (item.assessment.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const renderTableCell = (colName, value, row, index) => {
    const handleEditClick = (mode) => {
      fetchRatingDropDownValues();
      setModal({
        isOpen: true,
        title: mode === "add" ? "New assessment" : "Edit assessment",
        mode: mode,
      });

      setFilteredUpdateData(findItemById(scoreData, row.assessment.id));
      setUpdateItemID(row.assessment.id);
      setDeleteItemID(row.assessment.id);
    };
    if (colName === "Action") {
      return (
        <TableCell
          key="actions"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => handleEditClick("edit")}>
              <Edit />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    return <TableCell key={index}>{value}</TableCell>;
  };

  const transformedItems = smData?.map((item) => ({
    id: item.id,
    itemId: item.id,
    label: item.label || item.name,
    children: item.children || [],
  }));

  const handleAddNew = (mode) => {
    setModal({
      isOpen: true,
      title: mode === "add" ? "New Assessment" : "Edit Assessment",
      mode: mode,
    });

    fetchRatingDropDownValues();
  };
  const fetchRatingDropDownValues = async () => {
    try {
      const [dropDownValueAssessment, dropDownValueLikelihood] =
        await Promise.all([
          dispatch(getAllModuleRating({ moduleId, token })).unwrap(),
          dispatch(fetchLikelihood({ moduleId, token })).unwrap(),
        ]);

      if (dropDownValueAssessment.data && dropDownValueLikelihood.data) {
        const combinedArray = [...dropDownValueAssessment.data];

        const likelihoodArray = dropDownValueLikelihood.data;

        const resultArray = [];
        combinedArray.forEach((item) =>
          resultArray.push({
            id: item.id,
            val: item.rating,
            label: item.likelihoodLabel || item.scaleLabel,
          })
        );

        const resultArrayLikelihood = [];
        likelihoodArray.forEach((item) =>
          resultArrayLikelihood.push({
            id: item.id,
            val: item.rating,
            label: item.likelihoodLabel || item.scaleLabel,
          })
        );
        setInfoRating(dropDownValueAssessment.data);
        setRatingDropDownArray(resultArray);
        setRatingLikelihoodDropDownArray(resultArrayLikelihood);
      } else {
        setRatingDropDownArray([]);
      }
    } catch (error) {
      console.error("Fetching of the data failed:", error);
    }
  };
  const handleCloseModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
  };

  const handleCloseIroModal = () => {
    setIroModal((prev) => ({ ...prev, isOpen: false }));
    resetDataFetchedAndTabData();
  };

  const resetDataFetchedAndTabData = () => {
    setDataFetched({
      source: false,
      aps: false,
      valuechain: false,
      geographies: false,
      stakeholders: false,
      sustainablilitymatters: false,
      updatedsustainablilitymatters: false,
    });

    setTabData({
      source: [],
      aps: [],
      valuechain: [],
      geographies: [],
      stakeholders: [],
      sustainablilitymatters: [],
      updatedsustainablilitymatters: [],
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await dispatch(deleteIro({ iroId: iroId, token, moduleId })).unwrap();
      setAssessmentIroId(null);
      setCurrentAssessmentId(null);
      setJustDeleted(true);
      setDeleteModal({ isOpen: false, itemId: null });
      onBackClick();
    } catch (error) {
      console.error("Deletion failed:", error);
    }
  };

  const handleDeleteClick = () => {
    setDeleteModal({ isOpen: true, itemId: updateIroItemID });
  };

  const createNewIroAssessmentApi = async (data) => {
    if (data) {
      try {
        const response = await dispatch(
          createIROAssessment({ iroAssessmentData: data, token })
        ).unwrap();
        if (response.success) {
          handleCloseModal();
          setRefreshData((prev) => prev + 1);
        }
      } catch (error) {
        console.error("Creation failed:", error);
      }
    }
  };

  const updateIroAssessmentApi = async (data) => {
    if (data) {
      try {
        const response = await dispatch(
          updateIROAssessment({ iroAssessmentData: data, token })
        ).unwrap();
        if (response.success) {
          handleCloseModal();
          setRefreshData((prev) => prev + 1);
        }
      } catch (error) {
        console.error("Updating failed:", error);
      }
    }
  };

  const handleDeleteCloseModalTry = () => {
    setIsDeleteModalOpen(false);
  };

  const childRef = useRef();

  const handleDelete = async () => {
    try {
      await dispatch(
        deleteIROAssessment({
          assessmentId: deleteItemID,
          moduleIROId,
          moduleId,
          token,
        })
      ).unwrap();
      handleDeleteCloseModalTry();
      setRefreshData((prev) => prev + 1);
      if (childRef.current) {
        childRef.current.handleDeleteCloseModal();
      }
    } catch (error) {
      console.error("Deletion failed:", error);
    }
  };

  const findIroItemById = (data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findIroItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const handleIroEditClick = (iroId) => {
    const itemToEdit = findIroItemById(getIroData, iroId); // Find the specific item by ID
    if (itemToEdit) {
      setIroModal({
        isOpen: true,
        title: t("EditIRO"), // Set the modal title to "Edit IRO"
        mode: "edit", // Set the mode to edit
      });
      setFilteredUpdateData(itemToEdit); // Set the data to be displayed in the modal
      setUpdateIroItemID(iroId); // Store the ID of the item being updated
    } else {
      console.error("Item not found for editing.");
    }
  };

  const [tabData, setTabData] = useState({
    source: [],
    aps: [],
    valuechain: [],
    geographies: [],
    stakeholders: [],
    sustainablilitymatters: [],
    updatedsustainablilitymatters: [],
  });

  const [dataFetched, setDataFetched] = useState({
    source: false,
    aps: false,
    valuechain: false,
    geographies: false,
    stakeholders: false,
    sustainablilitymatters: false,
    updatedsustainablilitymatters: false,
  });

  const updateIroApi = async (data) => {
    if (data) {
      data.moduleId = moduleId;
      data.moduleIROId = updateIroItemID;
      try {
        const response = await dispatch(
          updateIro({ iroId: updateIroItemID, iroData: data, token })
        ).unwrap();
        if (response.success) {
          setUpdateTrigger((prev) => prev + 1);
          handleCloseIroModal();
          setAssessmentIroId(updateIroItemID);
        }
      } catch (error) {
        console.error("Updation failed:", error);
        setUpdateTrigger((prev) => prev + 1);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const fetchTabData = async (tab) => {
    if (!dataFetched[tab]) {
      try {
        const tabActions = {
          source: getSourcesIro,
          aps: getApsIro,
          valuechain: getValueChainIro,
          geographies: getGeographiesIro,
          stakeholders: getStakeholdersIro,
          sustainablilitymatters: getAllSustainabilityMattersIro,
        };

        const action = tabActions[tab];
        if (action) {
          const response = await dispatch(
            action({ iroId: updateIroItemID, token, moduleId })
          ).unwrap();

          if (response?.data) {
            setTabData((prev) => ({ ...prev, [tab]: response.data }));
            setDataFetched((prev) => ({ ...prev, [tab]: true }));
          }
        }
      } catch (error) {
        console.error(`Error fetching data for ${tab}:`, error);
      }
    }
  };

  const memoizedIroData = useMemo(() => getIroData || [], [getIroData]);

  useEffect(() => {
    if (memoizedIroData.length) {
      setPillarOptions(getUniqueOptions(memoizedIroData, "pillar"));
      setAreaOptions(getUniqueOptions(memoizedIroData, "area"));
    }
  }, [memoizedIroData]);

  const getUniqueOptions = (data, key) => {
    return [...new Set(data.map((item) => item[key]))].map((value) => ({
      value,
      label: value,
    }));
  };

  const updateTabData = async () => {
    const apiCalls = {
      source: updateSourcesIro,
      aps: updateApsIro,
      valuechain: updateValueChainIro,
      geographies: updateGeographiesIro,
      stakeholders: updateStakeholdersIro,
      sustainablilitymatters: updateSustainabilityMattersIro,
    };

    const tabDataMapping = {
      source: tabData.source,
      aps: tabData.aps,
      valuechain: tabData.valuechain,
      geographies: tabData.geographies,
      stakeholders: tabData.stakeholders,
      sustainablilitymatters: tabData.updatedsustainablilitymatters,
    };

    try {
      // Loop through each tab and trigger the corresponding API call
      const updatePromises = Object.keys(apiCalls).map(async (tab) => {
        const apiAction = apiCalls[tab];
        const tabDataItems = tabDataMapping[tab];

        if (tabDataItems && tabDataItems.length > 0) {
          const response = await dispatch(
            apiAction({
              iroId: updateIroItemID,
              iroData: tabDataItems.map((item) => ({
                ...item,
              })),
              token,
              moduleId,
            })
          ).unwrap();

          if (!response.success) {
            throw new Error(`Update failed for ${tab}`);
          }
        }
      });

      // Wait for all API calls to complete
      await Promise.all(updatePromises);
      setUpdateTrigger((prev) => prev + 1);
      handleCloseIroModal();
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  const onBackClick = () => {
    navigateToIdentification();
  };

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);

  // Whenever isError or errorMessage changes, reset showErrorModal
  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    if (isDMAError) {
      dispatch(clearDMAError());
    }
    if (isIROError) {
      dispatch(clearIROError());
    }
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? () => handleLogoutError(dispatch, navigate)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }

  return (
    <div className="iro-assessment-container">
      <div className="iro-subheader">
        <IROAssessmentSubheader
          header={iroModule.name || ""}
          subHeader={(iroModule.pillar || "") + " " + (iroModule.name || "")}
          impact={iroModule.iroTypeName || ""}
          previousButtonLabel={t("Previous")}
          nextButtonLabel={t("Next")}
          onBackClick={onBackClick}
          handleNextClick={handleNext}
          handlePreviousClick={handlePrevious}
          onEditClick={() => handleIroEditClick(iroId)}
          pagename="IRO"
          previousButtonDisabled={findCurrentIndex() === 0}
        />
      </div>
      <div className="motif-container iro-section">
        <div className="motif-row">
          <div className="motif-col-8 motif-col-xl-8 iro-left-section">
            <RateCard
              ratingCardValue={ratingCardScore}
              ratingCardHeader={"IRO assessment"}
              ratingCardLabel={
                ratingCardMaterial === null
                  ? "N/A"
                  : ratingCardMaterial
                  ? "Material"
                  : "Non Material"
              }
            />

            <MotifCard className="assessment-card">
              <MotifCardHeader className="assessment-header" variant="alt">
                {t("Assessments")}
              </MotifCardHeader>
              <MotifCardBody>
                <ContextSelection
                  addButtonText={t("Add New")}
                  onClickNewProject={() => handleAddNew("add")}
                  pageName={t("Assessments")}
                />

                <div className="gl-card-table-switch assessment-table">
                  {modal.isOpen && (
                    <IroAssessmentModal
                      iroType={iroModule.iroTypeName || ""}
                      modalTitle={modal.title}
                      modalMode={modal.mode}
                      saveButtonLabel={t("Save")}
                      updateButtonLabel={t("Update")}
                      cancelButtonLabel={t("Cancel")}
                      onClose={handleCloseModal}
                      ratingDropDownArray={ratingDropDownArray}
                      likelihood={ratingLikelihoodDropDownArray}
                      valueChainDropDown={valueChain}
                      handleDoneClick={
                        modal.mode === "add"
                          ? createNewIroAssessmentApi
                          : updateIroAssessmentApi
                      }
                      deleteButtonLabel={t("Delete")}
                      ratingLabel={t("RatingLabel")}
                      descriptionLabel={t("Description")}
                      iroTypeLabel={t("IroTypeLabel")}
                      valueChainLabel={t("ValueChain")}
                      positiveNegativeLabel={t("PositiveNegative")}
                      actualPotentialLabel={t("ActualPotential")}
                      filteredUpdateData={filteredUpdateData}
                      moduleId={moduleId}
                      moduleIROId={moduleIROId}
                      handleDelete={handleDelete}
                      infoRating={infoRating}
                      closeModal={isDeleteModalOpen}
                      ref={childRef}
                    />
                  )}
                  <ClientTable
                    columns={columnsAssessment}
                    data={scoreData}
                    itemsPerPage={10}
                    renderTableCell={renderTableCell}
                    generateRowKey={(row) => row.id}
                  />
                </div>
              </MotifCardBody>
            </MotifCard>
          </div>
          <div className="motif-col-4 motif-col-xl-4 iro-right-section">
            <MotifCard className="sm-card">
              <MotifCardHeader
                className="assessment-header sticky-header"
                variant="alt"
              >
                {t("Sustainablilitymatters")}
              </MotifCardHeader>
              <MotifCardBody className="motif-card-body-scrollable">
                <TreeView
                  items={transformedItems}
                  pageName="IROA"
                  defaultExpandedItems={[]}
                  defaultSelectedItems="1"
                />
              </MotifCardBody>
            </MotifCard>
            <ReferenceListTab
              cardHeader={t("Reference lists")}
              valueChain={valueChain}
              sourcesData={sources}
              aps={aps}
              stakeholder={stakeholder}
              geographies={geographies}
            />
            <MappingForm
              responsibleLabel={t("Responsible")}
              accountableLabel={t("Accountable")}
              consultedLabel={t("Consulted")}
              informedLabel={t("Informed")}
              othersLabel={t("Others")}
              selectResponsilbleLabel={t("SelectResponsible")}
              selectAccountableLabel={t("SelectAccountable")}
              selectConsultedLabel={t("SelectConsulted")}
              selectInformedLabel={t("SelectInformed")}
              selectOthersLabel={t("SelectOthers")}
              RACIMapping={t("RACIMapping")}
              saveButtonLabel={t("Save")}
              cancelButtonLabel={t("Cancel")}
              onSave={onSave}
              onCancel={onCancel}
              raciDropdownData={raciDropdownData}
              initialData={initialData}
              pagenName="IROAssessment"
            />
            {iroModal.isOpen && (
              <IroModal
                onClose={handleCloseIroModal}
                modalTitle={iroModal.title}
                modalMode={iroModal.mode}
                nameLabel={t("Name")}
                typeLabel={t("IROType")}
                areaLabel={t("Area")}
                pillarLabel={t("Pillar")}
                selectAreaLabel={t("SelectArea")}
                SelectPillar={t("SelectPillar")}
                BasicLabel={t("Definition")}
                SelectType={t("SelectType")}
                InputName={t("EnterName")}
                SelectTimeframe={t("SelectTimeframe")}
                DescriptionLabel={t("Description")}
                TabDescriptionLabel={t("TabDescription")}
                SourceLabel={t("SourceLabel")}
                ValueChainLabel={t("ValueChain")}
                APSLabel={t("APSLabel")}
                SustainablilityMattersLabel={t("Sustainablilitymatters")}
                StakeholdersLabel={t("Stakeholders")}
                GeographiesLabel={t("Geographies")}
                saveButtonLabel={t("addIRO")}
                updateButtonLabel={t("updateIRO")}
                cancelButtonLabel={t("Cancel")}
                filterByLabel={t("FilterBy")}
                pillarOptions={pillarOptions}
                areaOptions={areaOptions}
                handleDoneClick={updateIroApi}
                filteredUpdateData={filteredUpdateData}
                fetchTabData={fetchTabData}
                tabData={tabData}
                disable={isLoading}
                setTabData={setTabData}
                updateTabData={updateTabData}
                TimeframeLabel={t("TimeframeLabel")}
                timeframeOptions={timeframeOptions}
                iroTypesOptions={iroTypesOptions}
                onDeleteClick={handleDeleteClick}
                DeleteLabel={t("DeleteLabel")}
              />
            )}

            {deleteModal.isOpen && (
              <DeleteModal
                isOpen={deleteModal.isOpen}
                onClose={() => setDeleteModal({ isOpen: false, itemId: null })}
                setName={t("DeleteLabel")}
                labelText={t("DeletConfirmationSources")}
                onCancel={() => setDeleteModal({ isOpen: false, itemId: null })}
                handleDeleteClick={handleConfirmDelete}
                cancelButtonLabel={t("Cancel")}
                deleteButtonLabel={t("DeleteLabel")}
              />
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="iro-motif-loader">
          <MotifProgressLoader className="loader" show variant="default" />
        </div>
      )}
    </div>
  );
};
DMAIroAssessment.propTypes = {
  navigateToIdentification: PropTypes.func,
  assessmentIroId: PropTypes.number,
  setAssessmentIroId: PropTypes.func,
};
export default DMAIroAssessment;