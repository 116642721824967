import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Header.scss";
import MenuIcon from "@mui/icons-material/Menu";
import {
  MotifButton,
  MotifAvatar,
  MotifDropdownItem,
  MotifDropdown,
} from "@ey-xd/motif-react";
import notice from "../../assets/files/PrivacyNoticeESGSuite3.0.pdf";
import {
  PrivacyNote,
  HelpDesk,
  Maintenance,
  Location,
} from "../../constants/icons/Icons";
import EYHeaderLogo from "../../assets/images/HeaderLogo.png";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { logoutUser, setUser, setPhoto } from "../../features/slices/HomeSlice";
import Select from "react-select";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const userState = useSelector((state) => state.user);
  const { user, photo, jwtToken } = userState;

  const token = jwtToken || sessionStorage.getItem("authToken");
  const name = user?.name;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!userState.user) {
      const storedUserData = sessionStorage.getItem("user");
      if (storedUserData) {
        const userData = JSON.parse(storedUserData);
        dispatch(setUser(userData));
      }
    }
    const storedLanguage = sessionStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      const preferredLanguage = "en";
      i18n.changeLanguage(preferredLanguage);
      sessionStorage.setItem("language", preferredLanguage);
    }

    if (!userState.photo) {
      const storedPhoto = sessionStorage.getItem("photo");
      if (storedPhoto) {
        dispatch(setPhoto(storedPhoto));
      }
    }
  }, [dispatch, i18n, userState.user, userState.photo]);

  const handleSignOut = async () => {
    try {
      dispatch(logoutUser());
      localStorage.clear();
      sessionStorage.clear();
      if (window.msalInstance) {
        console.log("MSAL instance found, logging out");
        await window.msalInstance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      } else {
        console.warn("MSAL instance not found. Redirecting to home.");
        navigate("/");
      }
    } catch (error) {
      console.error("Error during sign-out", error);
    }
  };

  const handleLanguageChange = (selectedOption) => {
    const language = selectedOption.value;
    i18n.changeLanguage(language);
    sessionStorage.setItem("language", language);
  };

  const languageOptions = [
    { value: "en", label: "EN" },
    { value: "es", label: "ES" },
  ];

  return (
    <header className="header-container">
      <div>
        <a href="/" aria-label="Home" className="logo-title">
          <img src={EYHeaderLogo} alt="Logo" width="40" height="40" />
          <h1 className="brand-title">{t("Title")}</h1>
        </a>
      </div>
      <nav className="nav-items">
        <ul>
          <li>
            <MotifButton
              className="header-motif-body"
              onClick={() => window.open(notice, "_blank")}
              data-testid="privacy-note-button"
            >
              <PrivacyNote />
              {t("PrivacyNote")}
            </MotifButton>
          </li>
          <li>
            <a href="mailto:esgsuite@ey.com" className="no-underline">
              <MotifButton
                className="header-motif-body"
                data-testid="help-desk-button"
              >
                <HelpDesk />
                {t("HelpDesk")}
              </MotifButton>
            </a>
          </li>
          {user && (
            <li>
              <MotifButton
                className="header-motif-body"
                onClick={() =>
                  navigate("/maintenance", { state: { token: token } })
                }
              >
                <Maintenance />
                {t("Maintenance")}
              </MotifButton>
            </li>
          )}
          <li>
            <MotifButton className="header-motif-body">
              <Location />
              USA
            </MotifButton>
          </li>
        </ul>
      </nav>
      <div className="right-menu">
        <button
          className="burger-menu"
          onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
          data-testid="menu-icon"
          aria-label="Toggle mobile menu"
        >
          <MenuIcon size={30} />
        </button>
        <MotifDropdown
          ariaLabelledby="dropdown-trigger-1"
          closeOnOutsideClick={false}
          id="dropdown-1"
          placement="bottom-start"
          trigger={
            <div>
              <MotifAvatar
                className="avatar-portal"
                description={name}
                size="medium"
                userName={name}
                src={photo ? `data:image/png;base64,${photo}` : undefined}
                onError={(e) => (e.target.src = undefined)}
                onClick={() => setDropdownVisible(!dropdownVisible)}
                data-testid="user-avatar"
              />
            </div>
          }
        >
          {user && (
            <div className="header-dropdown-menu">
              <ul aria-label="profile menu">
                <li>
                  <MotifDropdownItem>
                    <Select
                      aria-labelledby="select-label"
                      className="header-select"
                      onChange={handleLanguageChange}
                      value={languageOptions.find(
                        (option) => option.value === i18n.language
                      )}
                      options={languageOptions}
                    />
                  </MotifDropdownItem>
                </li>
                <span className="motif-dropdown-horizontal-divider" />
                <li>
                  <MotifButton
                    className="header-motif-body"
                    onClick={handleSignOut}
                    data-testid="sign-out-button"
                  >
                    {t("SignOut")}
                  </MotifButton>
                </li>
              </ul>
            </div>
          )}
        </MotifDropdown>
      </div>
      {mobileMenuVisible && (
        <div className="mobile-menu show">
          <ul>
            <li>
              <MotifButton
                className="header-motif-body"
                onClick={() => window.open(notice, "_blank")}
                data-testid="mobile-privacy-note-button"
              >
                <PrivacyNote />
                {t("PrivacyNote")}
              </MotifButton>
            </li>
            <li>
              <MotifButton
                className="header-motif-body"
                data-testid="mobile-help-desk-button"
              >
                <HelpDesk />
                {t("HelpDesk")}
              </MotifButton>
            </li>
            {user && (
              <li>
                <MotifButton
                  className="header-motif-body"
                  onClick={() =>
                    navigate("/maintenance", { state: { token: token } })
                  }
                >
                  <Maintenance />
                  {t("Maintenance")}
                </MotifButton>
              </li>
            )}
            <li>
              <MotifButton className="header-motif-body">
                <Location />
                USA
              </MotifButton>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  isAdmin: PropTypes.bool,
};

export default Header;
