import React, { useState, useEffect, forwardRef } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifErrorMessage,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./IroAssessmentModal.scss";
import { useTranslation } from "react-i18next";
import { Cross, InfoIcon } from "../../../constants/icons/Icons";
import Select from "react-select";
import DeleteModal from "../deleteModal/DeleteModal";

const MAX_CHAR_LIMIT = 3000;

const IroAssessmentModal = forwardRef(
  (
    {
      iroType,
      onClose,
      modalMode,
      modalTitle,
      ratingDropDownArray,
      saveButtonLabel,
      updateButtonLabel,
      cancelButtonLabel,
      valueChainDropDown,
      handleDoneClick,
      deleteButtonLabel,
      ratingLabel,
      descriptionLabel,
      iroTypeLabel,
      valueChainLabel,
      positiveNegativeLabel,
      actualPotentialLabel,
      filteredUpdateData,
      moduleId,
      moduleIROId,
      handleDelete,
      infoRating,
      likelihood,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const isEditMode = modalMode === "edit";
    const [selectedTab, setSelectedTab] = useState("Basic");
    const [showInfo, setShowInfo] = useState(false);
    const [info, setInfo] = useState(null);
    const [formData, setFormData] = useState({
      ScaleId: 0,
      ScaleDescription: "",
      ScopeId: 0,
      ScopeDescription: "",
      RemediabilityId: 0,
      RemediabilityDescription: "",
      MagnitudeId: 0,
      MagnitudeDescription: "",
      LikelihoodId: 0,
      LikelihoodDescription: "",
      valueChainId: [],
      positive: null,
      actual: null,
    });
    const [isPositiveError, setIsPositiveError] = useState("");
    const [actualValidation, setActualValidation] = useState("");
    const [descriptionErrors, setDescriptionErrors] = useState({});
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteItemID, setDeleteItemID] = useState(null);
    const [tabConditions, setTabConditions] = useState({
      scaleDisabled: true,
      scopeDisabled: true,
      remediabilityDisabled: true,
      magnitudeDisabled: true,
      likelihoodDisabled: true,
    });

    React.useImperativeHandle(ref, () => ({
      handleDeleteCloseModal,
    }));

    useEffect(() => {
      if (filteredUpdateData && isEditMode) {
        setFormData({
          ScaleId:
            filteredUpdateData.assessment.moduleRatingScaleId.toString() ||
            null,
          ScaleDescription:
            filteredUpdateData.assessment.ratingScaleDescription || "",
          ScopeId:
            filteredUpdateData.assessment.moduleRatingScopeId.toString() ||
            null,
          ScopeDescription:
            filteredUpdateData.assessment.ratingScopeDescription || "",
          RemediabilityId:
            filteredUpdateData.assessment.moduleRatingRemediabilityId.toString() ||
            null,
          RemediabilityDescription:
            filteredUpdateData.assessment.ratingRemediabilityDescription || "",
          MagnitudeId:
            filteredUpdateData.assessment.moduleRatingMagnitudeId.toString() ||
            null,
          MagnitudeDescription:
            filteredUpdateData.assessment.ratingRatingMagnitudeIdDescription ||
            "",
          LikelihoodId:
            filteredUpdateData.assessment.moduleRatingLikelihoodId.toString() ||
            null,
          LikelihoodDescription:
            filteredUpdateData.assessment.ratingLikelihoodDescription || "",
          valueChainId: filteredUpdateData.valueChainIds,
          positive: filteredUpdateData.assessment.isPositive,
          actual: filteredUpdateData.assessment.isActual,
        });
      }
    }, [filteredUpdateData, isEditMode]);

    useEffect(() => {
      const isPositive = formData.positive === true;
      const isActual = formData.actual === true;
      const type = iroType;

      if (iroType === "Risk") {
        setFormData((prev) => ({ ...prev, positive: false, actual: false }));
      } else if (iroType === "Opportunity") {
        setFormData((prev) => ({ ...prev, positive: true, actual: false }));
      }

      setTabConditions({
        scaleDisabled: type === "Risk" || type === "Opportunity",
        scopeDisabled: type === "Risk" || type === "Opportunity",
        remediabilityDisabled:
          (isPositive && isActual) ||
          (isPositive && !isActual) ||
          type === "Risk" ||
          type === "Opportunity",
        magnitudeDisabled: !(type === "Risk" || type === "Opportunity"),
        likelihoodDisabled:
          (!isPositive && isActual) || (isPositive && isActual),
      });
    }, [formData.positive, formData.actual, iroType]);

    const validateFields = () => {
      let isValid = true;
      let firstErrorTab = null;
      const newDescriptionErrors = {};

      if (formData.actual === null) {
        setActualValidation("Actual/Potential is required");
        if (!firstErrorTab) firstErrorTab = "Basic";
        isValid = false;
      } else {
        setActualValidation("");
      }

      if (formData.positive === null) {
        setIsPositiveError("Positive/Negative is required");
        if (!firstErrorTab) firstErrorTab = "Basic";
        isValid = false;
      } else {
        setIsPositiveError("");
      }

      const tabs = [
        "Scale",
        "Scope",
        "Remediability",
        "Magnitude",
        "Likelihood",
      ];
      for (const tab of tabs) {
        if (tabConditions[`${tab.toLowerCase()}Disabled`]) continue;

        const description = formData[`${tab}Description`] || "";

        if (description.length >= MAX_CHAR_LIMIT) {
          newDescriptionErrors[
            tab
          ] = `Description should not exceed ${MAX_CHAR_LIMIT} characters`;
          if (!firstErrorTab) firstErrorTab = tab;
          isValid = false;
        } else {
          newDescriptionErrors[tab] = "";
        }
      }

      setDescriptionErrors(newDescriptionErrors);

      if (!isValid && firstErrorTab) {
        setSelectedTab(firstErrorTab);
      }

      return isValid;
    };

    const handleSubmit = () => {
      if (validateFields()) {
        const data = {
          moduleId,
          id: isEditMode ? filteredUpdateData.assessment.id : undefined,
          moduleIROId,
          isPositive: formData.positive,
          isActual: formData.actual,
          moduleRatingScopeId: parseInt(formData.ScopeId, 10) || null,
          ratingScopeDescription: formData.ScopeDescription || "",
          moduleRatingScaleId: parseInt(formData.ScaleId, 10) || null,
          ratingScaleDescription: formData.ScaleDescription || "",
          moduleRatingRemediabilityId:
            parseInt(formData.RemediabilityId, 10) || null,
          ratingRemediabilityDescription:
            formData.RemediabilityDescription || "",
          moduleRatingMagnitudeId: parseInt(formData.MagnitudeId, 10) || null,
          ratingMagnitudeIdDescription: formData.MagnitudeDescription || "",
          moduleRatingLikelihoodId: parseInt(formData.LikelihoodId, 10) || null,
          ratingLikelihoodDescription: formData.LikelihoodDescription || "",
          valueChainIds: Array.isArray(formData.valueChainId)
            ? [
                ...new Set(
                  formData.valueChainId
                    .filter((id) => id !== null && !isNaN(id))
                    .map((id) => parseInt(id, 10))
                ),
              ]
            : formData.valueChainId
            ? [parseInt(formData.valueChainId, 10)]
            : [],
        };
        console.log("updated data", data);
        handleDoneClick(data);
        onClose();
      }
    };

    const handleChange = (field, value) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

    const handlePositiveActual = (positiveActual, e) => {
      setFormData((prev) => ({ ...prev, [positiveActual]: e }));
    };

    const handleValueChainChange = (selectedOptions) => {
      setFormData((prev) => ({
        ...prev,
        valueChainId: selectedOptions
          ? selectedOptions.map((option) => option.value)
          : [],
      }));
    };

    const valueChainOptions = valueChainDropDown.map((item) => ({
      value: item.id,
      label: item.referenceList,
    }));

    const handleDeleteClick = () => {
      setIsDeleteModalOpen(true);
      setDeleteItemID(filteredUpdateData.assessment.id);
    };

    const handleDeleteCloseModal = () => {
      setIsDeleteModalOpen(false);
      onClose();
    };

    const getDefinitionByTypeAndId = (type, givenId) => {
      const result = infoRating.find((item) => item.id === givenId);
      if (result) {
        const positiveKey = `${type}DefinitionPositive`;
        const negativeKey = `${type}DefinitionNegative`;
        return {
          [positiveKey]: result[positiveKey] || "Not available",
          [negativeKey]: result[negativeKey] || "Not available",
        };
      } else {
        return "No matching ID found";
      }
    };

    const handleMouseEnter = (tab, id) => {
      const definitions = getDefinitionByTypeAndId(tab, id);
      setInfo(
        (definitions[`${tab}DefinitionNegative`] === "N/A"
          ? ""
          : definitions[`${tab}DefinitionNegative`]) +
          "  " +
          (definitions[`${tab}DefinitionPositive`] === "N/A"
            ? ""
            : definitions[`${tab}DefinitionPositive`])
      );
      setShowInfo(true);
    };

    const handleMouseLeave = () => {
      setShowInfo(false);
    };

    const renderTabContent = () => {
      switch (selectedTab) {
        case "Scale":
        case "Scope":
        case "Remediability":
        case "Magnitude":
        case "Likelihood":
          return (
            <>
              <div className="iro-form-row">
                <MotifLabel className="iro-label">{ratingLabel}</MotifLabel>
                <MotifSelect
                  value={formData[`${selectedTab}Id`]}
                  className="iro-textField"
                  onChange={(e) => handleChange(`${selectedTab}Id`, e)}
                >
                  {(selectedTab === "Likelihood"
                    ? likelihood
                    : ratingDropDownArray
                  ).map((item) => (
                    <MotifOption key={item.id} value={item.id.toString()}>
                      {`${item.val} - ${item.label} `}
                    </MotifOption>
                  ))}
                </MotifSelect>

                <div
                  className="info-icon"
                  onMouseEnter={() =>
                    handleMouseEnter(
                      selectedTab.toLocaleLowerCase(),
                      formData[`${selectedTab}Id`]
                    )
                  }
                  onMouseLeave={handleMouseLeave}
                >
                  <InfoIcon />
                </div>

                {showInfo && (
                  <div
                    className="info-description"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <p style={{ margin: 0 }}>{info}</p>
                  </div>
                )}
              </div>

              <div className="iro-form-row">
                <MotifLabel className="iro-label">
                  {descriptionLabel}
                </MotifLabel>
                <TextField
                  placeholder=""
                  multiline
                  fullWidth
                  rows={4}
                  value={formData[`${selectedTab}Description`]}
                  onChange={(e) =>
                    handleChange(`${selectedTab}Description`, e.target.value)
                  }
                />
              </div>
              {descriptionErrors[selectedTab] && (
                <MotifErrorMessage>
                  {descriptionErrors[selectedTab]}
                </MotifErrorMessage>
              )}
            </>
          );
        case "Basic":
          return (
            <>
              <div className="iro-form-row">
                <MotifLabel className="iro-label">{iroTypeLabel}</MotifLabel>
                <MotifFormField className="iro-textField">
                  <MotifInput value={iroType} disabled />
                </MotifFormField>
              </div>

              <div className="iro-form-row">
                <MotifLabel className="iro-label">{valueChainLabel}</MotifLabel>
                <Select
                  isMulti
                  value={valueChainOptions.filter((option) =>
                    formData.valueChainId.includes(option.value)
                  )}
                  onChange={handleValueChainChange}
                  options={valueChainOptions}
                  className="iro-textField"
                />
              </div>
              <div className="iro-form-row">
                <MotifLabel className="iro-label">
                  {positiveNegativeLabel}
                </MotifLabel>
                <MotifSelect
                  value={
                    formData.positive !== null
                      ? formData.positive
                        ? "true"
                        : "false"
                      : ""
                  }
                  className="iro-textField"
                  onChange={(e) =>
                    handlePositiveActual("positive", e === "true")
                  }
                  disabled={iroType === "Risk" || iroType === "Opportunity"}
                >
                  <MotifOption value="true">Positive</MotifOption>
                  <MotifOption value="false">Negative</MotifOption>
                </MotifSelect>
              </div>

              {isPositiveError && (
                <MotifErrorMessage className="error-message-iro-modal">
                  {isPositiveError}
                </MotifErrorMessage>
              )}

              <div className="iro-form-row">
                <MotifLabel className="iro-label">
                  {actualPotentialLabel}
                </MotifLabel>
                <MotifSelect
                  value={
                    formData.actual !== null
                      ? formData.actual
                        ? "true"
                        : "false"
                      : ""
                  }
                  className="iro-textField"
                  onChange={(e) => handlePositiveActual("actual", e === "true")}
                  disabled={iroType === "Risk" || iroType === "Opportunity"}
                >
                  <MotifOption value="true">Actual</MotifOption>
                  <MotifOption value="false">
                    {iroType === "Risk" || iroType === "Opportunity"
                      ? "------"
                      : "Potential"}
                  </MotifOption>
                </MotifSelect>
              </div>
              {actualValidation && (
                <MotifErrorMessage className="error-message-iro-modal">
                  {actualValidation}
                </MotifErrorMessage>
              )}
            </>
          );
        default:
          return null;
      }
    };

    return (
      <div className="iro-modal-overlay">
        <div className="iro-modal modal">
          <div className="iro-modal-header">
            <h2>{modalTitle}</h2>
          </div>
          <div className="iro-modal-body">
            <div className="iro-modal-sidebar">
              <ul>
                {[
                  "Basic",
                  "Scale",
                  "Scope",
                  "Remediability",
                  "Magnitude",
                  "Likelihood",
                ].map((tab) => (
                  <li key={tab}>
                    <button
                      type="button"
                      className={`sidebar-item ${
                        selectedTab === tab ? "active" : ""
                      }`}
                      onClick={() => setSelectedTab(tab)}
                      disabled={tabConditions[`${tab.toLowerCase()}Disabled`]}
                    >
                      {t(tab)}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-content">
              {renderTabContent()}
              <div className="iro-modal-footer">
                {isEditMode && (
                  <MotifButton
                    onClick={handleDeleteClick}
                    className="button delete-button"
                    style={{ marginRight: "10px" }}
                  >
                    {deleteButtonLabel} &nbsp;
                    <Cross />
                  </MotifButton>
                )}
                <MotifButton onClick={onClose} className="button cancel-button">
                  {cancelButtonLabel}
                </MotifButton>
                <MotifButton
                  onClick={handleSubmit}
                  className="button done-button"
                >
                  {isEditMode ? updateButtonLabel : saveButtonLabel}
                </MotifButton>
              </div>
            </div>
          </div>
        </div>

        {isDeleteModalOpen && (
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={handleDeleteCloseModal}
            setName={t("DeleteLabel")}
            labelText={t("DeletConfirmationSources")}
            onCancel={handleDeleteCloseModal}
            handleDeleteClick={handleDelete}
            cancelButtonLabel={t("Cancel")}
            deleteButtonLabel={t("DeleteLabel")}
          />
        )}
      </div>
    );
  }
);

IroAssessmentModal.propTypes = {
  iroType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  modalMode: PropTypes.string,
  modalTitle: PropTypes.string,
  ratingDropDownArray: PropTypes.array,
  likelihood: PropTypes.array,
  saveButtonLabel: PropTypes.string,
  updateButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  valueChainDropDown: PropTypes.array,
  handleDoneClick: PropTypes.func.isRequired,
  deleteButtonLabel: PropTypes.string,
  ratingLabel: PropTypes.string,
  descriptionLabel: PropTypes.string,
  iroTypeLabel: PropTypes.string,
  valueChainLabel: PropTypes.string,
  positiveNegativeLabel: PropTypes.string,
  actualPotentialLabel: PropTypes.string,
  filteredUpdateData: PropTypes.object,
  moduleId: PropTypes.string,
  moduleIROId: PropTypes.string,
  handleDelete: PropTypes.func,
  infoRating: PropTypes.array,
};

export default IroAssessmentModal;
