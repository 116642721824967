import languageData from "../../constants/languages/en/translations.json";

export const newProjectFormFields = [
  {
    id: "projectName",
    label: languageData.projectNameLabel,
    placeholder: languageData.projectNamePlaceholder,
    type: "input",
    class: "",
  },
  {
    id: "clientName",
    label: languageData.clientNameLabel,
    placeholder: languageData.clientNamePlaceholder,
    type: "input",
    class: "",
  },
  {
    id: "countryId",
    label: languageData.countryLabel,
    placeholder: languageData.countryPlaceholder,
    type: "select",
    options: [],
    class: "thirty-percent",
    filter: true,
  },
  {
    id: "engagementCode",
    label: languageData.engagementCodeLabel,
    placeholder: languageData.engagementCodePlaceholder,
    type: "input",
    class: "thirty-percent",
  },
  {
    id: "gisId",
    label: languageData.gisIdLabel,
    placeholder: languageData.gisIdPlaceholder,
    type: "input",
    class: "twenty-percent",
    button: true,
    buttonLabel: languageData.openGisButtonLabel,
    buttonUrl: "https://gis.ey.net",
  },
  {
    id: "opportunity",
    label: languageData.opportunityIDLabel,
    placeholder: languageData.opportunityIDPlaceholder,
    type: "input",
    class: "twenty-percent",
  },
  {
    id: "channel",
    label: languageData.channelLabel,
    placeholder: languageData.channelPlaceholder,
    type: "select",
    options: [
      { id: "yes", name: "Yes" },
      { id: "no", name: "No" },
    ],
    class: "",
    filter: false,
  },
  {
    id: "projectSize",
    label: languageData.projectSizeLabel,
    placeholder: languageData.projectSizePlaceholder,
    type: "select",
    options: [
      { id: "demo", name: "Demo" },
      { id: "<50k", name: "<50k" },
      { id: "50k-100k", name: "50k-100k" },
      { id: "100k-300k", name: "100k-300k" },
      { id: "300k-500k", name: "300k-500k" },
      { id: "500k-1000k", name: "500k-1000k" },
      { id: ">1000k", name: ">1000k" },
    ],
    class: "",
    filter: false,
  },
  {
    id: "clientSector",
    label: languageData.clientSectorLabel,
    placeholder: languageData.clientSectorPlaceholder,
    type: "select",
    options: [],
    class: "full-width",
    filter: true,
  },
  {
    id: "projectAdmin",
    label: languageData.projectAdministratorLabel,
    type: "multiSelect",
    multiple: true,
    filter: true,
    options: [],
    class: "full-width",
  },
];
