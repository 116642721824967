import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ExcelJS from "exceljs";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import DMACards from "./dmaCards/DMACards";
import { Stake, Iro, Reporting } from "../../../constants/icons/Icons";
import {
  fetchUnderstandingTheContextCount,
  fetchIROCount,
  fetchIroReportingData,
} from "../../../features/slices/DMAOverview";
import { fetchExcelData } from "../../../features/slices/LongListData";
import ErrorModal from "../../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";
import { handleError as handleLogoutError } from "../../../utils/handleError";
import { addSheet } from "../../../utils/excelUtils";
import { handleDownloadIroReport } from "../../../utils/iroExcel";

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
};

const formatExcelData = (data) => {
  const workbook = new ExcelJS.Workbook();
  addSheet(workbook, "Sustainability Matters", data);
  return workbook;
};

const downloadExcel = async (data) => {
  const workbook = formatExcelData(data);
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "sustainability_matters.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

const DMAOverview = ({ onTabChange }) => {
  const dispatch = useDispatch();
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("sources");
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { contextData, iroData, isError, errorMessage } = useSelector(
    (state) => state.dmaOverview
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          dispatch(fetchUnderstandingTheContextCount({ moduleId, token })),
          dispatch(fetchIROCount({ moduleId, token })),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      setActiveTab("Overview");
      setIsExcelLoading(false);
      setIsLoading(true);
    };
  }, [dispatch, moduleId, token]);

  const handleDownloadExcel = async () => {
    setIsExcelLoading(true);
    try {
      const excelResponse = await dispatch(
        fetchExcelData({ moduleId, token })
      ).unwrap();
      const exceResponseData = excelResponse?.data || [];
      await downloadExcel(exceResponseData);
    } catch (error) {
      console.error("Error fetching Excel data:", error);
    } finally {
      setIsExcelLoading(false);
    }
  };

  const handleDownloadIroReportWrapper = async () => {
    setIsExcelLoading(true);
    try {
      const iroResponseData = await dispatch(
        fetchIroReportingData({ moduleId, token })
      ).unwrap();
      const iroReportingData = iroResponseData?.data.responseData || [];
      await handleDownloadIroReport(iroReportingData);
    } catch (error) {
      console.error("Error fetching IRO data:", error);
    } finally {
      setIsExcelLoading(false);
    }
  };

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleLogoutError(dispatch, navigate)}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  return (
    <div className="dma-overview-container">
      {isLoading || isExcelLoading ? (
        <MotifProgressLoader className="loader" show variant="default" />
      ) : (
        <>
          <DMACards
            headerIconLeft={<Stake />}
            lefttype={t("UTC")}
            UTC={t("UTC")}
            references={contextData?.data}
            headerIconRight={<Reporting />}
            Longlist={t("Long list - Sustainable Matters")}
            Shortlist={t("IRO Report")}
            Dashboard={t("Dashboard")}
            ReportingImplication={t("ReportingImplication")}
            rightHeaderType={t("ReportingImplication")}
            onTabChange={onTabChange}
            onIroReportClick={handleDownloadIroReportWrapper}
            onLonglistClick={handleDownloadExcel}
          />
          <DMACards
            headerIconLeft={<Iro />}
            headerIconRight={<Stake />}
            references={iroData?.data}
            lefttype={t("IROIdentificationAndAssessment")}
            IROIdentificationAndAssessment={t("IROIdentificationAndAssessment")}
            onTabChange={setActiveTab}
          />
        </>
      )}
    </div>
  );
};

export default DMAOverview;