import React, { useState } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import language from "../../constants/languages/en/translations.json";
import "./InstanceMaintenance.scss";
import { useTranslation } from "react-i18next";
const ChangeStatusModal = ({
  onClose,
  setInstanceName,
  handleStatusUpdate,
  currentStatus,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(currentStatus);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const handleStatusChange = (event) => {
    setSelectedStatus(event);
  };

  const handleUpdateClick = () => {
    handleStatusUpdate(selectedStatus, message);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-template">
        <div className="modal-header">
          <h2>{t("ChangeStatus")}</h2>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group">
              <MotifLabel htmlFor="instance-name">
                {t("InstanceName")}
              </MotifLabel>
              <MotifFormField>
                <MotifInput
                  className="session-modal-input session-name-input"
                  value={setInstanceName}
                  aria-describedby="session-name"
                  hideClearButton={true}
                  disabled={true}
                />
              </MotifFormField>
            </div>
            <div className="form-group">
              <MotifLabel id="select-label">{t("Status")}</MotifLabel>
              <MotifFormField>
                <MotifSelect
                  className="change-status-modal"
                  onChange={handleStatusChange}
                  value={selectedStatus} // Set default value
                >
                  <MotifOption value={t("StatusActive")}>
                    {t("StatusActive")}
                  </MotifOption>
                  <MotifOption value={t("StatusInactive")}>
                    {t("StatusInactive")}
                  </MotifOption>
                </MotifSelect>
              </MotifFormField>
            </div>
          </div>

          <div className="inactive-message-box">
            <TextField
              placeholder={t("MessageToTheUser")}
              multiline
              fullWidth
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              disabled={selectedStatus === language.StatusActive}
              sx={{
                backgroundColor:
                  selectedStatus === language.StatusActive
                    ? "#f0f0f0"
                    : "inherit",
              }}
            />
            <MotifLabel id="select-label">{t("InactiveMessage")}</MotifLabel>
          </div>
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="button cancel-button">
            {t("Cancel")}
          </MotifButton>
          <MotifButton
            onClick={handleUpdateClick}
            className="button done-button"
          >
            {t("Done")}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

ChangeStatusModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setInstanceName: PropTypes.string.isRequired,
  handleStatusUpdate: PropTypes.func.isRequired,
  currentStatus: PropTypes.string,
};

export default ChangeStatusModal;
